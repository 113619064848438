import React, { Component } from "react";
import { Link } from "react-router-dom";

import "./style.css";
import "bootstrap/dist/css/bootstrap.min.css";

// material UI imports
import {
    Button,
    TextField,
    InputLabel,
    MenuItem,
    Select,
    FormControl,
} from "@material-ui/core";
import { Row, Col, Button as Btn1, Modal, Container } from "react-bootstrap";
import moment from "moment";
import { TextEdit } from "../../components/showEdit/ShowEdit";
import { inWords } from "../../extra/helpers";

// font awasome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faPenAlt,
    faBook,
    faTrash,
    faSyncAlt,
    faPlusCircle,
    faTrashAlt,
} from "@fortawesome/free-solid-svg-icons";

// Toastify imports
import { ToastContainer, toast } from "react-toastify";
// import "../ledger_manager/exportManager/node_modules/react-toastify/dist/ReactToastify.css"
// datatable setup
import jsZip from "jszip";
window.JSZip = jsZip;
var $ = require("jquery");
$.DataTable = require("datatables.net");
require("datatables.net-bs4");
require("datatables.net-autofill-bs4");
require("datatables.net-buttons-bs4");
require("datatables.net-buttons/js/buttons.colVis");
require("datatables.net-buttons/js/buttons.flash");
require("datatables.net-buttons/js/buttons.html5");
require("datatables.net-buttons/js/buttons.print");
require("datatables.net-responsive-bs4");
require("datatables.net-scroller-bs4");
require("datatables.net-select-bs4");
require("pdfmake");

// constants
const axios = require("axios");
const API_URL = "https://5techg.a2hosted.com/globaltyres_api/products";
const companies = ["MRF TYRES", "JK TYRES", "CEAT TYRES", "BRIDGE STONE TYRES"];

export default class Company extends Component {
    constructor(props) {
        super(props);
        console.log("Constructor : ", props);
        this.state = {
            showAddModal: false,
            showUpdateModel: false,
            id: "",
            companyId: this.props.company,
            productName: "",
            productSku: "",
            category: "",
            inch: "",
            size: "",
            brand: "",
            type: "",
            tyre: "",
            tube: "",
            ndp: "",
            mv: "",
            priceSingle: "",
            pricePair: "",
            rsp: "",
            discount: "",
            pattern: "",
            application: "",
            warrenty: "",
            netLandingSinglel: "",
            netLandingPair: "",
            stockCount: "",
            lastModified: "",
            status: "",
            productData: [],
        };
    }

    fetchPartiesData() {
        axios
            .get(API_URL + `?_where=(companyId,eq,${this.props.company})`)
            .then((res) => {
                console.log("bill data: ", res.data);
                this.setState({ partiesData: res.data });
                console.log("fetchParties ==>> ", this.state);
            })
            .catch((err) => {
                console.log("bill data error: ", err);
            });
    }

    handleUpdateSubmit(e) {
        e.preventDefault();
        let data = {
            companyId: this.state.companyId,
            productName: this.state.productName,
            productSku: this.state.productSku,
            category: this.state.category,
            inch: this.state.inch,
            size: this.state.size,
            brand: this.state.brand,
            type: this.state.type,
            tyre: this.state.tyre,
            tube: this.state.tube,
            ndp: this.state.ndp,
            mv: this.state.mv,
            priceSingle: this.state.priceSingle,
            pricePair: this.state.pricePair,
            rsp: this.state.rsp,
            discount: this.state.discount,
            pattern: this.state.pattern,
            application: this.state.application,
            warrenty: this.state.warrenty,
            netLandingSinglel: this.state.netLandingSinglel,
            netLandingPair: this.state.netLandingPair,
            stockCount: this.state.stockCount,
            lastModified: this.state.lastModified,
            status: this.state.status,
        };
        axios
            .patch(API_URL + `/${this.state.id}`, data)
            .then((res) => {
                toast.success("bill details updated successfully");
                this.fetchPartiesData();
            })
            .catch((err) => {
                console.log("error while updating bill data", err);
            });
    }

    handleAddSubmit(e) {
        e.preventDefault();
        let data = {
            companyId: this.state.companyId,
            productName: this.state.productName,
            productSku: this.state.productSku,
            category: this.state.category,
            inch: this.state.inch,
            size: this.state.size,
            brand: this.state.brand,
            type: this.state.type,
            tyre: this.state.tyre,
            tube: this.state.tube,
            ndp: this.state.ndp,
            mv: this.state.mv,
            priceSingle: this.state.priceSingle,
            pricePair: this.state.pricePair,
            rsp: this.state.rsp,
            discount: this.state.discount,
            pattern: this.state.pattern,
            application: this.state.application,
            warrenty: this.state.warrenty,
            netLandingSinglel: this.state.netLandingSinglel,
            netLandingPair: this.state.netLandingPair,
            stockCount: this.state.stockCount,
            lastModified: this.state.lastModified,
            status: this.state.status,
        };
        console.log("added product : ", data);
        axios
            .post(API_URL, data)
            .then((res) => {
                console.log("added : ", res);
                this.props.refresh();
                toast.success("bill details updated successfully");
            })
            .catch((err) => {
                console.log("error while updating bill data", err);
            });
    }

    deleteRecord(id) {
        axios
            .delete(API_URL + `/${id}`)
            .then((res) => {
                console.log("deleted status data: ", res.data);
                this.props.refresh();
                toast.error("bill deleted successfully");
            })
            .catch((err) => {
                console.log("record delete error: ", err);
            });
    }

    handleTabs = (event, newValue) => {
        this.setState({ value: newValue });
    };

    componentDidMount() {
        this.fetchPartiesData();
    }

    componentDidUpdate() {
        //console.log("updated ==>> ", this.state);
        $("#bill_table").DataTable({
            destroy: true,
            keys: true,
            buttons: [
                "copy",
                "csv",
                "excel",
                {
                    extend: "pdf",
                    messageTop: "<h4 style='text-align:center'>Users List</h4>",
                    download: "open",
                },
                {
                    extend: "print",
                    messageTop: "<h4 style='text-align:center'>Users List</h4>",
                    download: "open",
                },
            ],
        });
    }

    renderPartiesData = () => {
        const bills = this.state.partiesData;

        if (bills == null) {
            return null;
        }

        return bills.map((product, index) => {
            return (
                <tr key={"company-row-" + index.toString()}>
                    <td align="center">{product["id"]}</td>
                    <td align="center">{product["productName"]}</td>
                    <td align="center">{product["productSku"]}</td>
                    <td align="center">{product["category"]}</td>
                    <td align="center">{product["brand"]}</td>
                    <td align="center">{product["type"]}</td>
                    <td align="center">{product["discount"]}</td>
                    <td align="center">{product["stockCount"]}</td>
                    <td align="center">{product["tubeHsn"]}</td>
                    <td align="center">{product["flapHsn"]}</td>
                    <td align="center" style={{ width: "6em" }}>
                        <button
                            className="btn shadow mr-1 border"
                            style={{ backgroundColor: "transparent" }}
                            onClick={(e) => {
                                this.setState({
                                    id: product["id"],
                                    companyId: product["companyId"],
                                    productName: product["productName"],
                                    productSku: product["productSku"],
                                    category: product["category"],
                                    inch: product["inch"],
                                    size: product["size"],
                                    brand: product["brand"],
                                    type: product["type"],
                                    tyre: product["tyre"],
                                    tube: product["tube"],
                                    ndp: product["ndp"],
                                    mv: product["mv"],
                                    priceSingle: product["priceSingle"],
                                    pricePair: product["pricePair"],
                                    rsp: product["rsp"],
                                    discount: product["discount"],
                                    pattern: product["pattern"],
                                    application: product["application"],
                                    warrenty: product["warrenty"],
                                    netLandingSinglel:
                                        product["netLandingSinglel"],
                                    netLandingPair: product["netLandingPair"],
                                    stockCount: product["stockCount"],
                                    lastModified: product["lastModified"],
                                    status: product["status"],
                                    showUpdateModal: true,
                                });
                            }}
                        >
                            <FontAwesomeIcon
                                icon={faPenAlt}
                                style={{ color: "blue" }}
                            />
                        </button>
                        <button
                            className="btn shadow border"
                            style={{
                                backgroundColor: "transparent",
                                borderSize: 0,
                            }}
                            onClick={(e) => {
                                if (window.confirm("Delete the item?")) {
                                    this.deleteRecord(product["id"]);
                                }
                            }}
                        >
                            <FontAwesomeIcon
                                icon={faTrashAlt}
                                style={{ color: "red" }}
                            />
                        </button>
                    </td>
                </tr>
            );
        });
    };

    renderUpdateInventoryModal() {
        return (
            <Modal
                show={this.state.showUpdateModal}
                onHide={(e) => this.setState({ showUpdateModal: false })}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        <div>Update Product</div>
                        <small>{`id : ${this.state.id} | ${
                            companies[parseInt(this.state.companyId)]
                        } | ${this.state.productName}`}</small>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form noValidate autoComplete="off">
                        <Container>
                            {false ? (
                                <Row>
                                    <Col>
                                        <TextField
                                            id="companyId"
                                            label="Company"
                                            variant="outlined"
                                            className="m-2"
                                            defaultValue={
                                                companies[
                                                    parseInt(
                                                        this.state.companyId
                                                    )
                                                ]
                                            }
                                            disabled
                                        />
                                    </Col>
                                    <Col>
                                        <TextField
                                            id="productName"
                                            label="Prodcut name"
                                            variant="outlined"
                                            className="m-2"
                                            defaultValue={
                                                this.state.productName
                                            }
                                            onChange={(e) =>
                                                this.setState({
                                                    productName: e.target.value,
                                                })
                                            }
                                        />
                                    </Col>
                                    <Col>
                                        <TextField
                                            id="productSku"
                                            label="Product Sku"
                                            variant="outlined"
                                            className="m-2"
                                            defaultValue={this.state.productSku}
                                            onChange={(e) =>
                                                this.setState({
                                                    productSku: e.target.value,
                                                })
                                            }
                                        />
                                    </Col>
                                </Row>
                            ) : (
                                ""
                            )}
                            <Row>
                                <Col>
                                    <TextField
                                        id="productSku"
                                        label="Product Sku"
                                        variant="outlined"
                                        className="m-2"
                                        defaultValue={this.state.productSku}
                                        onChange={(e) =>
                                            this.setState({
                                                productSku: e.target.value,
                                            })
                                        }
                                    />
                                </Col>
                                <Col>
                                    <TextField
                                        id="category"
                                        label="Category"
                                        variant="outlined"
                                        className="m-2"
                                        defaultValue={this.state.category}
                                        onChange={(e) =>
                                            this.setState({
                                                category: e.target.value,
                                            })
                                        }
                                    />
                                </Col>
                                <Col>
                                    <TextField
                                        id="brand"
                                        label="Brand"
                                        variant="outlined"
                                        className="m-2"
                                        defaultValue={this.state.brand}
                                        onChange={(e) =>
                                            this.setState({
                                                brand: e.target.value,
                                            })
                                        }
                                    />
                                </Col>
                                <Col>
                                    <TextField
                                        id="type"
                                        label="Type"
                                        variant="outlined"
                                        className="m-2"
                                        defaultValue={this.state.type}
                                        onChange={(e) =>
                                            this.setState({
                                                type: e.target.value,
                                            })
                                        }
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <TextField
                                        id="inch"
                                        label="Inch"
                                        variant="outlined"
                                        className="m-2"
                                        defaultValue={this.state.inch}
                                        onChange={(e) =>
                                            this.setState({
                                                inch: e.target.value,
                                            })
                                        }
                                    />
                                </Col>
                                <Col>
                                    <TextField
                                        id="size"
                                        label="Size"
                                        variant="outlined"
                                        className="m-2"
                                        defaultValue={this.state.size}
                                        onChange={(e) =>
                                            this.setState({
                                                size: e.target.value,
                                            })
                                        }
                                    />
                                </Col>
                                <Col>
                                    <TextField
                                        id="discount"
                                        label="Discount"
                                        variant="outlined"
                                        className="m-2"
                                        defaultValue={this.state.discount}
                                        onChange={(e) =>
                                            this.setState({
                                                discount: e.target.value,
                                            })
                                        }
                                    />
                                </Col>
                                <Col>
                                    <TextField
                                        id="stock"
                                        label="Stock"
                                        variant="outlined"
                                        className="m-2"
                                        defaultValue={this.state.stockCount}
                                        onChange={(e) =>
                                            this.setState({
                                                stockCount: e.target.value,
                                            })
                                        }
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <TextField
                                        id="tyre"
                                        label="Tyre"
                                        variant="outlined"
                                        className="m-2"
                                        defaultValue={this.state.tyre}
                                        onChange={(e) =>
                                            this.setState({
                                                tyre: e.target.value,
                                            })
                                        }
                                    />
                                </Col>
                                <Col>
                                    <TextField
                                        id="tube"
                                        label="Tube"
                                        variant="outlined"
                                        className="m-2"
                                        defaultValue={this.state.tube}
                                        onChange={(e) =>
                                            this.setState({
                                                tube: e.target.value,
                                            })
                                        }
                                    />
                                </Col>
                                <Col>
                                    <TextField
                                        id="ndp"
                                        label="Ndp"
                                        variant="outlined"
                                        className="m-2"
                                        defaultValue={this.state.ndp}
                                        onChange={(e) =>
                                            this.setState({
                                                ndp: e.target.value,
                                            })
                                        }
                                    />
                                </Col>
                                <Col>
                                    <TextField
                                        id="mv"
                                        label="Mv"
                                        variant="outlined"
                                        className="m-2"
                                        defaultValue={this.state.mv}
                                        onChange={(e) =>
                                            this.setState({
                                                mv: e.target.value,
                                            })
                                        }
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <TextField
                                        id="priceSingle"
                                        label="Price Sigle"
                                        variant="outlined"
                                        className="m-2"
                                        defaultValue={this.state.priceSingle}
                                        onChange={(e) =>
                                            this.setState({
                                                priceSingle: e.target.value,
                                            })
                                        }
                                    />
                                </Col>
                                <Col>
                                    <TextField
                                        id="pricePair"
                                        label="pricePair"
                                        variant="outlined"
                                        className="m-2"
                                        defaultValue={this.state.pricePair}
                                        onChange={(e) =>
                                            this.setState({
                                                pricePair: e.target.value,
                                            })
                                        }
                                    />
                                </Col>
                                <Col>
                                    <TextField
                                        id="rsp"
                                        label="Rsp"
                                        variant="outlined"
                                        className="m-2"
                                        defaultValue={this.state.rsp}
                                        onChange={(e) =>
                                            this.setState({
                                                rsp: e.target.value,
                                            })
                                        }
                                    />
                                </Col>
                                <Col>
                                    <TextField
                                        id="pattern"
                                        label="Pattern"
                                        variant="outlined"
                                        className="m-2"
                                        defaultValue={this.state.pattern}
                                        onChange={(e) =>
                                            this.setState({
                                                pattern: e.target.value,
                                            })
                                        }
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <TextField
                                        id="application"
                                        label="Application"
                                        variant="outlined"
                                        className="m-2 w-100"
                                        defaultValue={this.state.application}
                                        onChange={(e) =>
                                            this.setState({
                                                application: e.target.value,
                                            })
                                        }
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <TextField
                                        id="warrety"
                                        label="Warrenty"
                                        variant="outlined"
                                        className="m-2 w-100"
                                        defaultValue={this.state.warrenty}
                                        onChange={(e) =>
                                            this.setState({
                                                warrenty: e.target.value,
                                            })
                                        }
                                    />
                                </Col>
                            </Row>
                        </Container>
                        <hr />
                        <div className="mt-2 mr-1">
                            <Btn1
                                style={{ float: "right" }}
                                onClick={(e) => {
                                    this.setState({
                                        showUpdateModal: false,
                                    });
                                    this.handleUpdateSubmit(e);
                                }}
                            >
                                Update
                            </Btn1>
                        </div>
                    </form>
                </Modal.Body>
            </Modal>
        );
    }

    renderShowAddNewModal() {
        return (
            <Modal
                show={this.state.showAddModal}
                onHide={(e) => this.setState({ showAddModal: false })}
                size="xl"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        New Product
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form noValidate autoComplete="off">
                        <Container>
                            <Row>
                                <Col>
                                    <TextField
                                        id="companyId"
                                        label="Company"
                                        variant="outlined"
                                        className="m-2"
                                        defaultValue={
                                            companies[
                                                parseInt(this.state.companyId)
                                            ]
                                        }
                                        onChange={(e) =>
                                            this.setState({
                                                companyId: e.target.value,
                                            })
                                        }
                                    />
                                </Col>
                                <Col>
                                    <TextField
                                        id="productName"
                                        label="Prodcut name"
                                        variant="outlined"
                                        className="m-2"
                                        defaultValue={this.state.productName}
                                        onChange={(e) =>
                                            this.setState({
                                                productName: e.target.value,
                                            })
                                        }
                                    />
                                </Col>
                                <Col>
                                    <TextField
                                        id="productSku"
                                        label="Product Sku"
                                        variant="outlined"
                                        className="m-2"
                                        defaultValue={this.state.productSku}
                                        onChange={(e) =>
                                            this.setState({
                                                productSku: e.target.value,
                                            })
                                        }
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <TextField
                                        id="category"
                                        label="Category"
                                        variant="outlined"
                                        className="m-2"
                                        defaultValue={this.state.category}
                                        onChange={(e) =>
                                            this.setState({
                                                category: e.target.value,
                                            })
                                        }
                                    />
                                </Col>
                                <Col>
                                    <TextField
                                        id="brand"
                                        label="Brand"
                                        variant="outlined"
                                        className="m-2"
                                        defaultValue={this.state.brand}
                                        onChange={(e) =>
                                            this.setState({
                                                brand: e.target.value,
                                            })
                                        }
                                    />
                                </Col>
                                <Col>
                                    <TextField
                                        id="type"
                                        label="Type"
                                        variant="outlined"
                                        className="m-2"
                                        defaultValue={this.state.type}
                                        onChange={(e) =>
                                            this.setState({
                                                type: e.target.value,
                                            })
                                        }
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <TextField
                                        id="inch"
                                        label="Inch"
                                        variant="outlined"
                                        className="m-2"
                                        defaultValue={this.state.inch}
                                        onChange={(e) =>
                                            this.setState({
                                                inch: e.target.value,
                                            })
                                        }
                                    />
                                </Col>
                                <Col>
                                    <TextField
                                        id="size"
                                        label="Size"
                                        variant="outlined"
                                        className="m-2"
                                        defaultValue={this.state.size}
                                        onChange={(e) =>
                                            this.setState({
                                                size: e.target.value,
                                            })
                                        }
                                    />
                                </Col>
                                <Col>
                                    <TextField
                                        id="discount"
                                        label="Discount"
                                        variant="outlined"
                                        className="m-2"
                                        defaultValue={this.state.discount}
                                        onChange={(e) =>
                                            this.setState({
                                                discount: e.target.value,
                                            })
                                        }
                                    />
                                </Col>
                                <Col>
                                    <TextField
                                        id="stock"
                                        label="Stock"
                                        variant="outlined"
                                        className="m-2"
                                        defaultValue={this.state.stockCount}
                                        onChange={(e) =>
                                            this.setState({
                                                stockCount: e.target.value,
                                            })
                                        }
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <TextField
                                        id="tyre"
                                        label="Tyre"
                                        variant="outlined"
                                        className="m-2"
                                        defaultValue={this.state.tyre}
                                        onChange={(e) =>
                                            this.setState({
                                                tyre: e.target.value,
                                            })
                                        }
                                    />
                                </Col>
                                <Col>
                                    <TextField
                                        id="tube"
                                        label="Tube"
                                        variant="outlined"
                                        className="m-2"
                                        defaultValue={this.state.tube}
                                        onChange={(e) =>
                                            this.setState({
                                                tube: e.target.value,
                                            })
                                        }
                                    />
                                </Col>
                                <Col>
                                    <TextField
                                        id="ndp"
                                        label="Ndp"
                                        variant="outlined"
                                        className="m-2"
                                        defaultValue={this.state.ndp}
                                        onChange={(e) =>
                                            this.setState({
                                                ndp: e.target.value,
                                            })
                                        }
                                    />
                                </Col>
                                <Col>
                                    <TextField
                                        id="mv"
                                        label="Mv"
                                        variant="outlined"
                                        className="m-2"
                                        defaultValue={this.state.mv}
                                        onChange={(e) =>
                                            this.setState({
                                                mv: e.target.value,
                                            })
                                        }
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <TextField
                                        id="priceSingle"
                                        label="Price Sigle"
                                        variant="outlined"
                                        className="m-2"
                                        defaultValue={this.state.priceSingle}
                                        onChange={(e) =>
                                            this.setState({
                                                priceSingle: e.target.value,
                                            })
                                        }
                                    />
                                </Col>
                                <Col>
                                    <TextField
                                        id="pricePair"
                                        label="pricePair"
                                        variant="outlined"
                                        className="m-2"
                                        defaultValue={this.state.pricePair}
                                        onChange={(e) =>
                                            this.setState({
                                                pricePair: e.target.value,
                                            })
                                        }
                                    />
                                </Col>
                                <Col>
                                    <TextField
                                        id="rsp"
                                        label="Rsp"
                                        variant="outlined"
                                        className="m-2"
                                        defaultValue={this.state.rsp}
                                        onChange={(e) =>
                                            this.setState({
                                                rsp: e.target.value,
                                            })
                                        }
                                    />
                                </Col>
                                <Col>
                                    <TextField
                                        id="pattern"
                                        label="Pattern"
                                        variant="outlined"
                                        className="m-2"
                                        defaultValue={this.state.pattern}
                                        onChange={(e) =>
                                            this.setState({
                                                pattern: e.target.value,
                                            })
                                        }
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <TextField
                                        id="application"
                                        label="Application"
                                        variant="outlined"
                                        className="m-2 w-100"
                                        defaultValue={this.state.application}
                                        onChange={(e) =>
                                            this.setState({
                                                application: e.target.value,
                                            })
                                        }
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <TextField
                                        id="warrety"
                                        label="Warrenty"
                                        variant="outlined"
                                        className="m-2 w-100"
                                        defaultValue={this.state.warrenty}
                                        onChange={(e) =>
                                            this.setState({
                                                warrenty: e.target.value,
                                            })
                                        }
                                    />
                                </Col>
                            </Row>
                        </Container>
                        <hr />
                        <div className="mt-2 mr-1">
                            <Btn1
                                style={{ float: "right" }}
                                onClick={(e) => {
                                    this.setState({
                                        showAddModal: false,
                                    });
                                    this.handleAddSubmit(e);
                                }}
                            >
                                Add Product
                            </Btn1>
                        </div>
                    </form>
                </Modal.Body>
            </Modal>
        );
    }

    render() {
        return (
            <div className="container-fluid border m-0 p-0 main">
                <div className="m-0 p-0">
                    <div className="container-fluid border m-0 p-1">
                        <div
                            className="btn-group"
                            role="group"
                            aria-label="Basic example"
                        >
                            <Button
                                color="primary"
                                variant="contained"
                                className="mt-1 mr-1 mb-3 ml-5"
                                onClick={(e) => {
                                    this.setState({
                                        id: "",
                                        productName: "",
                                        productSku: "",
                                        category: "",
                                        inch: "",
                                        size: "",
                                        brand: "",
                                        type: "",
                                        tyre: "",
                                        tube: "",
                                        ndp: "",
                                        mv: "",
                                        priceSingle: "",
                                        pricePair: "",
                                        rsp: "",
                                        discount: "",
                                        pattern: "",
                                        application: "",
                                        warrenty: "",
                                        netLandingSinglel: "",
                                        netLandingPair: "",
                                        stockCount: "",
                                        lastModified: "",
                                        status: "",
                                        showAddModal: true,
                                    });
                                }}
                            >
                                <FontAwesomeIcon
                                    icon={faPlusCircle}
                                    size="2x"
                                />
                            </Button>
                            <Button
                                color="primary"
                                variant="contained"
                                className="mt-1 mr-1 mb-3 ml-5"
                                onClick={this.props.refresh}
                            >
                                <FontAwesomeIcon icon={faSyncAlt} size="2x" />
                            </Button>
                        </div>

                        {true ? this.renderUpdateInventoryModal() : ""}
                        {true ? this.renderShowAddNewModal() : ""}
                        <Row className="ml-0 mr-0">
                            <Col md="12" className="p-0 m-0 measure1">
                                <div>
                                    <table
                                        id="bill_table"
                                        className="display"
                                        style={{ width: "100%" }}
                                    >
                                        <thead>
                                            <tr align="center">
                                                <th>Id</th>
                                                <th>Product Name</th>
                                                <th>Product Sku</th>
                                                <th>Category</th>
                                                <th>Brand</th>
                                                <th>Type</th>
                                                <th>Discount</th>
                                                <th>Stock Count</th>
                                                <th>Tube Hsn</th>
                                                <th>Flap Hsn</th>
                                                <th>Options</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.renderPartiesData()}
                                        </tbody>
                                    </table>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>
            </div>
        );
    }
}

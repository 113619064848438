import React, { Component } from "react";

import { TextField, Button } from "@material-ui/core";
// import { TabContext, TabList, TabPanel } from "@material-ui/lab";
// import { Row, Col, Card, Badge, Table as Tbl } from "react-bootstrap";
import "./style.css";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusCircle, faSyncAlt } from "@fortawesome/free-solid-svg-icons";
import { DateEdit } from "../../components/showEdit/ShowEdit";

//API handling components
const API_URL = "https://5techg.a2hosted.com/globaltyres_api/";
const axios = require("axios");

export class AddNewEntry extends Component {
    constructor(props) {
        super(props);
        this.state = {
            partyId: this.props.partyId,
            particular: null,
            total: 0,
            debit: 0,
            credit: 0,
        };
    }

    handleAddSubmit(e) {
        e.preventDefault();
        axios
            .post(API_URL + `/ledger`, {
                partyId: this.state.partyId,
                particular: this.state.particular,
                debit: this.state.debit,
                credit: this.state.credit,
            })
            .then((res) => {
                console.log("ledger record added successfully");
                this.props.toast.success("ledger record added successfully");
                this.props.refresh();
            })
            .catch((err) => {
                console.log(err);
            });
    }

    render() {
        return (
            <div className="row">
                <form autoComplete="off">
                    <div className="row ml-4 mt-4">
                        <TextField
                            id="particular"
                            label="particular"
                            variant="outlined"
                            type="text"
                            className="mr-2"
                            required={true}
                            size="small"
                            onChange={(e) =>
                                this.setState({ particular: e.target.value })
                            }
                        />

                        {/* <TextField
                            id="total"
                            label="Total"
                            variant="outlined"
                            type="number"
                            className="mr-2"
                            size="small"
                            onChange={(e) =>
                                this.setState({ total: e.target.value })
                            }
                        /> */}

                        <TextField
                            id="debit"
                            label="debit"
                            variant="outlined"
                            type="number"
                            className="mr-2"
                            size="small"
                            onChange={(e) =>
                                this.setState({ debit: e.target.value })
                            }
                        />
                        <TextField
                            id="credit"
                            label="credit"
                            variant="outlined"
                            className="mr-2"
                            type="number"
                            size="small"
                            onChange={(e) =>
                                this.setState({ credit: e.target.value })
                            }
                        />

                        <Button
                            color="primary"
                            variant="contained"
                            className="mr-2"
                            onClick={(e) => this.handleAddSubmit(e)}
                        >
                            <FontAwesomeIcon icon={faPlusCircle} size="2x" />
                        </Button>
                        <Button
                            color="primary"
                            variant="contained"
                            className="mr-2"
                            onClick={this.props.refresh}
                        >
                            <FontAwesomeIcon icon={faSyncAlt} size="2x" />
                        </Button>
                    </div>
                </form>
            </div>
        );
    }
}

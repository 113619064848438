import "./style.css";
import React, { useState } from "react";

export const TextEdit = ({
    val,
    setVal,
    show,
    toggle,
    style = {},
    showOnly = false,
    num = false,
}) => {
    //console.log("inside show Edit", val, show);
    if (show || showOnly) {
        return (
            <div
                className={showOnly ? "fws1" : "fws"}
                onClick={(e) => (showOnly ? "" : toggle(false))}
                style={style}
            >
                {num ? val.toFixed(2) : val}
            </div>
        );
    }
    return (
        <input
            type={num ? "number" : "text"}
            // value={val}
            className="fwi"
            onChange={(e) => {
                setVal(e.target.value === "" ? 0 : e.target.value);
            }}
            onBlur={(e) => toggle(true)}
            autoFocus
            style={style}
            min={1}
        />
    );
};

export const SelectEdit = ({
    val,
    setVal,
    show,
    toggle,
    style = {},
    showOnly = false,
    options = [],
}) => {
    //console.log("inside select Edit", val, show, options);
    if (show || showOnly) {
        return (
            <div
                className={showOnly ? "fws1" : "fws"}
                onClick={(e) => (showOnly ? "" : toggle(false))}
                style={style}
            >
                {options[val]}
            </div>
        );
    }
    return (
        <select
            value={val}
            className="fwi"
            onChange={(e) => setVal(e.target.value)}
            onBlur={(e) => toggle(true)}
            autoFocus
            style={style}
        >
            {(options || []).map((v, i) => (
                <option value={i} key={"product_" + i.toString()}>
                    {options[i]}
                </option>
            ))}
        </select>
    );
};

export const DateEdit = ({
    val,
    setVal,
    show,
    toggle,
    style = {},
    showOnly = false,
}) => {
    //console.log("inside show Edit", val, show);
    if (show || showOnly) {
        return (
            <div
                className={showOnly ? "fws1" : "fws"}
                onClick={(e) => (showOnly ? "" : toggle(false))}
                style={style}
            >
                {console.log(val)}
                {val}
            </div>
        );
    }
    return (
        <input
            type="date"
            value={val}
            className="fwi"
            onChange={(e) => setVal(e.target.value)}
            onBlur={(e) => toggle(true)}
            autoFocus
            style={style}
            min={1}
        />
    );
};

export const TimeEdit = ({
    val,
    setVal,
    show,
    toggle,
    style = {},
    showOnly = false,
    num = false,
}) => {
    //console.log("inside show Edit", val, show);
    if (show || showOnly) {
        return (
            <div
                className={showOnly ? "fws1" : "fws"}
                onClick={(e) => (showOnly ? "" : toggle(false))}
                style={style}
            >
                {num ? val.toFixed(2) : val}
            </div>
        );
    }
    return (
        <input
            type="time"
            value={val}
            className="fwi"
            onChange={(e) => setVal(e.target.value)}
            onBlur={(e) => toggle(true)}
            autoFocus
            style={style}
            min={1}
        />
    );
};

import React from "react";
import "./style.css";
import { inWords } from "../../extra/helpers";
import {
    SelectEdit,
    DateEdit,
    TimeEdit,
    TextEdit,
} from "../../components/showEdit/ShowEdit";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusCircle } from "@fortawesome/free-solid-svg-icons";

import {
    TextField,
    InputLabel,
    MenuItem,
    Select,
    FormControl,
} from "@material-ui/core";
import { Row, Col, Button as Btn1, Modal } from "react-bootstrap";

// Toastify imports
import { toast } from "react-toastify";

// constants
const axios = require("axios");
const API_URL = "https://5techg.a2hosted.com/globaltyres_api/party/";

export class PrintBillShow extends React.PureComponent {
    render() {
        return (
            <div className="bill mx-5 my-3 printable1">
                <div className="bill-title gry">
                    <div>
                        <img src={this.props.logo} alt={this.props.logo} />
                    </div>
                    <div className="bill-title-block  tac">
                        <div>
                            {this.props.billSettings
                                ? this.props.billSettings[0].value
                                : ""}
                        </div>
                        <small>
                            {this.props.billSettings
                                ? this.props.billSettings[5].value
                                : ""}
                        </small>
                    </div>
                </div>
                <div className="bill-company-logos tac">
                    {this.props.logos.map((logoPath) => (
                        <img src={logoPath} alt={logoPath} />
                    ))}
                </div>
                <div className="bill-sub-title">
                    <div className="tal f2">Debit MaTiO</div>
                    <div className="tac f4">TAX INVOICE</div>
                    <div className="tar f2">Original</div>
                </div>
                <div className="info">
                    <div className="client">
                        <div>
                            <b>
                                {this.props.billEdits.party[0]
                                    ? this.props.billEdits.party[0].name
                                    : ""}
                            </b>
                        </div>
                        <div>
                            <div className="half-a">
                                <div>Mob No. : </div>
                                <div>
                                    {this.props.billEdits.party[0]
                                        ? this.props.billEdits.party[0].mobile
                                        : ""}
                                </div>
                            </div>
                        </div>
                        <div className="client-2">
                            <div className="half-a">
                                <div>Gstin : </div>
                                <div>
                                    <div>
                                        {this.props.billEdits.party[0]
                                            ? this.props.billEdits.party[0]
                                                  .gstin
                                            : ""}
                                    </div>
                                </div>
                            </div>
                            <div className="half-a">
                                <div>
                                    <b>Place of supply : </b>
                                </div>
                                <div>
                                    <div>
                                        {this.props.billEdits.party[0]
                                            ? this.props.billEdits.party[0]
                                                  .address
                                            : ""}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="details">
                        <div className="gry">
                            <div className="half-p">
                                <div> Invoice No. : </div>
                                <div className="p__5">GT/{this.props.id}</div>
                            </div>
                            <div className="half-p">
                                <div>{`Date  :  `}</div>
                                <div>{this.props.dateTime.split("T")[0]}</div>
                            </div>
                        </div>
                        <div>
                            <div className="half-p">
                                <div>
                                    <b>Vehicle No. : </b>
                                </div>
                                <div>{this.props.vehicleNo}</div>
                            </div>
                            <div className="half-p">
                                <div>
                                    <b>Time : </b>
                                </div>
                                <div>{this.props.dateTime.split("T")[0]}</div>
                            </div>
                            <div className="half-p">
                                <div>
                                    <b> Vehicle</b>
                                </div>
                                <div>{this.props.vehicleName}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="bill-table">
                    <div className="bill-table-header">
                        <div>Sr.</div>
                        <div>Product Name</div>
                        <div>HSN/SAC</div>
                        <div>Qty</div>
                        <div>Rate</div>
                        <div>GST%</div>
                        <div>Amount</div>
                    </div>
                    {(this.props.billEdits.billTable || []).map(
                        (val, index) => (
                            <div key={"bill-table-" + index.toString()}>
                                {false
                                    ? console.log(
                                          "bill table content ",
                                          index,
                                          " : ",
                                          val
                                      )
                                    : ""}
                                <div>{index + 1}</div>
                                <div>{val.productName}</div>
                                <div>{val.hsn}</div>
                                <div>{val.quantity}</div>
                                <div>{val.rate}</div>
                                <div>{val.cgst + val.sgst}</div>
                                <div className="tar px-2">
                                    {(val.rate * val.quantity).toFixed(2)}
                                </div>
                            </div>
                        )
                    )}
                </div>
                <div className="bill-table-footer">
                    <div className="half-a">
                        <div>GSTIN NO. : </div>
                        <div>
                            {this.props.billSettings
                                ? this.props.billSettings[6].value
                                : ""}
                        </div>
                    </div>
                    <div className="half-a">
                        <div>Sub Total</div>
                        <div className="tar">
                            {this.props.billEdits.subTotal.toFixed(2)}
                        </div>
                    </div>
                </div>
                <div className="bill-summary">
                    <div className="bill-summary-1">
                        <div className="bill-summary-bank-details">
                            <div className="p-2">
                                <div className="half-b">
                                    <div>
                                        <b>Bank Name: </b>
                                    </div>
                                    <div>
                                        {this.props.billSettings
                                            ? this.props.billSettings[1].value
                                            : ""}
                                    </div>
                                </div>
                                <div className="half-b">
                                    <div>
                                        <b>Bank A/c No.</b>
                                    </div>
                                    <div>
                                        {this.props.billSettings
                                            ? this.props.billSettings[2].value
                                            : ""}
                                    </div>
                                </div>
                                <div className="half-b">
                                    <div>
                                        <b>RTGS/IFSC Code</b>
                                    </div>
                                    <div>
                                        {this.props.billSettings
                                            ? this.props.billSettings[3].value
                                            : ""}
                                    </div>
                                    <div></div>
                                </div>
                            </div>
                            <div></div>
                        </div>
                        <div className="bill-summary-amount-in-words p-2">
                            <div className="half-a">
                                <div>
                                    <b>Total GST: </b>
                                </div>
                                <div>
                                    {inWords(
                                        parseFloat(
                                            this.props.billEdits.cgst +
                                                this.props.billEdits.sgst
                                        ).toFixed(2)
                                    )}
                                </div>
                            </div>
                            <div className="half-a">
                                <div>
                                    <b>Bill Ammount : </b>
                                </div>
                                <div>
                                    {inWords(
                                        parseFloat(
                                            this.props.billEdits.total
                                        ).toFixed(2)
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="bill-ammounts">
                        <div></div>
                        <div className="pr-2">
                            <div className="half-a pl-2">
                                <div>
                                    <b>Taxable Amount</b>
                                </div>
                                <div className="tar">
                                    <b>
                                        {parseFloat(
                                            this.props.billEdits.subTotal
                                        ).toFixed(2)}
                                    </b>
                                </div>
                            </div>
                            <div className="half-p">
                                <div>CGST</div>
                                <div className="tar">
                                    {parseFloat(
                                        this.props.billEdits.cgst
                                    ).toFixed(2)}
                                </div>
                            </div>
                            <div className="half-p">
                                <div>SGCT</div>
                                <div className="tar">
                                    {parseFloat(
                                        this.props.billEdits.sgst
                                    ).toFixed(2)}
                                </div>
                            </div>
                            <div className="half-p">
                                <div>Discount</div>
                                <div className="tar">
                                    {parseFloat(
                                        this.props.billEdits.discount[0]
                                    ).toFixed(2)}
                                </div>
                            </div>
                        </div>
                        <div className="half-p px-2">
                            <div>
                                <b>Grand Total</b>
                            </div>
                            <div className="tar">
                                {parseFloat(this.props.billEdits.total).toFixed(
                                    2
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="bill-footer">
                    <div className="bill-notes px-2">
                        <div className="half-a">
                            <div>
                                <b>{"Terms & condition : "}</b>
                            </div>
                            <div>SAHAS</div>
                        </div>
                        <ol>
                            {this.props.billSettings
                                ? this.props.billSettings[4].value
                                      .split("$")
                                      .map((val, key) => (
                                          <li key={key}>{val}</li>
                                      ))
                                : ""}
                        </ol>
                        <div>Transport</div>
                    </div>
                    <div className="bill-receiver-sign">
                        <div></div>
                        <div className="pb-3">
                            <b>Receivers Sign</b>
                        </div>
                    </div>
                    <div className="bill-authority-sign">
                        <div className="pt-4 tar pr-3">
                            {"For, "}
                            <b>
                                {this.props.billSettings
                                    ? this.props.billSettings[0].value
                                    : ""}
                            </b>
                        </div>
                        <div></div>
                        <div className="pb-4 tar pr-4">
                            <small>(Authorised Signature)</small>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export class PrintBillUpdate extends React.PureComponent {
    render() {
        return (
            <div className="bill mx-5 my-3">
                <div className="bill-title gry">
                    <div>
                        <img src={this.props.logo} alt={this.props.logo} />
                    </div>
                    <div className="bill-title-block  tac">
                        <div>
                            {this.props.billSettings
                                ? this.props.billSettings[0].value
                                : "Global Tyres default"}
                        </div>
                        <small>
                            {this.props.billSettings
                                ? this.props.billSettings[5].value
                                : "PATEL CHOWK, NEAR PETROL PUMP, SANGLI PH:-(0233) 2621046,2621047"}
                        </small>
                    </div>
                </div>
                <div className="bill-company-logos tac">
                    {this.props.logos.map((logoPath) => (
                        <img src={logoPath} alt={logoPath} />
                    ))}
                </div>
                <div className="bill-sub-title">
                    <div className="tal f2">Debit MaTiO</div>
                    <div className="tac f4">TAX INVOICE</div>
                    <div className="tar f2">Original</div>
                </div>
                <div className="info">
                    <div className="client">
                        <div>
                            <b>
                                {false ? (
                                    <TextEdit
                                        style={{ paddingLeft: 0 }}
                                        val={
                                            this.props.billEdits.client_name[0]
                                        }
                                        setVal={(val) =>
                                            this.props.setState({
                                                billEdits: {
                                                    ...this.props.billEdits,
                                                    client_name: [
                                                        val,
                                                        this.props.billEdits
                                                            .client_name[1],
                                                    ],
                                                },
                                            })
                                        }
                                        show={
                                            this.props.billEdits.client_name[1]
                                        }
                                        toggle={(val) =>
                                            this.props.setState({
                                                billEdits: {
                                                    ...this.props.billEdits,
                                                    client_name: [
                                                        this.props.billEdits
                                                            .client_name[0],
                                                        val,
                                                    ],
                                                },
                                            })
                                        }
                                    />
                                ) : (
                                    ""
                                )}
                                {
                                    <SelectEdit
                                        val={
                                            this.props.billEdits.client_name[0]
                                        }
                                        setVal={(v) => {
                                            this.props.setState({
                                                ...this.props,
                                                billEdits: {
                                                    ...this.props.billEdits,
                                                    client_name: [
                                                        parseInt(v),
                                                        this.props.billEdits
                                                            .client_name[1],
                                                    ],
                                                },
                                            });
                                        }}
                                        show={
                                            this.props.billEdits.client_name[1]
                                        }
                                        toggle={(v) => {
                                            this.props.setState({
                                                ...this.props,
                                                billEdits: {
                                                    ...this.props.billEdits,
                                                    client_name: [
                                                        this.props.billEdits
                                                            .client_name[0],
                                                        v,
                                                    ],
                                                },
                                            });
                                        }}
                                        options={this.props.allParties.map(
                                            (v) => v.name
                                        )}
                                    />
                                }
                            </b>
                        </div>
                        <div>
                            <div className="half-a">
                                <div>Mob No. : </div>
                                <div>
                                    {this.props.allParties[
                                        parseInt(
                                            this.props.billEdits.client_name
                                        )
                                    ]
                                        ? this.props.allParties[
                                              parseInt(
                                                  this.props.billEdits
                                                      .client_name
                                              )
                                          ].mobile
                                        : ""}
                                </div>
                            </div>
                        </div>
                        <div className="client-2">
                            <div className="half-a">
                                <div>Gstin : </div>
                                <div>
                                    <div>
                                        {this.props.allParties[
                                            parseInt(
                                                this.props.billEdits.client_name
                                            )
                                        ]
                                            ? this.props.allParties[
                                                  parseInt(
                                                      this.props.billEdits
                                                          .client_name
                                                  )
                                              ].gstin
                                            : ""}
                                    </div>
                                </div>
                            </div>
                            <div className="half-a">
                                <div>
                                    <b>Place of supply : </b>
                                </div>
                                <div>
                                    <div>
                                        {this.props.allParties[
                                            parseInt(
                                                this.props.billEdits.client_name
                                            )
                                        ]
                                            ? this.props.allParties[
                                                  parseInt(
                                                      this.props.billEdits
                                                          .client_name
                                                  )
                                              ].address
                                            : ""}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="details">
                        <div className="gry">
                            <div className="half-p">
                                <div> Invoice No. : </div>
                                <div className="p__5">GT/{this.props.id}</div>
                            </div>
                            <div className="half-p">
                                <div>{`Date  :  `}</div>
                                {false ? (
                                    <div>16/06/2020</div>
                                ) : (
                                    <DateEdit
                                        val={this.props.billEdits.date[0]}
                                        setVal={(val) =>
                                            this.props.setState({
                                                billEdits: {
                                                    ...this.props.billEdits,
                                                    date: [
                                                        val,
                                                        this.props.billEdits
                                                            .date[1],
                                                    ],
                                                },
                                            })
                                        }
                                        show={this.props.billEdits.date[1]}
                                        toggle={(val) =>
                                            this.props.setState({
                                                billEdits: {
                                                    ...this.props.billEdits,
                                                    date: [
                                                        this.props.billEdits
                                                            .date[0],
                                                        val,
                                                    ],
                                                },
                                            })
                                        }
                                    />
                                )}
                            </div>
                        </div>
                        <div>
                            <div className="half-p">
                                <div>
                                    <b>Vehicle No. : </b>
                                </div>
                                <TextEdit
                                    val={this.props.billEdits.vehicleNo[0]}
                                    setVal={(val) =>
                                        this.props.setState({
                                            billEdits: {
                                                ...this.props.billEdits,
                                                vehicleNo: [
                                                    val,
                                                    this.props.billEdits
                                                        .vehicleNo[1],
                                                ],
                                            },
                                        })
                                    }
                                    show={this.props.billEdits.vehicleNo[1]}
                                    toggle={(val) =>
                                        this.props.setState({
                                            billEdits: {
                                                ...this.props.billEdits,
                                                vehicleNo: [
                                                    this.props.billEdits
                                                        .vehicleNo[0],
                                                    val,
                                                ],
                                            },
                                        })
                                    }
                                />
                            </div>
                            <div className="half-p">
                                <div>
                                    <b>Time : </b>
                                </div>
                                <TimeEdit
                                    val={this.props.billEdits.time[0]}
                                    setVal={(val) =>
                                        this.props.setState({
                                            billEdits: {
                                                ...this.props.billEdits,
                                                time: [
                                                    val,
                                                    this.props.billEdits
                                                        .time[1],
                                                ],
                                            },
                                        })
                                    }
                                    show={this.props.billEdits.time[1]}
                                    toggle={(val) =>
                                        this.props.setState({
                                            billEdits: {
                                                ...this.props.billEdits,
                                                time: [
                                                    this.props.billEdits
                                                        .time[0],
                                                    val,
                                                ],
                                            },
                                        })
                                    }
                                />
                            </div>
                            <div className="half-p">
                                <div>
                                    <b> Vehicle</b>
                                </div>
                                <TextEdit
                                    val={this.props.billEdits.vehicleName[0]}
                                    setVal={(val) =>
                                        this.props.setState({
                                            billEdits: {
                                                ...this.props.billEdits,
                                                vehicleName: [
                                                    val,
                                                    this.props.billEdits
                                                        .vehicleName[1],
                                                ],
                                            },
                                        })
                                    }
                                    show={this.props.billEdits.vehicleName[1]}
                                    toggle={(val) =>
                                        this.props.setState({
                                            billEdits: {
                                                ...this.props.billEdits,
                                                vehicleName: [
                                                    this.props.billEdits
                                                        .vehicleName[0],
                                                    val,
                                                ],
                                            },
                                        })
                                    }
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="bill-table">
                    <div className="bill-table-header">
                        <div>Sr.</div>
                        <div>Product Name</div>
                        <div>HSN/SAC</div>
                        <div>Qty</div>
                        <div>Rate</div>
                        <div>GST%</div>
                        <div>Amount</div>
                    </div>
                    {(this.props.billEdits.billTable || []).map(
                        (val, index) => (
                            <div key={"bill-table-" + index.toString()}>
                                {false
                                    ? console.log(
                                          "bill table content ",
                                          index,
                                          " : ",
                                          val
                                      )
                                    : ""}
                                <div>{index + 1}</div>
                                <div>{val.productName}</div>
                                <div>{val.hsn}</div>
                                <div>{val.quantity}</div>
                                <div>{val.rate}</div>
                                <div>{val.cgst + val.sgst}</div>
                                <div className="tar px-2">
                                    {(val.rate * val.quantity).toFixed(2)}
                                </div>
                            </div>
                        )
                    )}
                </div>
                <div className="bill-table-footer">
                    <div className="half-a">
                        <div>GSTIN NO. : </div>
                        <div>
                            {this.props.billSettings
                                ? this.props.billSettings[6].value
                                : ""}
                        </div>
                    </div>
                    <div className="half-a">
                        <div>Sub Total</div>
                        <div className="tar">
                            {this.props.billEdits.subTotal.toFixed(2)}
                        </div>
                    </div>
                </div>
                <div className="bill-summary">
                    <div className="bill-summary-1">
                        <div className="bill-summary-bank-details">
                            <div className="p-2">
                                <div className="half-b">
                                    <div>
                                        <b>Bank Name: </b>
                                    </div>
                                    <div>
                                        {this.props.billSettings
                                            ? this.props.billSettings[1].value
                                            : "BANK OF INDIA"}
                                    </div>
                                </div>
                                <div className="half-b">
                                    <div>
                                        <b>Bank A/c No.</b>
                                    </div>
                                    <div>
                                        {this.props.billSettings
                                            ? this.props.billSettings[2].value
                                            : "1609301100000075"}
                                    </div>
                                </div>
                                <div className="half-b">
                                    <div>
                                        <b>RTGS/IFSC Code</b>
                                    </div>
                                    <div>
                                        {this.props.billSettings
                                            ? this.props.billSettings[3].value
                                            : "BKID001609"}
                                    </div>
                                    <div></div>
                                </div>
                            </div>
                            <div></div>
                        </div>
                        <div className="bill-summary-amount-in-words p-2">
                            <div className="half-a">
                                <div>
                                    <b>Total GST: </b>
                                </div>
                                <div>
                                    {inWords(
                                        parseFloat(
                                            this.props.billEdits.cgst +
                                                this.props.billEdits.sgst
                                        ).toFixed(2)
                                    )}
                                </div>
                            </div>
                            <div className="half-a">
                                <div>
                                    <b>Bill Ammount : </b>
                                </div>
                                <div>
                                    {inWords(
                                        parseFloat(
                                            this.props.billEdits.total[0]
                                        ).toFixed(2)
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="bill-ammounts">
                        <div></div>
                        <div className="pr-2">
                            <div className="half-a pl-2">
                                <div>
                                    <b>Taxable Amount</b>
                                </div>
                                <div className="tar">
                                    <b>
                                        {this.props.billEdits.subTotal.toFixed(
                                            2
                                        )}
                                    </b>
                                </div>
                            </div>
                            <div className="half-p">
                                <div>CGST</div>
                                <div className="tar">
                                    {this.props.billEdits.cgst.toFixed(2)}
                                </div>
                            </div>
                            <div className="half-p">
                                <div>SGCT</div>
                                <div className="tar">
                                    {this.props.billEdits.sgst.toFixed(2)}
                                </div>
                            </div>
                            <div className="half-p">
                                <div>Discount</div>
                                <div className="tar">
                                    {parseFloat(
                                        this.props.billEdits.discount[0]
                                    ).toFixed(2)}
                                </div>
                            </div>
                        </div>
                        <div className="half-p px-2">
                            <div>
                                <b>Grand Total</b>
                            </div>
                            <div className="tar">
                                {parseFloat(
                                    this.props.billEdits.total[0]
                                ).toFixed(2)}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="bill-footer">
                    <div className="bill-notes px-2">
                        <div className="half-a">
                            <div>
                                <b>{"Terms & condition : "}</b>
                            </div>
                            <div>SAHAS</div>
                        </div>
                        {this.props.billSettings ? (
                            <ol>
                                {this.props.billSettings[4].value
                                    .split("$")
                                    .map((val, key) => (
                                        <li key={key}>{val}</li>
                                    ))}
                            </ol>
                        ) : (
                            <ol>
                                <li>{`Goods Carried Will Not Be Accepted`}</li>
                                <li>{`"Subjected To Sangli Juridictins Only.   E.&.O.E"`}</li>
                                <li>{`All tyers warranties covered by themanufacturing companies & all the cliamsfinalized by company.`}</li>
                            </ol>
                        )}
                        <div>Transport</div>
                    </div>
                    <div className="bill-receiver-sign">
                        <div></div>
                        <div className="pb-3">
                            <b>Receivers Sign</b>
                        </div>
                    </div>
                    <div className="bill-authority-sign">
                        <div className="pt-4 tar pr-3">
                            For,{" "}
                            <b>
                                {this.props.billSettings
                                    ? this.props.billSettings[0].value
                                    : ""}
                            </b>
                        </div>
                        <div></div>
                        <div className="pb-4 tar pr-4">
                            <small>(Authorised Signature)</small>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export class PrintBillCreate extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            showAddModal: false,
            value: "1",
            id: "",
            name: "",
            mobile: "",
            address: "",
            gstin: "",
            type: 1,
            status: 0,
            lastModified: "",
            partiesData: null,
        };
    }

    handleAddSubmit(e) {
        e.preventDefault();
        let data = {
            name: this.state.name,
            mobile: this.state.mobile,
            address: this.state.address,
            gstin: this.state.gstin,
            type: this.state.type,
            status: 0,
        };
        console.log(data);
        axios
            .post(API_URL, data)
            .then((res) => {
                toast.success("party details added successfully");
            })
            .catch((err) => {
                console.log(err);
            });
    }

    renderAddPartyModal() {
        return (
            <Modal
                show={this.state.showAddModal}
                onHide={(e) => this.setState({ showAddModal: false })}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Add New Party
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form noValidate autoComplete="off">
                        <div className="mt-3">
                            <Row>
                                <Col size="12">
                                    <TextField
                                        id="partyName"
                                        label="Party name"
                                        variant="outlined"
                                        className="m-2"
                                        defaultValue=""
                                        onChange={(e) => {
                                            this.setState({
                                                name: e.target.value,
                                            });
                                        }}
                                    />
                                </Col>
                                <Col>
                                    <TextField
                                        id="mobile"
                                        label="Mobile"
                                        variant="outlined"
                                        className="m-2"
                                        defaultValue=""
                                        onChange={(e) =>
                                            this.setState({
                                                mobile: e.target.value,
                                            })
                                        }
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <TextField
                                        id="address"
                                        s
                                        label="Address"
                                        variant="outlined"
                                        className="m-2"
                                        defaultValue=""
                                        onChange={(e) =>
                                            this.setState({
                                                address: e.target.value,
                                            })
                                        }
                                    />
                                </Col>
                                <Col>
                                    <TextField
                                        id="gstin"
                                        label="Gstin"
                                        variant="outlined"
                                        className="m-2"
                                        defaultValue=""
                                        onChange={(e) =>
                                            this.setState({
                                                gstin: e.target.value,
                                            })
                                        }
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <FormControl
                                        variant="filled"
                                        style={{
                                            minWidth: "120px",
                                        }}
                                        className="mt-2 ml-2"
                                    >
                                        <InputLabel id="demo-simple-select-outlined-label">
                                            Type
                                        </InputLabel>
                                        <Select
                                            labelId="demo-simple-select-outlined-label"
                                            id="demo-simple-select-outlined"
                                            label="type"
                                            defaultValue={this.state.type}
                                            onChange={(e) =>
                                                this.setState({
                                                    type: e.target.value,
                                                })
                                            }
                                        >
                                            <MenuItem value={1}>
                                                Wholesaler
                                            </MenuItem>
                                            <MenuItem value={2}>
                                                Retailer
                                            </MenuItem>
                                            <MenuItem value={3}>
                                                Customer
                                            </MenuItem>
                                        </Select>
                                    </FormControl>
                                </Col>
                            </Row>
                        </div>
                        <hr />
                        <div className="mt-2 mr-1">
                            <Btn1
                                style={{ float: "right" }}
                                onClick={(e) => {
                                    this.setState({
                                        showAddModal: false,
                                    });
                                    this.handleAddSubmit(e);
                                }}
                            >
                                Add Party
                            </Btn1>
                        </div>
                    </form>
                </Modal.Body>
            </Modal>
        );
    }

    render() {
        return (
            <div className="bill mx-5 my-3">
                <div className="bill-title gry">
                    <div>
                        <img src={this.props.logo} alt={this.props.logo} />
                    </div>
                    <div className="bill-title-block  tac">
                        <div>
                            {this.props.billSettings
                                ? this.props.billSettings[0].value
                                : ""}
                        </div>
                        <small>
                            {this.props.billSettings
                                ? this.props.billSettings[5].value
                                : ""}
                        </small>
                    </div>
                </div>
                <div className="bill-company-logos tac">
                    {this.props.logos.map((logoPath) => (
                        <img src={logoPath} alt={logoPath} />
                    ))}
                </div>
                <div className="bill-sub-title">
                    <div className="tal f2">Debit MaTiO</div>
                    <div className="tac f4">TAX INVOICE</div>
                    <div className="tar f2">Original</div>
                </div>
                <div className="info">
                    <div className="client">
                        <div className="half-a">
                            <div className="w-25 float-left">
                                <button
                                    className="btn py-0"
                                    onClick={(e) => {
                                        this.setState({
                                            showAddModal: true,
                                            type: 1,
                                        });
                                    }}
                                >
                                    <FontAwesomeIcon icon={faPlusCircle} />
                                </button>
                            </div>
                            <div className="w-75 float-left">
                                <b>
                                    {
                                        <SelectEdit
                                            val={
                                                this.props.billEdits
                                                    .client_name[0]
                                            }
                                            setVal={(v) => {
                                                this.props.setState({
                                                    ...this.props,
                                                    billEdits: {
                                                        ...this.props.billEdits,
                                                        client_name: [
                                                            parseInt(v),
                                                            this.props.billEdits
                                                                .client_name[1],
                                                        ],
                                                        client_id: this.props
                                                            .allParties[
                                                            parseInt(v)
                                                        ]
                                                            ? this.props
                                                                  .allParties[
                                                                  parseInt(v)
                                                              ].id
                                                            : "problem",
                                                    },
                                                });
                                            }}
                                            show={
                                                this.props.billEdits
                                                    .client_name[1]
                                            }
                                            toggle={(v) => {
                                                this.props.setState({
                                                    ...this.props,
                                                    billEdits: {
                                                        ...this.props.billEdits,
                                                        client_name: [
                                                            this.props.billEdits
                                                                .client_name[0],
                                                            v,
                                                        ],
                                                    },
                                                });
                                            }}
                                            options={this.props.allParties.map(
                                                (v) => v.name
                                            )}
                                        />
                                    }
                                </b>
                            </div>
                        </div>

                        <div>
                            <div className="half-a">
                                <div>Mob No. : </div>
                                <div>
                                    {this.props.allParties[
                                        parseInt(
                                            this.props.billEdits.client_name
                                        )
                                    ]
                                        ? this.props.allParties[
                                              parseInt(
                                                  this.props.billEdits
                                                      .client_name
                                              )
                                          ].mobile
                                        : ""}
                                </div>
                            </div>
                        </div>
                        <div className="client-2">
                            <div className="half-a">
                                <div>Gstin : </div>
                                <div>
                                    <div>
                                        {this.props.allParties[
                                            parseInt(
                                                this.props.billEdits.client_name
                                            )
                                        ]
                                            ? this.props.allParties[
                                                  parseInt(
                                                      this.props.billEdits
                                                          .client_name
                                                  )
                                              ].gstin
                                            : ""}
                                    </div>
                                </div>
                            </div>
                            <div className="half-a">
                                <div>
                                    <b>Place of supply : </b>
                                </div>
                                <div>
                                    <div>
                                        {this.props.allParties[
                                            parseInt(
                                                this.props.billEdits.client_name
                                            )
                                        ]
                                            ? this.props.allParties[
                                                  parseInt(
                                                      this.props.billEdits
                                                          .client_name
                                                  )
                                              ].address
                                            : ""}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="details">
                        <div className="gry">
                            <div className="half-p">
                                <div> Invoice No. : </div>
                                <div className="p__5">
                                    GT/{this.props.newInvoiceNo}
                                </div>
                            </div>
                            <div className="half-p">
                                <div>{`Date  :  `}</div>
                                {false ? (
                                    <div>16/06/2020</div>
                                ) : (
                                    <DateEdit
                                        val={this.props.billEdits.date[0]}
                                        setVal={(val) =>
                                            this.props.setState({
                                                billEdits: {
                                                    ...this.props.billEdits,
                                                    date: [
                                                        val,
                                                        this.props.billEdits
                                                            .date[1],
                                                    ],
                                                },
                                            })
                                        }
                                        show={this.props.billEdits.date[1]}
                                        toggle={(val) =>
                                            this.props.setState({
                                                billEdits: {
                                                    ...this.props.billEdits,
                                                    date: [
                                                        this.props.billEdits
                                                            .date[0],
                                                        val,
                                                    ],
                                                },
                                            })
                                        }
                                    />
                                )}
                            </div>
                        </div>
                        <div>
                            <div className="half-p">
                                <div>
                                    <b>Vehicle No. : </b>
                                </div>
                                <TextEdit
                                    val={this.props.billEdits.vehicleNo[0]}
                                    setVal={(val) =>
                                        this.props.setState({
                                            billEdits: {
                                                ...this.props.billEdits,
                                                vehicleNo: [
                                                    val,
                                                    this.props.billEdits
                                                        .vehicleNo[1],
                                                ],
                                            },
                                        })
                                    }
                                    show={this.props.billEdits.vehicleNo[1]}
                                    toggle={(val) =>
                                        this.props.setState({
                                            billEdits: {
                                                ...this.props.billEdits,
                                                vehicleNo: [
                                                    this.props.billEdits
                                                        .vehicleNo[0],
                                                    val,
                                                ],
                                            },
                                        })
                                    }
                                />
                            </div>
                            <div className="half-p">
                                <div>
                                    <b>Time : </b>
                                </div>
                                <TimeEdit
                                    val={this.props.billEdits.time[0]}
                                    setVal={(val) =>
                                        this.props.setState({
                                            billEdits: {
                                                ...this.props.billEdits,
                                                time: [
                                                    val,
                                                    this.props.billEdits
                                                        .time[1],
                                                ],
                                            },
                                        })
                                    }
                                    show={this.props.billEdits.time[1]}
                                    toggle={(val) =>
                                        this.props.setState({
                                            billEdits: {
                                                ...this.props.billEdits,
                                                time: [
                                                    this.props.billEdits
                                                        .time[0],
                                                    val,
                                                ],
                                            },
                                        })
                                    }
                                />
                            </div>
                            <div className="half-p">
                                <div>
                                    <b> Vehicle</b>
                                </div>
                                <TextEdit
                                    val={this.props.billEdits.vehicleName[0]}
                                    setVal={(val) =>
                                        this.props.setState({
                                            billEdits: {
                                                ...this.props.billEdits,
                                                vehicleName: [
                                                    val,
                                                    this.props.billEdits
                                                        .vehicleName[1],
                                                ],
                                            },
                                        })
                                    }
                                    show={this.props.billEdits.vehicleName[1]}
                                    toggle={(val) =>
                                        this.props.setState({
                                            billEdits: {
                                                ...this.props.billEdits,
                                                vehicleName: [
                                                    this.props.billEdits
                                                        .vehicleName[0],
                                                    val,
                                                ],
                                            },
                                        })
                                    }
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="bill-table">
                    <div className="bill-table-header">
                        <div>Sr.</div>
                        <div>Product Name</div>
                        <div>HSN/SAC</div>
                        <div>Qty</div>
                        <div>Rate</div>
                        <div>GST%</div>
                        <div>Amount</div>
                    </div>
                    {(this.props.billEdits.billTable || []).map(
                        (val, index) => (
                            <div key={"bill-table-" + index.toString()}>
                                <div>{index + 1}</div>
                                <div>
                                    {
                                        <SelectEdit
                                            val={val[0]}
                                            setVal={(v) => {
                                                let rw =
                                                    this.props.billEdits
                                                        .billTable;
                                                rw[index][0] = v;
                                                this.props.setState({
                                                    ...this.props,
                                                    billEdits: {
                                                        ...this.props.billEdits,
                                                        billTable: rw,
                                                    },
                                                });
                                                this.props.adjustAmmounts();
                                            }}
                                            show={val[2]}
                                            toggle={(v) => {
                                                let rw =
                                                    this.props.billEdits
                                                        .billTable;
                                                rw[index][2] = v;
                                                this.props.setState({
                                                    ...this.props,
                                                    billEdits: {
                                                        ...this.props.billEdits,
                                                        billTable: rw,
                                                    },
                                                });
                                            }}
                                            options={this.props.products.map(
                                                (v) => v.productName
                                            )}
                                        />
                                    }
                                </div>
                                <div>
                                    {this.props.products[val[0]]
                                        ? this.props.products[val[0]].hsn
                                        : ""}
                                </div>
                                <div>
                                    {
                                        <TextEdit
                                            val={val[1]}
                                            setVal={(v) => {
                                                let rw =
                                                    this.props.billEdits
                                                        .billTable;
                                                rw[index][1] = parseInt(v);
                                                this.props.setState({
                                                    ...this.props,
                                                    billEdits: {
                                                        ...this.props.billEdits,
                                                        billTable: rw,
                                                    },
                                                });
                                                this.props.adjustAmmounts();
                                            }}
                                            show={val[3]}
                                            toggle={(val) => {
                                                let rw =
                                                    this.props.billEdits
                                                        .billTable;
                                                rw[index][3] = val;
                                                this.props.setState({
                                                    ...this.props,
                                                    billEdits: {
                                                        ...this.props.billEdits,
                                                        billTable: rw,
                                                    },
                                                });
                                            }}
                                            num={true}
                                        />
                                    }
                                </div>
                                <div>
                                    {this.props.products[val[0]]
                                        ? this.props.products[
                                              val[0]
                                          ].rate.toFixed(2)
                                        : ""}
                                </div>
                                <div>
                                    {this.props.products[val[0]]
                                        ? this.props.products[val[0]].cgst +
                                          this.props.products[val[0]].sgst
                                        : ""}
                                </div>
                                <div className="tar px-2">
                                    {(
                                        val[1] *
                                        (this.props.products[val[0]]
                                            ? this.props.products[
                                                  val[0]
                                              ].rate.toFixed(2)
                                            : 0)
                                    ).toFixed(2)}
                                </div>
                            </div>
                        )
                    )}
                    <div>
                        <div>
                            <button
                                className="btn"
                                onClick={(e) => {
                                    let last =
                                        this.props.billEdits.billTable.length >
                                        0
                                            ? this.props.billEdits.billTable[
                                                  this.props.billEdits.billTable
                                                      .length - 1
                                              ]
                                            : ["abc"];
                                    if (
                                        last[0] ||
                                        last[1] ||
                                        last[2] ||
                                        last[3] ||
                                        last[4]
                                    ) {
                                        last = this.props.billEdits.billTable;
                                        last.push([0, 1, true, true]);
                                        this.props.setState({
                                            ...this.props,
                                            billEdits: {
                                                ...this.props.billEdits,
                                                billTable: last,
                                            },
                                        });
                                        this.props.adjustAmmounts();
                                    }
                                }}
                            >
                                <FontAwesomeIcon
                                    icon={faPlusCircle}
                                    style={{ color: "green" }}
                                />
                            </button>
                        </div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div className="tar p-2"></div>
                    </div>
                </div>
                <div className="bill-table-footer">
                    <div className="half-a">
                        <div>GSTIN NO. : </div>
                        <div>
                            {this.props.billSettings
                                ? this.props.billSettings[6].value
                                : `27AVAPP6656L1Z0`}
                        </div>
                    </div>
                    <div className="half-a">
                        <div>Sub Total</div>
                        <div className="tar">
                            {this.props.billEdits.subTotal.toFixed(2)}
                        </div>
                    </div>
                </div>
                <div className="bill-summary">
                    <div className="bill-summary-1">
                        <div className="bill-summary-bank-details">
                            <div className="p-2">
                                <div className="half-b">
                                    <div>
                                        <b>Bank Name: </b>
                                    </div>
                                    <div>
                                        {this.props.billSettings
                                            ? this.props.billSettings[1].value
                                            : "BANK OF INDIA"}
                                    </div>
                                </div>
                                <div className="half-b">
                                    <div>
                                        <b>Bank A/c No.</b>
                                    </div>
                                    <div>
                                        {this.props.billSettings
                                            ? this.props.billSettings[2].value
                                            : "1609301100000075"}
                                    </div>
                                </div>
                                <div className="half-b">
                                    <div>
                                        <b>RTGS/IFSC Code</b>
                                    </div>
                                    <div>
                                        {this.props.billSettings
                                            ? this.props.billSettings[3].value
                                            : "BKID001609"}
                                    </div>
                                    <div></div>
                                </div>
                            </div>
                            <div></div>
                        </div>
                        <div className="bill-summary-amount-in-words p-2">
                            <div className="half-a">
                                <div>
                                    <b>Total GST: </b>
                                </div>
                                <div>
                                    {inWords(
                                        parseFloat(
                                            this.props.billEdits.cgst +
                                                this.props.billEdits.sgst
                                        ).toFixed(2)
                                    )}
                                </div>
                            </div>
                            <div className="half-a">
                                <div>
                                    <b>Bill Ammount : </b>
                                </div>
                                <div>
                                    {inWords(
                                        parseFloat(
                                            this.props.billEdits.total[0]
                                        ).toFixed(2)
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="bill-ammounts">
                        <div></div>
                        <div className="pr-2">
                            <div className="half-a pl-2">
                                <div>
                                    <b>Taxable Amount</b>
                                </div>
                                <div className="tar">
                                    <b>
                                        {this.props.billEdits.subTotal.toFixed(
                                            2
                                        )}
                                    </b>
                                </div>
                            </div>
                            <div className="half-p">
                                <div>CGST</div>
                                <div className="tar">
                                    {this.props.billEdits.cgst.toFixed(2)}
                                </div>
                            </div>
                            <div className="half-p">
                                <div>SGCT</div>
                                <div className="tar">
                                    {this.props.billEdits.sgst.toFixed(2)}
                                </div>
                            </div>
                        </div>
                        <div className="half-p px-2">
                            <div>
                                <b>Grand Total</b>
                            </div>
                            <div className="tar">
                                <TextEdit
                                    showOnly={
                                        parseFloat(
                                            this.props.billEdits.subTotal
                                        ) === parseFloat(0.0)
                                            ? true
                                            : false
                                    }
                                    val={parseFloat(
                                        this.props.billEdits.total[0]
                                    ).toFixed(2)}
                                    setVal={(val) =>
                                        this.props.setState({
                                            billEdits: {
                                                ...this.props.billEdits,
                                                total: [
                                                    val,
                                                    this.props.billEdits
                                                        .total[1],
                                                ],
                                                subTotal: val / 1.28,
                                                cgst: (val - val / 1.28) / 2,
                                                sgst: (val - val / 1.28) / 2,
                                            },
                                        })
                                    }
                                    show={this.props.billEdits.total[1]}
                                    toggle={(val) =>
                                        this.props.setState({
                                            billEdits: {
                                                ...this.props.billEdits,
                                                total: [
                                                    this.props.billEdits
                                                        .total[0],
                                                    val,
                                                ],
                                            },
                                        })
                                    }
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="bill-footer">
                    <div className="bill-notes px-2">
                        <div className="half-a">
                            <div>
                                <b>{"Terms & condition : "}</b>
                            </div>
                            <div>SAHAS</div>
                        </div>
                        {this.props.billSettings ? (
                            <ol>
                                {this.props.billSettings[4].value
                                    .split("$")
                                    .map((val, key) => (
                                        <li key={key}>{val}</li>
                                    ))}
                            </ol>
                        ) : (
                            <ol>
                                <li>{`Goods Carried Will Not Be Accepted`}</li>
                                <li>{`"Subjected To Sangli Juridictins Only.   E.&.O.E"`}</li>
                                <li>{`All tyers warranties covered by themanufacturing companies & all the cliamsfinalized by company.`}</li>
                            </ol>
                        )}
                        <div>Transport</div>
                    </div>
                    <div className="bill-receiver-sign">
                        <div></div>
                        <div className="pb-3">
                            <b>Receivers Sign</b>
                        </div>
                    </div>
                    <div className="bill-authority-sign">
                        <div className="pt-4 tar pr-3">
                            For,{" "}
                            <b>
                                {this.props.billSettings
                                    ? this.props.billSettings[0].value
                                    : ""}
                            </b>
                        </div>
                        <div></div>
                        <div className="pb-4 tar pr-4">
                            <small>(Authorised Signature)</small>
                        </div>
                    </div>
                </div>
                {this.renderAddPartyModal()}
            </div>
        );
    }
}

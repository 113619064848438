import React, { Component } from "react";
import "./style.css";
import "bootstrap/dist/css/bootstrap.min.css";

// material UI imports
import {
  AppBar,
  Tab,
  Avatar,
} from "@material-ui/core";
import { TabContext, TabList, TabPanel } from "@material-ui/lab";

//API handling components
import { API_URL } from "../../global";

import Company from "./Company";

// datatable setup
import jsZip from "jszip";
window.JSZip = jsZip;
var $ = require("jquery");
$.DataTable = require("datatables.net");
require("datatables.net-bs4");
require("datatables.net-autofill-bs4");
require("datatables.net-buttons-bs4");
require("datatables.net-buttons/js/buttons.colVis");
require("datatables.net-buttons/js/buttons.flash");
require("datatables.net-buttons/js/buttons.html5");
require("datatables.net-buttons/js/buttons.print");
require("datatables.net-responsive-bs4");
require("datatables.net-scroller-bs4");
require("datatables.net-select-bs4");
require("pdfmake");

// constants
const axios = require("axios");
const logo1 = require("./logo1.png");
const logo2 = require("./logo2.png");
const logo3 = require("./logo3.png");

class BillManager extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: "1",
    };
  }

  handleTabs = (event, newValue) => {
    this.setState({ value: newValue });
  };

  render() {
    return (
      <TabContext
        value={this.state.value}
        className="container-fluid border m-0 p-0 main"
      >
        <AppBar position="static" color="default">
          <TabList
            onChange={this.handleTabs}
            aria-label="simple tabs example"
            indicatorColor="primary"
            textColor="primary"
          >
            <Tab
              icon={
                <Avatar
                  src={this.props.logos[0]}
                  alt="1"
                  style={{
                    borderRadius: 0,
                    border: "1px solid #0002",
                    boxShadow: "1px 1px 5px #000",
                  }}
                />
              }
              value="1"
            />
            <Tab
              icon={
                <Avatar
                  src={this.props.logos[1]}
                  alt="2"
                  style={{ borderRadius: 0 }}
                />
              }
              value="2"
            />
            <Tab
              icon={
                <Avatar
                  src={this.props.logos[2]}
                  alt="3"
                  style={{ borderRadius: 0 }}
                />
              }
              value="3"
            />
            <Tab
              icon={
                <Avatar
                  src={this.props.logos[3]}
                  alt="4"
                  style={{
                    borderRadius: 0,
                    border: "1px solid #ffffff22",
                    boxShadow: "1px 1px 5px black",
                  }}
                />
              }
              value="4"
            />
          </TabList>
        </AppBar>

        <TabPanel value="1" className="m-0 p-0">
          <Company
            company={this.state.value}
            key={Date.now()}
            refresh={this.props.refresh}
            logo={this.props.logo}
            logos={this.props.logos}
          />
        </TabPanel>
        <TabPanel value="2" className="m-0 p-0">
          <Company
            company={this.state.value}
            key={Date.now()}
            refresh={this.props.refresh}
            logo={this.props.logo}
            logos={this.props.logos}
          />
        </TabPanel>
        <TabPanel value="3" className="m-0 p-0">
          <Company
            company={this.state.value}
            key={Date.now()}
            refresh={this.props.refresh}
            logo={this.props.logo}
            logos={this.props.logos}
          />
        </TabPanel>
        <TabPanel value="4" className="m-0 p-0">
          <Company
            company={this.state.value}
            key={Date.now()}
            refresh={this.props.refresh}
            logo={this.props.logo}
            logos={this.props.logos}
          />
        </TabPanel>
      </TabContext>
    );
  }
}

export default BillManager;

import React, { Component } from "react";
import { Link } from "react-router-dom";

import "./style.css";
import "bootstrap/dist/css/bootstrap.min.css";

// material UI imports
import {
  AppBar,
  Tab,
  Button,
  TextField,
  InputLabel,
  MenuItem,
  Select,
  FormControl,
  Avatar,
} from "@material-ui/core";
import { TabContext, TabList, TabPanel } from "@material-ui/lab";
import { Row, Col, Button as Btn1, Modal, Badge } from "react-bootstrap";

// font awasome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPenAlt,
  faBook,
  faTrash,
  faSyncAlt,
} from "@fortawesome/free-solid-svg-icons";

// Toastify imports
import { ToastContainer, toast } from "react-toastify";
// import "../ledger_manager/exportManager/node_modules/react-toastify/dist/ReactToastify.css";

//API handling components
import { API_URL } from "../../global";

import Company from "./Company";

// datatable setup
import jsZip from "jszip";
window.JSZip = jsZip;
var $ = require("jquery");
$.DataTable = require("datatables.net");
require("datatables.net-bs4");
require("datatables.net-autofill-bs4");
require("datatables.net-buttons-bs4");
require("datatables.net-buttons/js/buttons.colVis");
require("datatables.net-buttons/js/buttons.flash");
require("datatables.net-buttons/js/buttons.html5");
require("datatables.net-buttons/js/buttons.print");
require("datatables.net-responsive-bs4");
require("datatables.net-scroller-bs4");
require("datatables.net-select-bs4");
require("pdfmake");

// constants
const axios = require("axios");
const logo1 = require("./logo1.png");
const logo2 = require("./logo2.png");
const logo3 = require("./logo3.png");

class InventoryManager extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showAddModal: false,
      showUpdateModel: false,
      value: "1",
      activePartyId: "",
      activePartyName: "",
      activePartyMobile: "",
      activePartyAddress: "",
      activePartyType: 1,
      partiesData: null,
    };
  }

  fetchPartiesData() {
    /*
    let url = API_URL;
    const query = `SELECT * FROM partyView WHERE status=1;`;
    let data = { crossDomain: true, crossOrigin: true, query: query };
    axios
      .post(url, data)
      .then((res) => {
        console.log("party data: ", res.data);
        this.setState({ partiesData: res.data });
      })
      .catch((err) => {
        console.log("party data error: ", err);
      });
    */
    console.log("fetch billManger");
  }

  handleUpdateSubmit(e) {
    e.preventDefault();
    let url = API_URL;

    const query = `UPDATE party SET name="${this.state.activePartyName}", mobile="${this.state.activePartyMobile}", address="${this.state.activePartyAddress}", type=${this.state.activePartyType} WHERE id=${this.state.activePartyId};`;
    let data = {
      crossDomain: true,
      crossOrigin: true,
      query: query,
    };
    axios
      .post(url, data)
      .then((res) => {
        toast.success("Party details updated successfully");
        this.fetchPartiesData();
      })
      .catch((err) => {
        console.log("error while updating party data", err);
      });
  }

  handleAddSubmit(e) {
    e.preventDefault();
    let url = API_URL;

    const query = `INSERT INTO party(name, mobile, address, type) VALUES('${this.state.activePartyName}', '${this.state.activePartyMobile}', '${this.state.activePartyAddress}', ${this.state.activePartyType})`;
    let data = {
      crossDomain: true,
      crossOrigin: true,
      query: query,
    };
    axios
      .post(url, data)
      .then((res) => {
        toast.success("party details added successfully");
        setTimeout(() => {
          this.refreshParties();
        }, 2000);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  deleteRecord(id) {
    let url = API_URL;
    const query = `UPDATE party SET status = 0  WHERE id=${id};`;
    let data = { crossDomain: true, crossOrigin: true, query: query };
    axios
      .post(url, data)
      .then((res) => {
        console.log("deleted status data: ", res.data);
        console.log("Party deleted successfully");
        toast.error("Party deleted successfully");
        setTimeout(() => {
          this.refreshParties();
        }, 2000);
      })
      .catch((err) => {
        console.log("record delete error: ", err);
      });
  }

  handleTabs = (event, newValue) => {
    this.setState({ value: newValue });
  };

  refreshParties() {
    window.location.reload(false);
  }

  componentDidMount() {
    this.fetchPartiesData();
  }

  componentDidUpdate() {
    /*
    $("#party_table").DataTable({
      destroy: true,
      keys: true,
      buttons: [
        "copy",
        "csv",
        "excel",
        {
          extend: "pdf",
          messageTop: "<h4 style='text-align:center'>Users List</h4>",
          download: "open",
        },
        {
          extend: "print",
          messageTop: "<h4 style='text-align:center'>Users List</h4>",
          download: "open",
        },
      ],
    });
    */
  }

  renderPartiesData = () => {
    const parties = this.state.partiesData;

    if (parties == null) {
      return null;
    }

    return parties.map((party) => {
      return (
        <tr>
          <td align="center">
            <Badge variant="primary">{party["id"]}</Badge>{" "}
          </td>
          <td align="center">{party["name"]}</td>
          <td align="center">
            <a href={"tel:" + party["mobile"]}>
              <Button className="mx-1" color="primary" variant="secondary">
                {party["mobile"]}
              </Button>
            </a>
          </td>
          <td align="center">{party["address"]}</td>
          <td align="center">
            {party["type"] == 1 ? "Retailer" : "Distributor"}
          </td>
          <td align="center">
            {party["balance"] == null ? 0 : party["balance"]}
          </td>
          <td align="center">
            <Button
              color="secondary"
              variant="contained"
              onClick={(e) => {
                this.setState({
                  activePartyId: party["id"],
                  activePartyName: party["name"],
                  activePartyMobile: party["mobile"],
                  activePartyAddress: party["address"],
                  activePartyType: party["type"],
                  showUpdateModal: true,
                });
              }}
            >
              <FontAwesomeIcon icon={faPenAlt} />
            </Button>
            <Link to={`ledgerManager/${party["id"]}`}>
              <Button
                className="mx-1"
                color="primary"
                variant="contained"
                onClick={(e) => {}}
              >
                <FontAwesomeIcon icon={faBook} />
              </Button>
            </Link>
            <Button
              className="mx-1"
              color="danger"
              variant="contained"
              onClick={(e) => {
                if (window.confirm("Delete the item?")) {
                  this.deleteRecord(party["id"]);
                }
              }}
            >
              <FontAwesomeIcon icon={faTrash} />
            </Button>
          </td>
        </tr>
      );
    });
  };

  renderUpdatePartyModal() {
    return (
      <Modal
        show={this.state.showUpdateModal}
        onHide={(e) => this.setState({ showUpdateModal: false })}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Update Party
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form noValidate autoComplete="off">
            <div className="mt-3">
              <Row>
                <Col size="12">
                  <TextField
                    id="partyName"
                    label="Party name"
                    variant="outlined"
                    className="m-2"
                    defaultValue={this.state.activePartyName}
                    onChange={(e) => {
                      this.setState({
                        activePartyName: e.target.value,
                      });
                    }}
                  />
                </Col>
                <Col>
                  <TextField
                    id="mobile"
                    label="Mobile"
                    variant="outlined"
                    className="m-2"
                    defaultValue={this.state.activePartyMobile}
                    onChange={(e) =>
                      this.setState({
                        activePartyMobile: e.target.value,
                      })
                    }
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <TextField
                    id="address"
                    s
                    label="Address"
                    variant="outlined"
                    className="m-2"
                    defaultValue={this.state.activePartyAddress}
                    onChange={(e) =>
                      this.setState({
                        activePartyAddress: e.target.value,
                      })
                    }
                  />
                </Col>
                <Col>
                  <FormControl
                    variant="filled"
                    style={{
                      minWidth: "120px",
                    }}
                    className="mt-2 ml-2"
                  >
                    <InputLabel id="demo-simple-select-outlined-label">
                      Type
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      label="type"
                      defaultValue={this.state.activePartyType}
                      onChange={(e) =>
                        this.setState({
                          activePartyType: e.target.value,
                        })
                      }
                    >
                      <MenuItem value={1}>Retailer</MenuItem>
                      <MenuItem value={2}>Distributor</MenuItem>
                    </Select>
                  </FormControl>
                </Col>
              </Row>
            </div>
            <hr />
            <div className="mt-2 mr-1">
              <Btn1
                style={{ float: "right" }}
                onClick={(e) => {
                  this.setState({
                    showUpdateModal: false,
                  });
                  this.handleUpdateSubmit(e);
                }}
              >
                Update
              </Btn1>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    );
  }

  render() {
    return (
      <TabContext
        value={this.state.value}
        className="container-fluid border m-0 p-0 main"
      >
        <AppBar position="static" color="default">
          <TabList
            onChange={this.handleTabs}
            aria-label="simple tabs example"
            indicatorColor="primary"
            textColor="primary"
          >
            <Tab
              icon={<Avatar src={this.props.logos[0]} alt="1" />}
              value="1"
            />
            <Tab
              icon={<Avatar src={this.props.logos[1]} alt="2" />}
              value="2"
            />
            <Tab
              icon={<Avatar src={this.props.logos[2]} alt="3" />}
              value="3"
            />
            <Tab
              icon={<Avatar src={this.props.logos[3]} alt="4" />}
              value="4"
            />
          </TabList>
        </AppBar>

        <TabPanel value="1" className="m-0 p-0">
          <Company
            company={this.state.value}
            key={Date.now()}
            refresh={this.props.refresh}
          />
        </TabPanel>
        <TabPanel value="2" className="m-0 p-0">
          <Company
            company={this.state.value}
            key={Date.now()}
            refresh={this.props.refresh}
          />
        </TabPanel>
        <TabPanel value="3" className="m-0 p-0">
          <Company
            company={this.state.value}
            key={Date.now()}
            refresh={this.props.refresh}
          />
        </TabPanel>
        <TabPanel value="4" className="m-0 p-0">
          <Company
            company={this.state.value}
            key={Date.now()}
            refresh={this.props.refresh}
          />
        </TabPanel>
      </TabContext>
    );
  }
}

export default InventoryManager;

import "./style.css";
import React from "react";
import logo from "../../logos/globalTyresLogo.jpg";
import { Link } from "react-router-dom";

const Menu = () => {
  return (
    <div className="menu">
      <div className="logo-space">
        <div className="company-container">
          <div className="company">Global Types</div>
        </div>
      </div>
      <div className="menu-links-cntainer">
        <ul className="menu-links">
          <li>
            <Link to="/">
              <button>Home</button>
            </Link>
          </li>
          <li>
            <Link to="/billManager/">
              <button>Bill Manager</button>
            </Link>
          </li>
          {false ? (
            <li>
              <Link to="/ledgerManager/">
                <button>Ledger Manager</button>
              </Link>
            </li>
          ) : (
            " "
          )}
          <li>
            <Link to="/partyManager/">
              <button>Party Manager</button>
            </Link>
          </li>
          <li>
            <Link to="/workerManager/">
              <button>Worker Manager</button>
            </Link>
          </li>
          <li>
            <Link to="/inventoryManager/">
              <button>Inventory Manager</button>
            </Link>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Menu;

import React, { Component } from "react";

//Bootstrap and jQuery libraries
import "bootstrap/dist/css/bootstrap.min.css";
import "jquery/dist/jquery.min.js";
//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import $ from "jquery";

// styles
import "./style.css";
import "bootstrap/dist/css/bootstrap.css";

// material UI imports
import {
    TableBody,
    TableContainer,
    Button,
    Paper,
    TextField,
} from "@material-ui/core";
import { DateEdit } from "../../components/showEdit/ShowEdit";
import { Row, Col, Button as Btn1, Modal, Badge } from "react-bootstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenAlt } from "@fortawesome/free-solid-svg-icons";
import moment from "moment";

// import child components
import { AddNewEntry } from "./AddNewEntry.js";

//API handling components
const API_URL = "https://5techg.a2hosted.com/globaltyres_api/";
const axios = require("axios");

class LedgerManager extends Component {
    constructor(props) {
        super(props);
        this.state = {
            partyId: this.props.match ? this.props.match.params.partyId : null,
            showAddModal: false,
            showUpdateModel: false,
            id: -1,
            billId: -1,
            particular: "",
            debit: 0,
            credit: 0,
            balance: 0,
            dateModified: "",
            totalBalance: 0,
            partyName: "",
            startDate: "2015-01-01T00:00",
            endDate: "2030-12-31T23:59",
        };
    }

    fetchPartyData() {
        // if party id is null
        if (!this.state.partyId) return null;

        axios
            .get(API_URL + `/party/${this.state.partyId}`)
            .then((res) => {
                console.log("party data: ", res.data);
                this.setState({ partyData: res.data });
            })
            .catch((err) => {
                console.log("party data fetch error: ", err);
            });
    }

    fetchLedgerData() {
        if (!this.state.partyId) {
            axios
                .get(API_URL + `ledger`)
                .then((res) => {
                    console.log("ledger data: ", res.data);
                    this.setState({ LedgerData: res.data });
                    this.initializeDataTable();
                })
                .catch((err) => {
                    console.log("ledger data fetch error: ", err);
                });
        } else {
            axios
                .get(
                    API_URL + `ledger?_where=(partyId,eq,${this.state.partyId})`
                )
                .then((res) => {
                    console.log("ledger data: ", res.data);
                    let bal = res.data.map((val) => [
                        val.total ? val.total : 0,
                        val.debit ? val.debit : 0,
                        val.credit ? val.credit : 0,
                    ]);
                    console.log("bal 0 : ", bal);
                    bal = bal.reduce((total, val) => {
                        //console.log("==>>", total, val);
                        return total + val[0] + val[1] - val[2];
                    }, 0);
                    console.log("bal 1 : ", bal);
                    this.setState({ LedgerData: res.data, totalBalance: bal });
                    this.initializeDataTable();
                })
                .catch((err) => {
                    console.log("ledger data fetch error: ", err);
                });
        }
    }

    handleUpdateSubmit(e) {
        axios
            .patch(API_URL + `ledger/${this.state.id}`, {
                partyId: this.state.partyId,
                particular: this.state.particular,
                debit: this.state.debit,
                credit: this.state.credit,
            })
            .then((res) => {
                this.props.toast.success("ledger details updated successfully");
                this.fetchLedgerData();
            })
            .catch((err) => {
                console.log(err);
            });
    }

    componentDidMount() {
        this.fetchPartyData();
        this.fetchLedgerData();
    }

    renderPartyData() {
        const party = this.state.partyData;
        if (!party) return null;

        return (
            <div className="mb-2">
                <h5 className="float-left mt-2">
                    {party[0]["id"]} | <b>{party[0]["name"]}</b>
                </h5>
                <Button
                    color="primary"
                    variant="outlined"
                    className="float-right pb-0"
                >
                    <h5>
                        Total balance:&nbsp; &nbsp;
                        <b>{this.state.totalBalance}</b>
                    </h5>
                </Button>
            </div>
        );
    }

    renderLedgerData = () => {
        if (this.state.LedgerData == null) {
            return null;
        }

        const ledger = this.state.LedgerData;
        let last_modified = null;
        let balance = 0;
        let sd = this.state.startDate;
        let sd1 = new Date(
            parseInt(sd.slice(0, 4)),
            parseInt(sd.slice(5, 7)),
            parseInt(sd.slice(8, 10)),
            parseInt(sd.slice(11, 13)),
            parseInt(sd.slice(14, 16))
        );
        let ed = this.state.endDate;
        let ed1 = new Date(
            parseInt(ed.slice(0, 4)),
            parseInt(ed.slice(5, 7)),
            parseInt(ed.slice(8, 10)),
            parseInt(ed.slice(11, 13)),
            parseInt(ed.slice(14, 16))
        );

        return ledger.map((record, record_index) => {
            let cld = moment(record["last_modified"]).format(
                "YYYY/MM/DD-HH:MM"
            );
            let cld1 = new Date(
                parseInt(cld.slice(0, 4)),
                parseInt(cld.slice(5, 7)),
                parseInt(cld.slice(8, 10)),
                parseInt(cld.slice(11, 13)),
                parseInt(cld.slice(14, 16))
            );

            if (cld1 < sd1 || cld1 > ed1) return "";
            balance =
                balance + record["total"] + record["debit"] - record["credit"];
            return (
                <tr>
                    <td align="center">{record["id"]}</td>
                    <td align="center">
                        {record["billId"] ? record["billId"] : "-"}
                    </td>
                    <td align="center">{record["particular"]}</td>
                    <td>{record["total"]}</td>
                    <td>{record["debit"]}</td>
                    <td>{record["credit"]}</td>
                    <td>{balance}</td>
                    <td>
                        {moment(record["last_modified"]).format(
                            "DD/MM/YYYY-HH:MM"
                        )}
                    </td>
                    <td align="center">
                        <button
                            className="btn shadow border"
                            style={{ backgroundColor: "transparent" }}
                            onClick={(e) => {
                                this.setState({
                                    id: record.id,
                                    particular: record.particular,
                                    debit: record.debit,
                                    credit: record.credit,
                                });
                                this.setState({ showUpdateModel: true });
                            }}
                        >
                            <FontAwesomeIcon
                                icon={faPenAlt}
                                style={{ color: "blue" }}
                            />
                        </button>
                        <Modal
                            show={this.state.showUpdateModel}
                            onHide={(e) =>
                                this.setState({ showUpdateModel: false })
                            }
                            size="md"
                            aria-labelledby="contained-modal-title-vcenter"
                            centered
                        >
                            <Modal.Header closeButton>
                                <Modal.Title id="contained-modal-title-vcenter">
                                    Update record
                                </Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <form noValidate autoComplete="off">
                                    <div className="mt-3">
                                        <Row>
                                            <Col xs={12}>
                                                <TextField
                                                    id="Particular"
                                                    label="Particular"
                                                    variant="outlined"
                                                    className="m-2"
                                                    defaultValue={
                                                        this.state.particular
                                                    }
                                                    onChange={(e) =>
                                                        this.setState({
                                                            particular:
                                                                e.target.value,
                                                        })
                                                    }
                                                />
                                            </Col>
                                            <Col>
                                                <TextField
                                                    id="debit"
                                                    label="Debit"
                                                    variant="outlined"
                                                    className="m-2"
                                                    defaultValue={
                                                        this.state.debit
                                                    }
                                                    onChange={(e) =>
                                                        this.setState({
                                                            debit: e.target
                                                                .value,
                                                        })
                                                    }
                                                />
                                            </Col>
                                            <Col>
                                                <TextField
                                                    id="credit"
                                                    label="Credit"
                                                    variant="outlined"
                                                    className="m-2"
                                                    defaultValue={
                                                        this.state.credit
                                                    }
                                                    onChange={(e) =>
                                                        this.setState({
                                                            credit: e.target
                                                                .value,
                                                        })
                                                    }
                                                />
                                            </Col>
                                        </Row>
                                    </div>

                                    <div className="mt-2 mr-1">
                                        <Btn1
                                            style={{ float: "right" }}
                                            onClick={(e) => {
                                                this.setState({
                                                    showUpdateModel: false,
                                                });
                                                this.handleUpdateSubmit(e);
                                            }}
                                        >
                                            Update
                                        </Btn1>
                                    </div>
                                </form>
                            </Modal.Body>
                        </Modal>
                    </td>
                </tr>
            );
        });
    };

    initializeDataTable() {
        $("#ledger_table").DataTable({
            destroy: true,
        });
    }

    render() {
        return (
            <div className="container-fluid border m-0 p-3">
                {this.state.partyId ? (
                    <>
                        {this.renderPartyData()}
                        <br />
                        <hr />
                        <div
                            class="btn-group mb-3"
                            role="group"
                            aria-label="Basic example"
                        >
                            <AddNewEntry
                                partyId={this.state.partyId}
                                refresh={this.fetchLedgerData}
                                totalBalance={this.state.totalBalance}
                                toast={this.props.toast}
                                state={this.state}
                                setState={(stateVal) =>
                                    this.setState({ ...stateVal })
                                }
                            />
                        </div>
                        <div className="dateFilter">
                            {console.log("state props : ", this.state)}
                            <div>
                                <TextField
                                    id="date"
                                    label="Start Date"
                                    type="datetime-local"
                                    value={this.state.startDate}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    onChange={(e) =>
                                        this.setState({
                                            ...this.state,
                                            startDate: e.target.value,
                                        })
                                    }
                                />
                            </div>
                            <div>
                                <TextField
                                    id="date"
                                    label="End Date"
                                    type="datetime-local"
                                    value={this.state.endDate}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    onChange={(e) =>
                                        this.setState({
                                            ...this.state,
                                            endDate: e.target.value,
                                        })
                                    }
                                />
                            </div>
                        </div>
                    </>
                ) : (
                    ""
                )}
                <Row className="ml-0 mr-0">
                    <Col md="12" className="p-0 m-0 measure1">
                        <div>
                            <table
                                id="ledger_table"
                                class="display"
                                style={{ width: "100%" }}
                            >
                                <thead>
                                    <tr>
                                        <th align="center">ID</th>
                                        <th align="center">Invoice No.</th>
                                        <th align="center">Particular</th>
                                        <th>Total</th>
                                        <th>Debit</th>
                                        <th align="center">Credit</th>
                                        <th>Balance</th>
                                        <th>last modified</th>
                                        <th align="center">Options</th>
                                    </tr>
                                </thead>
                                <TableBody>{this.renderLedgerData()}</TableBody>
                            </table>
                        </div>
                    </Col>
                </Row>
            </div>
        );
    }
}

export default LedgerManager;

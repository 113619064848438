import React, { Component } from "react";
import { Link } from "react-router-dom";

import "./style.css";
import "bootstrap/dist/css/bootstrap.min.css";

// material UI imports
import {
    AppBar,
    Tab,
    Button,
    TextField,
    InputLabel,
    MenuItem,
    Select,
    FormControl,
} from "@material-ui/core";
import { TabContext, TabList, TabPanel } from "@material-ui/lab";
import { Row, Col, Button as Btn1, Modal, Badge } from "react-bootstrap";
import moment from "moment";

// font awasome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faPenAlt,
    faPlusCircle,
    faBook,
    faTrash,
    faTrashAlt,
    faSyncAlt,
    faEye,
} from "@fortawesome/free-solid-svg-icons";

// Toastify imports
import { ToastContainer, toast } from "react-toastify";
// import "../ledger_manager/exportManager/node_modules/react-toastify/dist/ReactToastify.css";

// datatable setup
import jsZip from "jszip";
window.JSZip = jsZip;
var $ = require("jquery");
$.DataTable = require("datatables.net");
require("datatables.net-bs4");
require("datatables.net-autofill-bs4");
require("datatables.net-buttons-bs4");
require("datatables.net-buttons/js/buttons.colVis");
require("datatables.net-buttons/js/buttons.flash");
require("datatables.net-buttons/js/buttons.html5");
require("datatables.net-buttons/js/buttons.print");
require("datatables.net-responsive-bs4");
require("datatables.net-scroller-bs4");
require("datatables.net-select-bs4");
require("pdfmake");

// constants
const axios = require("axios");
const API_URL = "https://5techg.a2hosted.com/globaltyres_api/party/";

class WorkerManager extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showAddModal: false,
            showUpdateModel: false,
            value: "1",
            id: "",
            name: "",
            mobile: "",
            address: "",
            gstin: "",
            type: 1,
            status: 0,
            lastModified: "",
            partiesData: null,
        };
    }

    fetchPartiesData() {
        axios
            .get(API_URL)
            .then((res) => {
                console.log("party data: ", res.data);
                this.setState({ partiesData: res.data });
            })
            .catch((err) => {
                console.log("party data error: ", err);
            });
    }

    handleUpdateSubmit(e) {
        e.preventDefault();
        axios
            .patch(API_URL + this.state.id, {
                name: this.state.name,
                mobile: this.state.mobile,
                address: this.state.address,
                gstin: this.state.gstin,
                type: this.state.type,
                status: 0,
            })
            .then((res) => {
                this.props.refresh();
                toast.success("Party details updated successfully");
            })
            .catch((err) => {
                console.log("error while updating party data", err);
            });
    }

    handleAddSubmit(e) {
        e.preventDefault();
        let data = {
            name: this.state.name,
            mobile: this.state.mobile,
            address: this.state.address,
            gstin: this.state.gstin,
            type: this.state.type,
            status: 0,
        };
        console.log(data);
        axios
            .post(API_URL, data)
            .then((res) => {
                console.log("-----", res);
                this.props.refresh();
                toast.success("party details added successfully");
            })
            .catch((err) => {
                console.log(err);
            });
    }

    deleteRecord(id) {
        axios
            .delete(API_URL + `${id}`)
            .then((res) => {
                console.log("deleted status data: ", res.data);
                console.log("Party deleted successfully");
                this.props.refresh();
                toast.error("Party deleted successfully");
            })
            .catch((err) => {
                console.log("record delete error: ", err);
            });
    }

    handleTabs = (event, newValue) => {
        this.setState({ value: newValue });
    };

    componentDidMount() {
        this.fetchPartiesData();
    }

    componentDidUpdate() {
        $("#party_table").DataTable({
            destroy: true,
            keys: true,
            buttons: [
                "copy",
                "csv",
                "excel",
                {
                    extend: "pdf",
                    messageTop: "<h4 style='text-align:center'>Users List</h4>",
                    download: "open",
                },
                {
                    extend: "print",
                    messageTop: "<h4 style='text-align:center'>Users List</h4>",
                    download: "open",
                },
            ],
        });
    }

    renderPartiesData = () => {
        const parties = this.state.partiesData;

        if (parties == null) {
            return null;
        }

        return parties.map((party) => {
            return (
                <tr>
                    <td align="center">{party["id"]}</td>
                    <td align="center">{party["name"]}</td>
                    <td align="center">
                        <a href={"tel:" + party["mobile"]}>
                            <Button
                                className="mx-1"
                                color="primary"
                                variant="secondary"
                            >
                                {party["mobile"]}
                            </Button>
                        </a>
                    </td>
                    <td align="center">{party["address"]}</td>
                    <td align="center">
                        {party["gstin"] ? party["gstin"] : "-"}
                    </td>
                    <td align="center">
                        {party["type"] == 1 ? "Wholesaler" : "Retailer"}
                    </td>
                    <td align="center">
                        {moment(party["lastModified"]).format(
                            "DD/MM/YYYY-HH:MM"
                        )}
                    </td>
                    <td align="center">
                        <button
                            className="btn shadow border mr-1"
                            style={{ backgroundColor: "transparent" }}
                            onClick={(e) => {
                                this.setState({
                                    id: party["id"],
                                    name: party["name"],
                                    mobile: party["mobile"],
                                    address: party["address"],
                                    type: party["type"],
                                    gstin: party["gstin"],
                                    showUpdateModal: true,
                                });
                            }}
                        >
                            <FontAwesomeIcon
                                icon={faPenAlt}
                                style={{ color: "blue" }}
                            />
                        </button>
                        {false ? (
                            <Link to={`/ledgerManager/${party["id"]}`}>
                                <button
                                    className="btn shadow border mr-1"
                                    style={{ backgroundColor: "transparent" }}
                                    onClick={(e) => {}}
                                >
                                    <FontAwesomeIcon
                                        icon={faBook}
                                        style={{ color: "blue" }}
                                    />
                                </button>
                            </Link>
                        ) : (
                            ""
                        )}
                        <button
                            className="btn shadow border"
                            style={{ backgroundColor: "transparent" }}
                            onClick={(e) => {
                                if (window.confirm("Delete the item?")) {
                                    this.deleteRecord(party["id"]);
                                }
                            }}
                        >
                            <FontAwesomeIcon icon={faTrashAlt} color="red" />
                        </button>
                    </td>
                </tr>
            );
        });
    };

    renderUpdatePartyModal() {
        return (
            <Modal
                show={this.state.showUpdateModal}
                onHide={(e) => this.setState({ showUpdateModal: false })}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Update Party
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form noValidate autoComplete="off">
                        <div className="mt-3">
                            <Row>
                                <Col size="12">
                                    <TextField
                                        id="partyName"
                                        label="Party name"
                                        variant="outlined"
                                        className="m-2"
                                        defaultValue={this.state.name}
                                        onChange={(e) => {
                                            this.setState({
                                                name: e.target.value,
                                            });
                                        }}
                                    />
                                </Col>
                                <Col>
                                    <TextField
                                        id="mobile"
                                        label="Mobile"
                                        variant="outlined"
                                        className="m-2"
                                        defaultValue={this.state.mobile}
                                        onChange={(e) =>
                                            this.setState({
                                                mobile: e.target.value,
                                            })
                                        }
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <TextField
                                        id="address"
                                        s
                                        label="Address"
                                        variant="outlined"
                                        className="m-2"
                                        defaultValue={this.state.address}
                                        onChange={(e) =>
                                            this.setState({
                                                address: e.target.value,
                                            })
                                        }
                                    />
                                </Col>
                                <Col>
                                    <TextField
                                        id="gstin"
                                        label="Gstin"
                                        variant="outlined"
                                        className="m-2"
                                        defaultValue={this.state.gstin}
                                        onChange={(e) =>
                                            this.setState({
                                                gstin: e.target.value,
                                            })
                                        }
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <FormControl
                                        variant="filled"
                                        style={{
                                            minWidth: "120px",
                                        }}
                                        className="mt-2 ml-2"
                                    >
                                        <InputLabel id="demo-simple-select-outlined-label">
                                            Type
                                        </InputLabel>
                                        <Select
                                            labelId="demo-simple-select-outlined-label"
                                            id="demo-simple-select-outlined"
                                            label="type"
                                            defaultValue={this.state.type}
                                            onChange={(e) =>
                                                this.setState({
                                                    type: e.target.value,
                                                })
                                            }
                                        >
                                            <MenuItem value={1}>
                                                WholeSaler
                                            </MenuItem>
                                            <MenuItem value={2}>
                                                Retailer
                                            </MenuItem>
                                            <MenuItem value={3}>
                                                Customer
                                            </MenuItem>
                                        </Select>
                                    </FormControl>
                                </Col>
                            </Row>
                        </div>
                        <hr />
                        <div className="mt-2 mr-1">
                            <Btn1
                                style={{ float: "right" }}
                                onClick={(e) => {
                                    this.setState({
                                        showUpdateModal: false,
                                    });
                                    this.handleUpdateSubmit(e);
                                }}
                            >
                                Update
                            </Btn1>
                        </div>
                    </form>
                </Modal.Body>
            </Modal>
        );
    }

    render() {
        return (
            <div className="container-fluid border m-0 p-0 main">
                <div className="container-fluid border m-0 p-1">
                    <div
                        class="btn-group"
                        role="group"
                        aria-label="Basic example"
                    >
                        <Button
                            color="primary"
                            variant="contained"
                            className="mt-1 mr-1 mb-3 ml-5"
                            onClick={(e) => {
                                this.setState({ showAddModal: true, type: 1 });
                            }}
                        >
                            <FontAwesomeIcon icon={faPlusCircle} size="2x" />
                        </Button>
                        <Button
                            color="primary"
                            variant="contained"
                            className="mt-1 mr-1 mb-3 ml-5"
                            onClick={this.props.refresh}
                        >
                            <FontAwesomeIcon icon={faSyncAlt} size="2x" />
                        </Button>
                    </div>

                    {this.renderUpdatePartyModal()}
                    <Modal
                        show={this.state.showAddModal}
                        onHide={(e) => this.setState({ showAddModal: false })}
                        size="md"
                        aria-labelledby="contained-modal-title-vcenter"
                        centered
                    >
                        <Modal.Header closeButton>
                            <Modal.Title id="contained-modal-title-vcenter">
                                Add New Party
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <form noValidate autoComplete="off">
                                <div className="mt-3">
                                    <Row>
                                        <Col size="12">
                                            <TextField
                                                id="partyName"
                                                label="Party name"
                                                variant="outlined"
                                                className="m-2"
                                                defaultValue=""
                                                onChange={(e) => {
                                                    this.setState({
                                                        name: e.target.value,
                                                    });
                                                }}
                                            />
                                        </Col>
                                        <Col>
                                            <TextField
                                                id="mobile"
                                                label="Mobile"
                                                variant="outlined"
                                                className="m-2"
                                                defaultValue=""
                                                onChange={(e) =>
                                                    this.setState({
                                                        mobile: e.target.value,
                                                    })
                                                }
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <TextField
                                                id="address"
                                                s
                                                label="Address"
                                                variant="outlined"
                                                className="m-2"
                                                defaultValue=""
                                                onChange={(e) =>
                                                    this.setState({
                                                        address: e.target.value,
                                                    })
                                                }
                                            />
                                        </Col>
                                        <Col>
                                            <TextField
                                                id="gstin"
                                                label="Gstin"
                                                variant="outlined"
                                                className="m-2"
                                                defaultValue=""
                                                onChange={(e) =>
                                                    this.setState({
                                                        gstin: e.target.value,
                                                    })
                                                }
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <FormControl
                                                variant="filled"
                                                style={{
                                                    minWidth: "120px",
                                                }}
                                                className="mt-2 ml-2"
                                            >
                                                <InputLabel id="demo-simple-select-outlined-label">
                                                    Type
                                                </InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-outlined-label"
                                                    id="demo-simple-select-outlined"
                                                    label="type"
                                                    defaultValue={
                                                        this.state.type
                                                    }
                                                    onChange={(e) =>
                                                        this.setState({
                                                            type: e.target
                                                                .value,
                                                        })
                                                    }
                                                >
                                                    <MenuItem value={1}>
                                                        Wholesaler
                                                    </MenuItem>
                                                    <MenuItem value={2}>
                                                        Retailer
                                                    </MenuItem>
                                                    <MenuItem value={3}>
                                                        Customer
                                                    </MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Col>
                                    </Row>
                                </div>
                                <hr />
                                <div className="mt-2 mr-1">
                                    <Btn1
                                        style={{ float: "right" }}
                                        onClick={(e) => {
                                            this.setState({
                                                showAddModal: false,
                                            });
                                            this.handleAddSubmit(e);
                                        }}
                                    >
                                        Add Party
                                    </Btn1>
                                </div>
                            </form>
                        </Modal.Body>
                    </Modal>
                    <Row className="ml-0 mr-0">
                        <Col md="12" className="p-0 m-0 measure1">
                            <div>
                                <table
                                    id="party_table"
                                    className="display"
                                    style={{ width: "100%" }}
                                >
                                    <thead>
                                        <tr align="center">
                                            <th>Id</th>
                                            <th>Name</th>
                                            <th>Mobile No</th>
                                            <th>Address</th>
                                            <th>gstin</th>
                                            <th>Type</th>
                                            <th>date modified</th>
                                            <th>Options</th>
                                        </tr>
                                    </thead>
                                    <tbody>{this.renderPartiesData()}</tbody>
                                </table>
                            </div>
                        </Col>
                    </Row>
                </div>

                <ToastContainer
                    position={toast.POSITION.TOP_RIGHT}
                    autoClose={5000}
                />
            </div>
        );
    }
}

export default WorkerManager;

import React, { Component } from "react";
import { Link } from "react-router-dom";

import "./style.css";
import "bootstrap/dist/css/bootstrap.min.css";

import ReactToPrint from "react-to-print";
// material UI imports
import { Button } from "@material-ui/core";
import { Row, Col, Button as Btn1, Modal, Badge } from "react-bootstrap";
import moment from "moment";
import {
    TextEdit,
    SelectEdit,
    DateEdit,
    TimeEdit,
} from "../../components/showEdit/ShowEdit";
import { inWords, todaysDate, currentTime } from "../../extra/helpers";

// font awasome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faPenAlt,
    faBook,
    faTrash,
    faTrashAlt,
    faSyncAlt,
    faPlusCircle,
} from "@fortawesome/free-solid-svg-icons";
import { PrintBillCreate, PrintBillShow, PrintBillUpdate } from "./PrintBill";

// Toastify imports
import { ToastContainer, toast } from "react-toastify";
// import "../ledger_manager/exportManager/node_modules/react-toastify/dist/ReactToastify.css"
// datatable setup
import jsZip from "jszip";
window.JSZip = jsZip;
var $ = require("jquery");
$.DataTable = require("datatables.net");
require("datatables.net-bs4");
require("datatables.net-autofill-bs4");
require("datatables.net-buttons-bs4");
require("datatables.net-buttons/js/buttons.colVis");
require("datatables.net-buttons/js/buttons.flash");
require("datatables.net-buttons/js/buttons.html5");
require("datatables.net-buttons/js/buttons.print");
require("datatables.net-responsive-bs4");
require("datatables.net-scroller-bs4");
require("datatables.net-select-bs4");
require("pdfmake");

// constants
const axios = require("axios");
const API_URL = "https://5techg.a2hosted.com/globaltyres_api/bill";

export default class Company extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showAddModal: false,
            showBillModal: false,
            showUpdateModel: false,
            id: -1,
            billId: -1,
            total: 0,
            discount: 0,
            status: 0,
            last_modified: "",
            dateTime: "",
            billData: null,
            products: [],
            allParties: [],
            billSettings: null,
            billEdits: {
                party: [],
                vehicleNo: ["abc", true],
                vehicleName: ["MARUTI SWIFT DZRIE", true],
                time: [currentTime(), true],
                date: [todaysDate(), true],
                client_id: -1,
                client_name: [0, true],
                client_mobile: ["9665657777", true],
                billTable: [], //[[0, 2, true, true]],
                subTotal: 0,
                bank: ["BANK OF INDIA", "1609301100000075", "BKID001609"],
                discount: [0, true],
                total: [0, true],
                cgst: 0,
                sgst: 0,
            },
        };
    }

    adjustAmmounts(changeFor = "subTotal") {
        console.log("adjust Ammount : ", changeFor);
        let subTotal = this.state.billEdits.subTotal;
        let total = this.state.billEdits.total[0];
        let discount = parseFloat(this.state.billEdits.discount[0]);
        let cgst = 0;
        let sgst = 0;
        let extra = [0, 0, 0];
        if (changeFor === "subTotal") {
            subTotal = this.state.billEdits.billTable
                .map(
                    (val) =>
                        parseFloat(this.state.products[val[0]].rate) *
                        parseFloat(val[1])
                )
                .reduce((sum, element) => sum + element, 0);
            extra = this.state.billEdits.billTable
                .map((v) => {
                    let st = this.state.products[v[0]].rate * v[1];
                    return [
                        (st * this.state.products[v[0]].cgst) / 100,
                        (st * this.state.products[v[0]].sgst) / 100,
                        st,
                    ];
                })
                .reduce(
                    (e, l) => [e[0] + l[0], e[1] + l[1], e[2] + l[2]],
                    [0, 0, 0]
                );
            total = Math.floor(extra[2] + extra[0] + extra[1] + 0.5);
            discount = total - (extra[2] + extra[0] + extra[1]);
        } else if (changeFor === "discount") {
            total = subTotal - discount;
        } else if (changeFor === "total") {
            discount = subTotal - total;
        }
        console.log("0__==__>>", this.state);
        this.setState({
            ...this.state,
            billEdits: {
                ...this.state.billEdits,
                subTotal: extra[2],
                discount: [discount, this.state.billEdits.discount[1]],
                total: [total, this.state.billEdits.total[1]],
                sgst: extra[1],
                cgst: extra[0],
            },
        });
        console.log("1__==__>>", this.state);
    }

    calculate_cgst() {
        if (!this.state.products[0]) return 0;
        return this.state.billEdits.billTable
            .map((val) =>
                parseFloat(
                    (this.state.products[val[0]].cgst *
                        this.state.products[val[0]].rate *
                        val[1]) /
                        100
                )
            )
            .reduce((sum, element) => sum + element, 0);
    }

    calculate_sgst() {
        if (!this.state.products[0]) return 0;
        return this.state.billEdits.billTable
            .map((val) =>
                parseFloat(
                    (this.state.products[val[0]].sgst *
                        this.state.products[val[0]].rate *
                        val[1]) /
                        100
                )
            )
            .reduce((sum, element) => sum + element, 0);
    }

    fetchPartiesData() {
        let run = async () => {
            let partyData = await axios
                .get(
                    "https://5techg.a2hosted.com/globaltyres_api/xjoin?_join=b.bill,_j,p.party&_on1=(b.partyId,eq,p.id)&_fields=b.id,b.companyId,b.partyId,b.dateTime,b.vehicleNo,b.vehicleName,b.discount,b.total,b.status,b.last_modified,p.name"
                )
                .then((res) => {
                    //console.log("bill data 0: ", res.data);
                    return res.data;
                })
                .catch((err) => {
                    console.log("bill data error: ", err);
                    return null;
                });
            let productData = await axios
                .get(
                    `https://5techg.a2hosted.com/globaltyres_api/products?_where=(companyId,eq,${this.props.company})`
                )
                .then((res) => {
                    //console.log("bill data 1: ", res.data);
                    return res.data;
                })
                .catch((err) => {
                    console.log("err : ", err);
                    return null;
                });

            this.setState({
                partiesData: partyData ? partyData : [],
                products: productData ? productData : [],
            });
        };

        run();
    }

    old() {
        /* axios
      .get(
        "https://5techg.a2hosted.com/globaltyres_api/xjoin?_join=b.bill,_j,p.party&_on1=(b.partyId,eq,p.id)&_fields=b.id,b.companyId,b.partyId,b.dateTime,b.vehicleNo,b.vehicleName,b.discount,b.total,b.status,b.last_modified,p.name"
      )
      .then((res) => {
        console.log("bill data 0: ", res.data);
        var data = res.data;
        axios
          .get(
            `https://5techg.a2hosted.com/globaltyres_api/products?_where=(companyId,eq,${this.props.company})`
          )
          .then((res) => {
            console.log("bill data 1: ", res.data);
            this.setState({
              partiesData: data,
              products: res.data,
            });
          })
          .catch((err) => console.log("err : ", err));
        console.log("fetchParties ==>> ", this.state);
      })
      .catch((err) => {
        console.log("bill data error: ", err);
      });
    */
    }

    fetchBillSetting(extraData) {
        console.log("in fetch settings : ", extraData);
        let run = async () => {
            let settings = await axios
                .get("https://5techg.a2hosted.com/globaltyres_api/setting")
                .then((res) => {
                    console.log("settings : ", res.data);
                    return res.data;
                })
                .catch((err) => {
                    console.log("err : ", err);
                    return null;
                });

            let newInvoiceNo = await axios
                .get(
                    "https://5techg.a2hosted.com/globaltyres_api/bill/aggregate?_fields=id"
                )
                .then((res) => {
                    console.log(
                        "Last Invoice Number : ",
                        res.data,
                        res.data[0].max_of_id
                    );
                    return res.data[0].max_of_id + 1;
                })
                .catch((err) => {
                    console.log("err : ", err);
                    return null;
                });

            let allParties = await axios
                .get("https://5techg.a2hosted.com/globaltyres_api/party")
                .then((res) => {
                    console.log("settings : ", res.data);
                    return res.data;
                })
                .catch((err) => {
                    console.log("err : ", err);
                    return null;
                });

            console.log("fetch bill related data : ", settings, allParties, {
                ...this.state,
                ...extraData,
                newInvoiceNo: newInvoiceNo,
                billSettings: settings ? settings : [],
                allParties: allParties ? allParties : [],
                billEdits: {
                    ...this.state.billEdits,
                    client_id: allParties[0].id,
                    billTable: [],
                    subTotal: 0,
                    total: 0,
                    csgt: 0,
                    sgst: 0,
                    discount: 0,
                },
            });
            this.setState({
                ...this.state,
                ...extraData,
                newInvoiceNo: newInvoiceNo,
                billSettings: settings ? settings : [],
                allParties: allParties ? allParties : [],
                billEdits: {
                    ...this.state.billEdits,
                    client_id: allParties[0].id,
                    billTable: [],
                    subTotal: 0,
                    total: 0,
                    csgt: 0,
                    sgst: 0,
                    discount: 0,
                },
            });
        };

        run();
    }

    fetchBillShowSetting(extraData, partyId) {
        console.log("in fetch update settings : ", extraData);
        let run = async () => {
            let settings = await axios
                .get("https://5techg.a2hosted.com/globaltyres_api/setting")
                .then((res) => {
                    console.log("settings : ", res.data);
                    return res.data;
                })
                .catch((err) => {
                    console.log("err : ", err);
                    return null;
                });

            let party = await axios
                .get(
                    `https://5techg.a2hosted.com/globaltyres_api/party/${partyId}`
                )
                .then((res) => {
                    console.log("settings : ", res.data);
                    return res.data;
                })
                .catch((err) => {
                    console.log("err : ", err);
                    return null;
                });

            let products = await axios
                .get(
                    `https://5techg.a2hosted.com/globaltyres_api/billProductList?_where=(billId,eq,${extraData.id})`
                )
                .then((res) => {
                    console.log("settings : ", res.data);
                    return res.data;
                })
                .catch((err) => {
                    console.log("err : ", err);
                    return null;
                });

            let extra = products.map((v) => {
                //console.log("products : ", v);
                return [
                    (v.cgst * v.amount) / 100,
                    (v.sgst * v.amount) / 100,
                    v.amount,
                ];
            });
            //console.log(extra);
            extra = extra.reduce(
                (e, l) => [e[0] + l[0], e[1] + l[1], e[2] + l[2]],
                [0, 0, 0]
            );

            console.log(
                "fetch bill related data : ",
                settings,
                party,
                extra,
                products
            );
            this.setState({
                ...this.state,
                ...extraData,
                billSettings: settings ? settings : [],
                billEdits: {
                    ...this.state.billEdits,
                    party: party,
                    billTable: products,
                    cgst: extra[0],
                    sgst: extra[1],
                    subTotal: extra[2],
                    total: extra[0] + extra[1] + extra[2] + extraData.discount,
                },
            });
        };

        run();
    }

    fetchBillUpdateSetting(extraData, partyId) {
        console.log("in fetch update settings : ", extraData);
        let run = async () => {
            let settings = await axios
                .get("https://5techg.a2hosted.com/globaltyres_api/setting")
                .then((res) => {
                    console.log("settings : ", res.data);
                    return res.data;
                })
                .catch((err) => {
                    console.log("err : ", err);
                    return null;
                });

            if (false) {
                let party = await axios
                    .get(
                        `https://5techg.a2hosted.com/globaltyres_api/party/${partyId}`
                    )
                    .then((res) => {
                        console.log("settings : ", res.data);
                        return res.data;
                    })
                    .catch((err) => {
                        console.log("err : ", err);
                        return null;
                    });
            }

            let allParties = await axios
                .get("https://5techg.a2hosted.com/globaltyres_api/party")
                .then((res) => {
                    console.log("settings : ", res.data);
                    return res.data;
                })
                .catch((err) => {
                    console.log("err : ", err);
                    return null;
                });

            let products = await axios
                .get(
                    `https://5techg.a2hosted.com/globaltyres_api/billProductList?_where=(billId,eq,${extraData.id})`
                )
                .then((res) => {
                    console.log("settings : ", res.data);
                    return res.data;
                })
                .catch((err) => {
                    console.log("err : ", err);
                    return null;
                });

            let extra = products
                .map((v) => [v.cgst, v.sgst, v.amount])
                .reduce(
                    (e, l) => [e[0] + l[0], e[1] + l[1], e[2] + l[2]],
                    [0, 0, 0]
                );

            console.log(
                "fetch bill related data : ",
                settings,
                products,
                this.state.allParties,
                extra,
                "<<--"
            );

            let main_party = -1;
            for (let i = 0; i < allParties.length; i++) {
                main_party += 1;
                console.log(
                    "check party Id : ",
                    extraData.partyId,
                    allParties[main_party].id
                );
                if (extraData.partyId === allParties[i].id) break;
            }

            this.setState({
                ...this.state,
                ...extraData,
                billSettings: settings ? settings : [],
                allParties: allParties,
                billEdits: {
                    ...this.state.billEdits,
                    vehicleNo: [extraData.vehicleNo, true],
                    vehicleName: [extraData.vehicleName, true],
                    date: [extraData.dateTime.split("T")[0], true],
                    time: [extraData.dateTime.split("T")[1], true],
                    //party: party,
                    client_name: [main_party, true],
                    billTable: products,
                    cgst: extra[0],
                    sgst: extra[1],
                    subTotal: extra[2],
                    discount: [extraData.discount, true],
                    total: [
                        extra[0] + extra[1] + extra[2] + extraData.discount,
                        true,
                    ],
                },
            });
            console.log("update seting fetch ended!");
        };

        run();
        console.log("update settting fetch started!");
    }

    handleUpdateSubmit(e) {
        e.preventDefault();
        let run = async () => {
            let partyAdded = await axios
                .patch(
                    `https://5techg.a2hosted.com/globaltyres_api/bill/${this.state.id}`,
                    {
                        partyId:
                            this.state.allParties[
                                parseInt(this.state.billEdits.client_name)
                            ].id,
                        dateTime:
                            this.state.billEdits.date[0] +
                            "T" +
                            this.state.billEdits.time[0] +
                            ":00.000Z",
                        vehicleNo: this.state.billEdits.vehicleNo[0],
                        vehicleName: this.state.billEdits.vehicleName[0],
                        discount: this.state.billEdits.discount[0].toFixed(2),
                        total: this.state.billEdits.total[0].toFixed(2),
                    }
                )
                .then((res) => {
                    console.log("inserted", res.data.insertId);
                    return res;
                })
                .catch((err) => {
                    console.log(err);
                    return null;
                });

            console.log("bill updated successfully : ", partyAdded);
            this.props.refresh();
            toast.success("bill updated successfully!");
        };

        console.log("this is update submit new ", {
            partyId: this.state.billEdits.client_id,
            dateTime:
                this.state.billEdits.date[0] +
                "T" +
                this.state.billEdits.time[0] +
                ":00.000Z",
            vehicleNo: this.state.billEdits.vehicleNo[0],
            vehicleName: this.state.billEdits.vehicleName[0],
            discount: this.state.billEdits.discount[0].toFixed(2),
            total: this.state.billEdits.total[0].toFixed(2),
        });

        run();
    }

    handleAddSubmit(e) {
        e.preventDefault();
        let run = async () => {
            let partyAdded = await axios
                .post(`https://5techg.a2hosted.com/globaltyres_api/bill`, {
                    companyId: this.props.company,
                    partyId:
                        this.state.allParties[
                            parseInt(this.state.billEdits.client_name)
                        ].id,
                    dateTime:
                        this.state.billEdits.date[0] +
                        "T" +
                        this.state.billEdits.time[0] +
                        ":00.000Z",
                    vehicleNo: this.state.billEdits.vehicleNo[0],
                    vehicleName: this.state.billEdits.vehicleName[0],
                    discount: this.state.billEdits.discount[0].toFixed(2),
                    total: this.state.billEdits.total[0].toFixed(2),
                })
                .then((res) => {
                    console.log("inserted", res.data.insertId);
                    this.state.billEdits.billTable.map((val) => {
                        let productInfo = [
                            {
                                companyId: this.props.company,
                                billId: res.data.insertId,
                                productName:
                                    this.state.products[val[0]].productName,
                                hsn: this.state.products[val[0]].hsn,
                                quantity: val[1],
                                rate: this.state.products[val[0]].rate,
                                cgst: this.state.products[val[0]].cgst,
                                sgst: this.state.products[val[0]].sgst,
                                amount: this.state.billEdits.total[0],
                            },
                        ][0];
                        axios
                            .post(
                                `https://5techg.a2hosted.com/globaltyres_api/billProductList`,
                                productInfo
                            )
                            .then((res) => {
                                console.log(
                                    "bulk product list  ==>>  res",
                                    res
                                );
                            })
                            .catch((err) => {
                                console.log(err);
                            });

                        axios
                            .patch(
                                `https://5techg.a2hosted.com/globaltyres_api/products/${
                                    this.state.products[val[0]].id
                                }`,
                                {
                                    stockCount:
                                        parseInt(
                                            this.state.products[val[0]]
                                                .stockCount
                                        ) - parseInt(val[1]),
                                }
                            )
                            .then((res) => {
                                console.log(
                                    "bulk product list  ==>>  res",
                                    res
                                );
                            })
                            .catch((err) => {
                                console.log(err);
                            });
                    });
                    return res;
                })
                .catch((err) => {
                    console.log(err);
                    return null;
                });

            let ledgerEntry = await axios
                .post("https://5techg.a2hosted.com/globaltyres_api/ledger", {
                    partyId:
                        this.state.allParties[
                            parseInt(this.state.billEdits.client_name)
                        ].id,
                    particular: `Invoice No. : ${parseInt(
                        this.state.billEdits.client_name
                    )}`,
                    total: this.state.billEdits.total[0].toFixed(2),
                    debit: 0,
                    credit: 0,
                    billId: parseInt(this.state.newInvoiceNo),
                })
                .then((res) => res.data)
                .catch((err) => console.log(err));

            this.props.refresh();
            toast.success("bill saved successfully!");
        };
        run();
    }

    deleteRecord(id) {
        axios
            .delete(`https://5techg.a2hosted.com/globaltyres_api/bill/${id}`)
            .then((res) => {
                console.log("deleted status data: ", res.data);
                this.props.refresh();
                toast.error("bill deleted successfully");
            })
            .catch((err) => {
                console.log("record delete error: ", err);
            });
    }

    componentDidMount() {
        this.fetchPartiesData();
    }

    componentDidUpdate() {
        console.log("updated ==>> ", this.state);
        $("#bill_table").DataTable({
            destroy: true,
            keys: true,
            buttons: [
                "copy",
                "csv",
                "excel",
                {
                    extend: "pdf",
                    messageTop: "<h4 style='text-align:center'>Users List</h4>",
                    download: "open",
                },
                {
                    extend: "print",
                    messageTop: "<h4 style='text-align:center'>Users List</h4>",
                    download: "open",
                },
            ],
        });
    }

    renderPartiesData = () => {
        const bills = this.state.partiesData;

        if (bills == null) {
            return null;
        }

        return bills.map((bill, index) => {
            if (bill["b_companyId"] !== parseInt(this.props.company)) return "";
            return (
                <tr key={"company-row-" + index.toString()}>
                    <td align="center">{bill["b_id"]}</td>
                    <td align="center">{bill["p_name"]}</td>
                    <td align="center">{bill["b_vehicleNo"]}</td>
                    <td align="center">{bill["b_vehicleName"]}</td>
                    <td align="center">
                        {bill["b_total"] == null ? 0 : bill["b_total"]}
                    </td>
                    <td align="center">
                        {bill["b_advance"] == null ? 0 : bill["b_advance"]}
                    </td>
                    <td align="center">
                        {moment(bill["b_last_modified"]).format(
                            "DD/MM/YYYY-HH:MM"
                        )}
                    </td>
                    <td align="center">
                        <button
                            className="btn shadow border mr-1"
                            style={{ backgroundColor: "transparent" }}
                            onClick={(e) => {
                                this.fetchBillUpdateSetting(
                                    {
                                        id: bill["b_id"],
                                        companyId: bill["b_companyId"],
                                        partyId: bill["b_partyId"],
                                        partyName: bill["p_name"],
                                        vehicleNo: bill["b_vehicleNo"],
                                        vehicleName: bill["b_vehicleName"],
                                        total: bill["b_total"],
                                        discount: bill["b_discount"],
                                        status: bill["b_status"],
                                        dateTime: bill["b_dateTime"],
                                        last_modified: bill["b_last_modified"],
                                        showUpdateModal: true,
                                    },
                                    bill["b_partyId"]
                                );
                            }}
                        >
                            <FontAwesomeIcon
                                icon={faPenAlt}
                                style={{ color: "blue" }}
                            />
                        </button>
                        <button
                            className="btn shadow border mr-1"
                            style={{ backgroundColor: "transparent" }}
                            onClick={(e) => {
                                this.fetchBillShowSetting(
                                    {
                                        id: bill["b_id"],
                                        companyId: bill["b_companyId"],
                                        partyId: bill["b_partyId"],
                                        partyName: bill["p_name"],
                                        vehicleNo: bill["b_vehicleNo"],
                                        vehicleName: bill["b_vehicleName"],
                                        total: bill["b_total"],
                                        discount: bill["b_discount"],
                                        status: bill["b_status"],
                                        dateTime: bill["b_dateTime"],
                                        last_modified: bill["b_last_modified"],
                                        showBillModal: true,
                                    },
                                    bill["b_partyId"]
                                );
                            }}
                        >
                            <FontAwesomeIcon
                                icon={faBook}
                                style={{ color: "blue" }}
                            />
                        </button>
                        <button
                            className="btn shadow border"
                            style={{ backgroundColor: "transparent" }}
                            onClick={(e) => {
                                if (
                                    window.confirm(
                                        `Delete the item (${bill["b_id"]})?`
                                    )
                                ) {
                                    this.deleteRecord(bill["b_id"]);
                                }
                            }}
                        >
                            <FontAwesomeIcon
                                icon={faTrashAlt}
                                style={{ color: "red" }}
                            />
                        </button>
                    </td>
                </tr>
            );
        });
    };

    renderUpdatebillModal1() {
        return (
            <Modal
                show={this.state.showUpdateModal}
                onHide={(e) => this.setState({ showUpdateModal: false })}
                size="xl"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                {console.log("current state : ", this.state)}
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Update bill
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="bill mx-5 my-3">
                        <div className="bill-title tac gry">
                            <div>GLOBAL TRADERS</div>
                            <div style={{ fontSize: "16px", fontWeight: 400 }}>
                                PATEL CHOWK, NEAR PETROL PUMP, SANGLI PH:-(0233)
                                2621046, 2621047
                            </div>
                        </div>
                        <div className="bill-company-address tac"></div>
                        <div className="bill-sub-title">
                            <div className="tal f2">Debit MaTiO</div>
                            <div className="tac f4">TAX INVOICE</div>
                            <div className="tar f2">Original</div>
                        </div>
                        <div className="info">
                            <div className="client">
                                <div>
                                    <b>
                                        <TextEdit
                                            style={{ paddingLeft: 0 }}
                                            val={
                                                this.state.billEdits
                                                    .client_name[0]
                                            }
                                            setVal={(val) =>
                                                this.setState({
                                                    billEdits: {
                                                        ...this.state.billEdits,
                                                        client_name: [
                                                            val,
                                                            this.state.billEdits
                                                                .client_name[1],
                                                        ],
                                                    },
                                                })
                                            }
                                            show={
                                                this.state.billEdits
                                                    .client_name[1]
                                            }
                                            toggle={(val) =>
                                                this.setState({
                                                    billEdits: {
                                                        ...this.state.billEdits,
                                                        client_name: [
                                                            this.state.billEdits
                                                                .client_name[0],
                                                            val,
                                                        ],
                                                    },
                                                })
                                            }
                                        />
                                    </b>
                                </div>
                                <div>
                                    <div className="half-a">
                                        <div>Mob No. : </div>
                                        <TextEdit
                                            val={
                                                this.state.billEdits
                                                    .client_mobile[0]
                                            }
                                            setVal={(val) =>
                                                this.setState({
                                                    billEdits: {
                                                        ...this.state.billEdits,
                                                        client_mobile: [
                                                            val,
                                                            this.state.billEdits
                                                                .client_mobile[1],
                                                        ],
                                                    },
                                                })
                                            }
                                            show={
                                                this.state.billEdits
                                                    .client_mobile[1]
                                            }
                                            toggle={(val) =>
                                                this.setState({
                                                    billEdits: {
                                                        ...this.state.billEdits,
                                                        client_mobile: [
                                                            this.state.billEdits
                                                                .client_mobile[0],
                                                            val,
                                                        ],
                                                    },
                                                })
                                            }
                                        />
                                    </div>
                                </div>
                                <div className="client-2">
                                    <div className="half-a">
                                        <div>Gstin : </div>
                                        <div>27gje8se868</div>
                                    </div>
                                    <div className="half-a">
                                        <div>
                                            <b>Place of supply : </b>
                                        </div>
                                        <div>Maharashtra</div>
                                    </div>
                                </div>
                            </div>
                            <div className="details">
                                <div className="gry">
                                    <div className="half-p">
                                        <div> Invoice No. : </div>
                                        <div className="p__5">GT/5219</div>
                                    </div>
                                    <div className="half-p">
                                        <div>{`Date  :  `}</div>
                                        <div>16/06/2020</div>
                                    </div>
                                </div>
                                <div>
                                    <div className="half-p">
                                        <div>
                                            <b>Vehicle No. : </b>
                                        </div>
                                        <TextEdit
                                            val={
                                                this.state.billEdits
                                                    .vehicleNo[0]
                                            }
                                            setVal={(val) =>
                                                this.setState({
                                                    billEdits: {
                                                        ...this.state.billEdits,
                                                        vehicleNo: [
                                                            val,
                                                            this.state.billEdits
                                                                .vehicleNo[1],
                                                        ],
                                                    },
                                                })
                                            }
                                            show={
                                                this.state.billEdits
                                                    .vehicleNo[1]
                                            }
                                            toggle={(val) =>
                                                this.setState({
                                                    billEdits: {
                                                        ...this.state.billEdits,
                                                        vehicleNo: [
                                                            this.state.billEdits
                                                                .vehicleNo[0],
                                                            val,
                                                        ],
                                                    },
                                                })
                                            }
                                        />
                                    </div>
                                    <div className="half-p">
                                        <div>
                                            <b>Time : </b>
                                        </div>
                                        <TextEdit
                                            val={this.state.billEdits.time[0]}
                                            setVal={(val) =>
                                                this.setState({
                                                    billEdits: {
                                                        ...this.state.billEdits,
                                                        time: [
                                                            val,
                                                            this.state.billEdits
                                                                .time[1],
                                                        ],
                                                    },
                                                })
                                            }
                                            show={this.state.billEdits.time[1]}
                                            toggle={(val) =>
                                                this.setState({
                                                    billEdits: {
                                                        ...this.state.billEdits,
                                                        time: [
                                                            this.state.billEdits
                                                                .time[0],
                                                            val,
                                                        ],
                                                    },
                                                })
                                            }
                                        />
                                    </div>
                                    <div className="half-p">
                                        <div>
                                            <b> Vehicle</b>
                                        </div>
                                        <TextEdit
                                            val={
                                                this.state.billEdits
                                                    .vehicleName[0]
                                            }
                                            setVal={(val) =>
                                                this.setState({
                                                    billEdits: {
                                                        ...this.state.billEdits,
                                                        vehicleName: [
                                                            val,
                                                            this.state.billEdits
                                                                .vehicleName[1],
                                                        ],
                                                    },
                                                })
                                            }
                                            show={
                                                this.state.billEdits
                                                    .vehicleName[1]
                                            }
                                            toggle={(val) =>
                                                this.setState({
                                                    billEdits: {
                                                        ...this.state.billEdits,
                                                        vehicleName: [
                                                            this.state.billEdits
                                                                .vehicleName[0],
                                                            val,
                                                        ],
                                                    },
                                                })
                                            }
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="bill-table">
                            <div className="bill-table-header">
                                <div>Sr.</div>
                                <div>Product Name</div>
                                <div>HSN/SAC</div>
                                <div>Qty</div>
                                <div>Rate</div>
                                <div>GST%</div>
                                <div>Amount</div>
                            </div>
                            {(this.state.billEdits.billTable || []).map(
                                (val, index) => (
                                    <div
                                        className="bill-table-content"
                                        key={"bill-table-" + index.toString()}
                                    >
                                        <div>{index}</div>
                                        <div>{val[0]}</div>
                                        <div>{val[1]}</div>
                                        <div>{val[2].toFixed(2)}</div>
                                        <div>{val[3].toFixed(2)}</div>
                                        <div>{val[4]}</div>
                                        <div className="tar p-2">
                                            {(val[2] * val[3]).toFixed(2)}
                                        </div>
                                    </div>
                                )
                            )}
                        </div>
                        <div className="bill-table-footer">
                            <div className="half-a">
                                <div>GSTIN NO. : </div>
                                <div>27AVAPP6656L1Z0</div>
                            </div>
                            <div className="half-a">
                                <div>Outstanding : </div>
                                <TextEdit
                                    val={this.state.billEdits.outstanding[0]}
                                    setVal={(val) =>
                                        this.setState({
                                            billEdits: {
                                                ...this.state.billEdits,
                                                outstanding: [
                                                    val,
                                                    this.state.billEdits
                                                        .outstanding[1],
                                                ],
                                            },
                                        })
                                    }
                                    show={this.state.billEdits.outstanding[1]}
                                    toggle={(val) =>
                                        this.setState({
                                            billEdits: {
                                                ...this.state.billEdits,
                                                outstanding: [
                                                    this.state.billEdits
                                                        .outstanding[0],
                                                    val,
                                                ],
                                            },
                                        })
                                    }
                                />
                            </div>
                            <div className="half-a">
                                <div>Sub Total</div>
                                <div className="tar">
                                    {this.state.billEdits.subTotal.toFixed(2)}
                                </div>
                            </div>
                        </div>
                        <div className="bill-summary">
                            <div className="bill-summary-1">
                                <div className="bill-summary-bank-details">
                                    <div className="p-2">
                                        <div className="half-b">
                                            <div>
                                                <b>Bank Name: </b>
                                            </div>
                                            <div>
                                                {this.state.billEdits.bank[0]}
                                            </div>
                                        </div>
                                        <div className="half-b">
                                            <div>
                                                <b>Bank A/c No.</b>
                                            </div>
                                            <div>
                                                {this.state.billEdits.bank[1]}
                                            </div>
                                        </div>
                                        <div className="half-b">
                                            <div>
                                                <b>RTGS/IFSC Code</b>
                                            </div>
                                            <div>
                                                {this.state.billEdits.bank[2]}
                                            </div>
                                            <div></div>
                                        </div>
                                    </div>
                                    <div></div>
                                </div>
                                <div className="bill-summary-amount-in-words p-2">
                                    <div className="half-a">
                                        <div>
                                            <b>Total GST: </b>
                                        </div>
                                        <div>
                                            {inWords(
                                                parseFloat(
                                                    parseFloat(
                                                        this.state.billEdits
                                                            .cgst
                                                    ) +
                                                        parseFloat(
                                                            this.state.billEdits
                                                                .sgst
                                                        )
                                                ).toFixed(2)
                                            )}
                                        </div>
                                    </div>
                                    <div className="half-a">
                                        <div>
                                            <b>Bill Ammount : </b>
                                        </div>
                                        <div>
                                            {inWords(
                                                parseFloat(
                                                    this.state.billEdits
                                                        .total[0]
                                                ).toFixed(2)
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="bill-ammounts">
                                <div></div>
                                <div className="pr-2">
                                    <div className="half-a pl-2">
                                        <div>
                                            <b>Taxable Amount</b>
                                        </div>
                                        <div className="tar">
                                            <b>
                                                {this.state.billEdits.subTotal}
                                            </b>
                                        </div>
                                    </div>
                                    <div className="half-p">
                                        <div>CGST</div>
                                        <div className="tar">
                                            {this.state.billEdits.cgst}
                                        </div>
                                    </div>
                                    <div className="half-p">
                                        <div>SGCT</div>
                                        <div className="tar">
                                            {this.state.billEdits.sgst}
                                        </div>
                                    </div>
                                    <div className="half-p">
                                        <div>Round-off</div>
                                        <div className="tar">-0.02</div>
                                    </div>
                                </div>
                                <div className="half-p px-2">
                                    <div>
                                        <b>Grand Total</b>
                                    </div>
                                    <div className="tar">
                                        <b>
                                            {this.state.billEdits.total[0].toFixed(
                                                2
                                            )}
                                        </b>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="bill-footer">
                            <div className="bill-notes px-2">
                                <div className="half-a">
                                    <div>
                                        <b>{"Terms & condition : "}</b>
                                    </div>
                                    <div>SAHAS</div>
                                </div>
                                <ol>
                                    <li>Goods Carried Will Not Be Accepted</li>
                                    <li>{`"Subjected To Sangli Juridictins Only.   E.&.O.E"`}</li>
                                    <li>{`All tyers warranties covered by themanufacturing companies & all the cliamsfinalized by company.`}</li>
                                </ol>
                                <div>Transport</div>
                            </div>
                            <div className="bill-receiver-sign">
                                <div></div>
                                <div className="pb-3">
                                    <b>Receivers Sign</b>
                                </div>
                            </div>
                            <div className="bill-authority-sign">
                                <div className="pt-4 tar pr-3">
                                    For, <b>Shakti Traders</b>
                                </div>
                                <div></div>
                                <div className="pb-4 tar pr-4">
                                    <small>(Authorised Signature)</small>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        );
    }

    renderUpdateBillModal2() {
        return (
            <Modal
                show={this.state.showUpdateModal}
                onHide={(e) => this.setState({ showUpdateModal: false })}
                size="xl"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                {false ? console.log("current state : ", this.state) : ""}
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Update bill
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="bill mx-5 my-3">
                        <div className="bill-title gry">
                            <div>
                                <img
                                    src={this.props.logo}
                                    alt={this.props.logo}
                                />
                            </div>
                            <div className="bill-title-block  tac">
                                <div>
                                    {this.state.billSettings
                                        ? this.state.billSettings[0].value
                                        : "Global Tyres default"}
                                </div>
                                <small>
                                    {this.state.billSettings
                                        ? this.state.billSettings[5].value
                                        : "PATEL CHOWK, NEAR PETROL PUMP, SANGLI PH:-(0233) 2621046,2621047"}
                                </small>
                            </div>
                        </div>
                        <div className="bill-company-logos tac">
                            {this.props.logos.map((logoPath) => (
                                <img src={logoPath} alt={logoPath} />
                            ))}
                        </div>
                        <div className="bill-sub-title">
                            <div className="tal f2">Debit MaTiO</div>
                            <div className="tac f4">TAX INVOICE</div>
                            <div className="tar f2">Original</div>
                        </div>
                        <div className="info">
                            <div className="client">
                                <div>
                                    <b>
                                        {false ? (
                                            <TextEdit
                                                style={{ paddingLeft: 0 }}
                                                val={
                                                    this.state.billEdits
                                                        .client_name[0]
                                                }
                                                setVal={(val) =>
                                                    this.setState({
                                                        billEdits: {
                                                            ...this.state
                                                                .billEdits,
                                                            client_name: [
                                                                val,
                                                                this.state
                                                                    .billEdits
                                                                    .client_name[1],
                                                            ],
                                                        },
                                                    })
                                                }
                                                show={
                                                    this.state.billEdits
                                                        .client_name[1]
                                                }
                                                toggle={(val) =>
                                                    this.setState({
                                                        billEdits: {
                                                            ...this.state
                                                                .billEdits,
                                                            client_name: [
                                                                this.state
                                                                    .billEdits
                                                                    .client_name[0],
                                                                val,
                                                            ],
                                                        },
                                                    })
                                                }
                                            />
                                        ) : (
                                            ""
                                        )}
                                        {
                                            <SelectEdit
                                                val={
                                                    this.state.billEdits
                                                        .client_name[0]
                                                }
                                                setVal={(v) => {
                                                    this.setState({
                                                        ...this.state,
                                                        billEdits: {
                                                            ...this.state
                                                                .billEdits,
                                                            client_name: [
                                                                parseInt(v),
                                                                this.state
                                                                    .billEdits
                                                                    .client_name[1],
                                                            ],
                                                        },
                                                    });
                                                }}
                                                show={
                                                    this.state.billEdits
                                                        .client_name[1]
                                                }
                                                toggle={(v) => {
                                                    this.setState({
                                                        ...this.state,
                                                        billEdits: {
                                                            ...this.state
                                                                .billEdits,
                                                            client_name: [
                                                                this.state
                                                                    .billEdits
                                                                    .client_name[0],
                                                                v,
                                                            ],
                                                        },
                                                    });
                                                }}
                                                options={this.state.allParties.map(
                                                    (v) => v.name
                                                )}
                                            />
                                        }
                                    </b>
                                </div>
                                <div>
                                    <div className="half-a">
                                        <div>Mob No. : </div>
                                        <div>
                                            {this.state.allParties[
                                                parseInt(
                                                    this.state.billEdits
                                                        .client_name
                                                )
                                            ]
                                                ? this.state.allParties[
                                                      parseInt(
                                                          this.state.billEdits
                                                              .client_name
                                                      )
                                                  ].mobile
                                                : ""}
                                        </div>
                                    </div>
                                </div>
                                <div className="client-2">
                                    <div className="half-a">
                                        <div>Gstin : </div>
                                        <div>
                                            <div>
                                                {this.state.allParties[
                                                    parseInt(
                                                        this.state.billEdits
                                                            .client_name
                                                    )
                                                ]
                                                    ? this.state.allParties[
                                                          parseInt(
                                                              this.state
                                                                  .billEdits
                                                                  .client_name
                                                          )
                                                      ].gstin
                                                    : ""}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="half-a">
                                        <div>
                                            <b>Place of supply : </b>
                                        </div>
                                        <div>
                                            <div>
                                                {this.state.allParties[
                                                    parseInt(
                                                        this.state.billEdits
                                                            .client_name
                                                    )
                                                ]
                                                    ? this.state.allParties[
                                                          parseInt(
                                                              this.state
                                                                  .billEdits
                                                                  .client_name
                                                          )
                                                      ].address
                                                    : ""}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="details">
                                <div className="gry">
                                    <div className="half-p">
                                        <div> Invoice No. : </div>
                                        <div className="p__5">GT/5219</div>
                                    </div>
                                    <div className="half-p">
                                        <div>{`Date  :  `}</div>
                                        {false ? (
                                            <div>16/06/2020</div>
                                        ) : (
                                            <DateEdit
                                                val={
                                                    this.state.billEdits.date[0]
                                                }
                                                setVal={(val) =>
                                                    this.setState({
                                                        billEdits: {
                                                            ...this.state
                                                                .billEdits,
                                                            date: [
                                                                val,
                                                                this.state
                                                                    .billEdits
                                                                    .date[1],
                                                            ],
                                                        },
                                                    })
                                                }
                                                show={
                                                    this.state.billEdits.date[1]
                                                }
                                                toggle={(val) =>
                                                    this.setState({
                                                        billEdits: {
                                                            ...this.state
                                                                .billEdits,
                                                            date: [
                                                                this.state
                                                                    .billEdits
                                                                    .date[0],
                                                                val,
                                                            ],
                                                        },
                                                    })
                                                }
                                            />
                                        )}
                                    </div>
                                </div>
                                <div>
                                    <div className="half-p">
                                        <div>
                                            <b>Vehicle No. : </b>
                                        </div>
                                        <TextEdit
                                            val={
                                                this.state.billEdits
                                                    .vehicleNo[0]
                                            }
                                            setVal={(val) =>
                                                this.setState({
                                                    billEdits: {
                                                        ...this.state.billEdits,
                                                        vehicleNo: [
                                                            val,
                                                            this.state.billEdits
                                                                .vehicleNo[1],
                                                        ],
                                                    },
                                                })
                                            }
                                            show={
                                                this.state.billEdits
                                                    .vehicleNo[1]
                                            }
                                            toggle={(val) =>
                                                this.setState({
                                                    billEdits: {
                                                        ...this.state.billEdits,
                                                        vehicleNo: [
                                                            this.state.billEdits
                                                                .vehicleNo[0],
                                                            val,
                                                        ],
                                                    },
                                                })
                                            }
                                        />
                                    </div>
                                    <div className="half-p">
                                        <div>
                                            <b>Time : </b>
                                        </div>
                                        <TimeEdit
                                            val={this.state.billEdits.time[0]}
                                            setVal={(val) =>
                                                this.setState({
                                                    billEdits: {
                                                        ...this.state.billEdits,
                                                        time: [
                                                            val,
                                                            this.state.billEdits
                                                                .time[1],
                                                        ],
                                                    },
                                                })
                                            }
                                            show={this.state.billEdits.time[1]}
                                            toggle={(val) =>
                                                this.setState({
                                                    billEdits: {
                                                        ...this.state.billEdits,
                                                        time: [
                                                            this.state.billEdits
                                                                .time[0],
                                                            val,
                                                        ],
                                                    },
                                                })
                                            }
                                        />
                                    </div>
                                    <div className="half-p">
                                        <div>
                                            <b> Vehicle</b>
                                        </div>
                                        <TextEdit
                                            val={
                                                this.state.billEdits
                                                    .vehicleName[0]
                                            }
                                            setVal={(val) =>
                                                this.setState({
                                                    billEdits: {
                                                        ...this.state.billEdits,
                                                        vehicleName: [
                                                            val,
                                                            this.state.billEdits
                                                                .vehicleName[1],
                                                        ],
                                                    },
                                                })
                                            }
                                            show={
                                                this.state.billEdits
                                                    .vehicleName[1]
                                            }
                                            toggle={(val) =>
                                                this.setState({
                                                    billEdits: {
                                                        ...this.state.billEdits,
                                                        vehicleName: [
                                                            this.state.billEdits
                                                                .vehicleName[0],
                                                            val,
                                                        ],
                                                    },
                                                })
                                            }
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="bill-table">
                            <div className="bill-table-header">
                                <div>Sr.</div>
                                <div>Product Name</div>
                                <div>HSN/SAC</div>
                                <div>Qty</div>
                                <div>Rate</div>
                                <div>GST%</div>
                                <div>Amount</div>
                            </div>
                            {(this.state.billEdits.billTable || []).map(
                                (val, index) => (
                                    <div key={"bill-table-" + index.toString()}>
                                        {false
                                            ? console.log(
                                                  "bill table content ",
                                                  index,
                                                  " : ",
                                                  val
                                              )
                                            : ""}
                                        <div>{index + 1}</div>
                                        <div>{val.productName}</div>
                                        <div>{val.hsn}</div>
                                        <div>{val.quantity}</div>
                                        <div>{val.rate}</div>
                                        <div>{val.cgst + val.sgst}</div>
                                        <div className="tar px-2">
                                            {(val.rate * val.quantity).toFixed(
                                                2
                                            )}
                                        </div>
                                    </div>
                                )
                            )}
                        </div>
                        <div className="bill-table-footer">
                            <div className="half-a">
                                <div>GSTIN NO. : </div>
                                <div>
                                    {this.state.billSettings
                                        ? this.state.billSettings[6].value
                                        : ""}
                                </div>
                            </div>
                            <div className="half-a">
                                <div>Sub Total</div>
                                <div className="tar">
                                    {this.state.billEdits.subTotal.toFixed(2)}
                                </div>
                            </div>
                        </div>
                        <div className="bill-summary">
                            <div className="bill-summary-1">
                                <div className="bill-summary-bank-details">
                                    <div className="p-2">
                                        <div className="half-b">
                                            <div>
                                                <b>Bank Name: </b>
                                            </div>
                                            <div>
                                                {this.state.billSettings
                                                    ? this.state.billSettings[1]
                                                          .value
                                                    : "BANK OF INDIA"}
                                            </div>
                                        </div>
                                        <div className="half-b">
                                            <div>
                                                <b>Bank A/c No.</b>
                                            </div>
                                            <div>
                                                {this.state.billSettings
                                                    ? this.state.billSettings[2]
                                                          .value
                                                    : "1609301100000075"}
                                            </div>
                                        </div>
                                        <div className="half-b">
                                            <div>
                                                <b>RTGS/IFSC Code</b>
                                            </div>
                                            <div>
                                                {this.state.billSettings
                                                    ? this.state.billSettings[3]
                                                          .value
                                                    : "BKID001609"}
                                            </div>
                                            <div></div>
                                        </div>
                                    </div>
                                    <div></div>
                                </div>
                                <div className="bill-summary-amount-in-words p-2">
                                    <div className="half-a">
                                        <div>
                                            <b>Total GST: </b>
                                        </div>
                                        <div>
                                            {inWords(
                                                parseFloat(
                                                    this.state.billEdits.cgst +
                                                        this.state.billEdits
                                                            .sgst
                                                ).toFixed(2)
                                            )}
                                        </div>
                                    </div>
                                    <div className="half-a">
                                        <div>
                                            <b>Bill Ammount : </b>
                                        </div>
                                        <div>
                                            {inWords(
                                                parseFloat(
                                                    this.state.billEdits
                                                        .total[0]
                                                ).toFixed(2)
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="bill-ammounts">
                                <div></div>
                                <div className="pr-2">
                                    <div className="half-a pl-2">
                                        <div>
                                            <b>Taxable Amount</b>
                                        </div>
                                        <div className="tar">
                                            <b>
                                                {this.state.billEdits.subTotal.toFixed(
                                                    2
                                                )}
                                            </b>
                                        </div>
                                    </div>
                                    <div className="half-p">
                                        <div>CGST</div>
                                        <div className="tar">
                                            {this.state.billEdits.cgst.toFixed(
                                                2
                                            )}
                                        </div>
                                    </div>
                                    <div className="half-p">
                                        <div>SGCT</div>
                                        <div className="tar">
                                            {this.state.billEdits.sgst.toFixed(
                                                2
                                            )}
                                        </div>
                                    </div>
                                    <div className="half-p">
                                        <div>Discount</div>
                                        <div className="tar">
                                            {parseFloat(
                                                this.state.billEdits.discount[0]
                                            ).toFixed(2)}
                                        </div>
                                    </div>
                                </div>
                                <div className="half-p px-2">
                                    <div>
                                        <b>Grand Total</b>
                                    </div>
                                    <div className="tar">
                                        {parseFloat(
                                            this.state.billEdits.total[0]
                                        ).toFixed(2)}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="bill-footer">
                            <div className="bill-notes px-2">
                                <div className="half-a">
                                    <div>
                                        <b>{"Terms & condition : "}</b>
                                    </div>
                                    <div>SAHAS</div>
                                </div>
                                {this.state.billSettings ? (
                                    <ol>
                                        {this.state.billSettings[4].value
                                            .split("$")
                                            .map((val, key) => (
                                                <li key={key}>{val}</li>
                                            ))}
                                    </ol>
                                ) : (
                                    <ol>
                                        <li>{`Goods Carried Will Not Be Accepted`}</li>
                                        <li>{`"Subjected To Sangli Juridictins Only.   E.&.O.E"`}</li>
                                        <li>{`All tyers warranties covered by themanufacturing companies & all the cliamsfinalized by company.`}</li>
                                    </ol>
                                )}
                                <div>Transport</div>
                            </div>
                            <div className="bill-receiver-sign">
                                <div></div>
                                <div className="pb-3">
                                    <b>Receivers Sign</b>
                                </div>
                            </div>
                            <div className="bill-authority-sign">
                                <div className="pt-4 tar pr-3">
                                    For,{" "}
                                    <b>
                                        {this.state.billSettings
                                            ? this.state.billSettings[0].value
                                            : ""}
                                    </b>
                                </div>
                                <div></div>
                                <div className="pb-4 tar pr-4">
                                    <small>(Authorised Signature)</small>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr />
                    <div className="mt-2 mr-1">
                        <Btn1
                            style={{ float: "right" }}
                            onClick={(e) => {
                                this.setState({
                                    showUpdateModal: false,
                                });
                                this.handleUpdateSubmit(e);
                            }}
                        >
                            Update
                        </Btn1>
                    </div>
                </Modal.Body>
            </Modal>
        );
    }

    renderUpdateBillModal() {
        return (
            <Modal
                show={this.state.showUpdateModal}
                onHide={(e) => this.setState({ showUpdateModal: false })}
                size="xl"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                {false ? console.log("current state : ", this.state) : ""}
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Bill Update
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <PrintBillUpdate
                        {...this.props}
                        {...this.state}
                        setState={(stateVal) => this.setState({ ...stateVal })}
                        ref={(el) => (this.componentRef = el)}
                    />
                    <hr />
                    <div className="mt-2 mr-1">
                        <ReactToPrint
                            trigger={() => {
                                return (
                                    <Btn1
                                        style={{ float: "right" }}
                                        className="mx-2"
                                        onClick={(e) => {
                                            console.log("Success!");
                                        }}
                                    >
                                        print
                                    </Btn1>
                                );
                            }}
                            content={() => this.componentRef}
                        />
                        <Btn1
                            style={{ float: "right" }}
                            onClick={(e) => {
                                this.setState({
                                    showUpdateModal: false,
                                });
                                this.handleUpdateSubmit(e);
                            }}
                        >
                            Update
                        </Btn1>
                    </div>
                </Modal.Body>
            </Modal>
        );
    }

    renderShowAddNewModal1() {
        return (
            <Modal
                show={this.state.showAddModal}
                onHide={(e) =>
                    this.setState({ showAddModal: false, billSettings: null })
                }
                size="xl"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                {false
                    ? console.log(
                          "render show bill modal : ",
                          this.state.allParties
                      )
                    : ""}
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        New Bill
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="bill mx-5 my-3">
                        <div className="bill-title gry">
                            <div>
                                <img
                                    src={this.props.logo}
                                    alt={this.props.logo}
                                />
                            </div>
                            <div className="bill-title-block  tac">
                                <div>
                                    {this.state.billSettings
                                        ? this.state.billSettings[0].value
                                        : "Global Tyres default"}
                                </div>
                                <small>
                                    {this.state.billSettings
                                        ? this.state.billSettings[5].value
                                        : "PATEL CHOWK, NEAR PETROL PUMP, SANGLI PH:-(0233) 2621046,2621047"}
                                </small>
                            </div>
                        </div>
                        <div className="bill-company-logos tac">
                            {this.props.logos.map((logoPath) => (
                                <img src={logoPath} alt={logoPath} />
                            ))}
                        </div>
                        <div className="bill-sub-title">
                            <div className="tal f2">Debit MaTiO</div>
                            <div className="tac f4">TAX INVOICE</div>
                            <div className="tar f2">Original</div>
                        </div>
                        <div className="info">
                            <div className="client">
                                <div>
                                    <b>
                                        {
                                            <SelectEdit
                                                val={
                                                    this.state.billEdits
                                                        .client_name[0]
                                                }
                                                setVal={(v) => {
                                                    this.setState({
                                                        ...this.state,
                                                        billEdits: {
                                                            ...this.state
                                                                .billEdits,
                                                            client_name: [
                                                                parseInt(v),
                                                                this.state
                                                                    .billEdits
                                                                    .client_name[1],
                                                            ],
                                                            client_id: this
                                                                .state
                                                                .allParties[
                                                                parseInt(v)
                                                            ]
                                                                ? this.state
                                                                      .allParties[
                                                                      parseInt(
                                                                          v
                                                                      )
                                                                  ].id
                                                                : "problem",
                                                        },
                                                    });
                                                }}
                                                show={
                                                    this.state.billEdits
                                                        .client_name[1]
                                                }
                                                toggle={(v) => {
                                                    this.setState({
                                                        ...this.state,
                                                        billEdits: {
                                                            ...this.state
                                                                .billEdits,
                                                            client_name: [
                                                                this.state
                                                                    .billEdits
                                                                    .client_name[0],
                                                                v,
                                                            ],
                                                        },
                                                    });
                                                }}
                                                options={this.state.allParties.map(
                                                    (v) => v.name
                                                )}
                                            />
                                        }
                                    </b>
                                </div>
                                <div>
                                    <div className="half-a">
                                        <div>Mob No. : </div>
                                        <div>
                                            {this.state.allParties[
                                                parseInt(
                                                    this.state.billEdits
                                                        .client_name
                                                )
                                            ]
                                                ? this.state.allParties[
                                                      parseInt(
                                                          this.state.billEdits
                                                              .client_name
                                                      )
                                                  ].mobile
                                                : ""}
                                        </div>
                                    </div>
                                </div>
                                <div className="client-2">
                                    <div className="half-a">
                                        <div>Gstin : </div>
                                        <div>
                                            <div>
                                                {this.state.allParties[
                                                    parseInt(
                                                        this.state.billEdits
                                                            .client_name
                                                    )
                                                ]
                                                    ? this.state.allParties[
                                                          parseInt(
                                                              this.state
                                                                  .billEdits
                                                                  .client_name
                                                          )
                                                      ].gstin
                                                    : ""}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="half-a">
                                        <div>
                                            <b>Place of supply : </b>
                                        </div>
                                        <div>
                                            <div>
                                                {this.state.allParties[
                                                    parseInt(
                                                        this.state.billEdits
                                                            .client_name
                                                    )
                                                ]
                                                    ? this.state.allParties[
                                                          parseInt(
                                                              this.state
                                                                  .billEdits
                                                                  .client_name
                                                          )
                                                      ].address
                                                    : ""}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="details">
                                <div className="gry">
                                    <div className="half-p">
                                        <div> Invoice No. : </div>
                                        <div className="p__5">GT/5219</div>
                                    </div>
                                    <div className="half-p">
                                        <div>{`Date  :  `}</div>
                                        {false ? (
                                            <div>16/06/2020</div>
                                        ) : (
                                            <DateEdit
                                                val={
                                                    this.state.billEdits.date[0]
                                                }
                                                setVal={(val) =>
                                                    this.setState({
                                                        billEdits: {
                                                            ...this.state
                                                                .billEdits,
                                                            date: [
                                                                val,
                                                                this.state
                                                                    .billEdits
                                                                    .date[1],
                                                            ],
                                                        },
                                                    })
                                                }
                                                show={
                                                    this.state.billEdits.date[1]
                                                }
                                                toggle={(val) =>
                                                    this.setState({
                                                        billEdits: {
                                                            ...this.state
                                                                .billEdits,
                                                            date: [
                                                                this.state
                                                                    .billEdits
                                                                    .date[0],
                                                                val,
                                                            ],
                                                        },
                                                    })
                                                }
                                            />
                                        )}
                                    </div>
                                </div>
                                <div>
                                    <div className="half-p">
                                        <div>
                                            <b>Vehicle No. : </b>
                                        </div>
                                        <TextEdit
                                            val={
                                                this.state.billEdits
                                                    .vehicleNo[0]
                                            }
                                            setVal={(val) =>
                                                this.setState({
                                                    billEdits: {
                                                        ...this.state.billEdits,
                                                        vehicleNo: [
                                                            val,
                                                            this.state.billEdits
                                                                .vehicleNo[1],
                                                        ],
                                                    },
                                                })
                                            }
                                            show={
                                                this.state.billEdits
                                                    .vehicleNo[1]
                                            }
                                            toggle={(val) =>
                                                this.setState({
                                                    billEdits: {
                                                        ...this.state.billEdits,
                                                        vehicleNo: [
                                                            this.state.billEdits
                                                                .vehicleNo[0],
                                                            val,
                                                        ],
                                                    },
                                                })
                                            }
                                        />
                                    </div>
                                    <div className="half-p">
                                        <div>
                                            <b>Time : </b>
                                        </div>
                                        <TimeEdit
                                            val={this.state.billEdits.time[0]}
                                            setVal={(val) =>
                                                this.setState({
                                                    billEdits: {
                                                        ...this.state.billEdits,
                                                        time: [
                                                            val,
                                                            this.state.billEdits
                                                                .time[1],
                                                        ],
                                                    },
                                                })
                                            }
                                            show={this.state.billEdits.time[1]}
                                            toggle={(val) =>
                                                this.setState({
                                                    billEdits: {
                                                        ...this.state.billEdits,
                                                        time: [
                                                            this.state.billEdits
                                                                .time[0],
                                                            val,
                                                        ],
                                                    },
                                                })
                                            }
                                        />
                                    </div>
                                    <div className="half-p">
                                        <div>
                                            <b> Vehicle</b>
                                        </div>
                                        <TextEdit
                                            val={
                                                this.state.billEdits
                                                    .vehicleName[0]
                                            }
                                            setVal={(val) =>
                                                this.setState({
                                                    billEdits: {
                                                        ...this.state.billEdits,
                                                        vehicleName: [
                                                            val,
                                                            this.state.billEdits
                                                                .vehicleName[1],
                                                        ],
                                                    },
                                                })
                                            }
                                            show={
                                                this.state.billEdits
                                                    .vehicleName[1]
                                            }
                                            toggle={(val) =>
                                                this.setState({
                                                    billEdits: {
                                                        ...this.state.billEdits,
                                                        vehicleName: [
                                                            this.state.billEdits
                                                                .vehicleName[0],
                                                            val,
                                                        ],
                                                    },
                                                })
                                            }
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="bill-table">
                            <div className="bill-table-header">
                                <div>Sr.</div>
                                <div>Product Name</div>
                                <div>HSN/SAC</div>
                                <div>Qty</div>
                                <div>Rate</div>
                                <div>GST%</div>
                                <div>Amount</div>
                            </div>
                            {(this.state.billEdits.billTable || []).map(
                                (val, index) => (
                                    <div key={"bill-table-" + index.toString()}>
                                        <div>{index + 1}</div>
                                        <div>
                                            {
                                                <SelectEdit
                                                    val={val[0]}
                                                    setVal={(v) => {
                                                        let rw =
                                                            this.state.billEdits
                                                                .billTable;
                                                        rw[index][0] = v;
                                                        this.setState({
                                                            ...this.state,
                                                            billEdits: {
                                                                ...this.state
                                                                    .billEdits,
                                                                billTable: rw,
                                                            },
                                                        });
                                                        this.adjustAmmounts();
                                                    }}
                                                    show={val[2]}
                                                    toggle={(v) => {
                                                        let rw =
                                                            this.state.billEdits
                                                                .billTable;
                                                        rw[index][2] = v;
                                                        this.setState({
                                                            ...this.state,
                                                            billEdits: {
                                                                ...this.state
                                                                    .billEdits,
                                                                billTable: rw,
                                                            },
                                                        });
                                                    }}
                                                    options={this.state.products.map(
                                                        (v) => v.productName
                                                    )}
                                                />
                                            }
                                        </div>
                                        <div>
                                            {this.state.products[val[0]]
                                                ? this.state.products[val[0]]
                                                      .hsn
                                                : ""}
                                        </div>
                                        <div>
                                            {
                                                <TextEdit
                                                    val={val[1]}
                                                    setVal={(v) => {
                                                        let rw =
                                                            this.state.billEdits
                                                                .billTable;
                                                        rw[index][1] =
                                                            parseInt(v);
                                                        this.setState({
                                                            ...this.state,
                                                            billEdits: {
                                                                ...this.state
                                                                    .billEdits,
                                                                billTable: rw,
                                                            },
                                                        });
                                                        this.adjustAmmounts();
                                                    }}
                                                    show={val[3]}
                                                    toggle={(val) => {
                                                        let rw =
                                                            this.state.billEdits
                                                                .billTable;
                                                        rw[index][3] = val;
                                                        this.setState({
                                                            ...this.state,
                                                            billEdits: {
                                                                ...this.state
                                                                    .billEdits,
                                                                billTable: rw,
                                                            },
                                                        });
                                                    }}
                                                    num={true}
                                                />
                                            }
                                        </div>
                                        <div>
                                            {this.state.products[val[0]]
                                                ? this.state.products[
                                                      val[0]
                                                  ].rate.toFixed(2)
                                                : ""}
                                        </div>
                                        <div>
                                            {this.state.products[val[0]]
                                                ? this.state.products[val[0]]
                                                      .cgst +
                                                  this.state.products[val[0]]
                                                      .sgst
                                                : ""}
                                        </div>
                                        <div className="tar px-2">
                                            {this.state.billEdits.subTotal.toFixed(
                                                2
                                            )}
                                        </div>
                                    </div>
                                )
                            )}
                            <div>
                                <div>
                                    <button
                                        className="btn"
                                        onClick={(e) => {
                                            let last =
                                                this.state.billEdits.billTable
                                                    .length > 0
                                                    ? this.state.billEdits
                                                          .billTable[
                                                          this.state.billEdits
                                                              .billTable
                                                              .length - 1
                                                      ]
                                                    : ["abc"];
                                            if (
                                                last[0] ||
                                                last[1] ||
                                                last[2] ||
                                                last[3] ||
                                                last[4]
                                            ) {
                                                last =
                                                    this.state.billEdits
                                                        .billTable;
                                                last.push([0, 1, true, true]);
                                                this.setState({
                                                    ...this.state,
                                                    billEdits: {
                                                        ...this.state.billEdits,
                                                        billTable: last,
                                                    },
                                                });
                                                this.adjustAmmounts();
                                            }
                                        }}
                                    >
                                        <FontAwesomeIcon
                                            icon={faPlusCircle}
                                            style={{ color: "green" }}
                                        />
                                    </button>
                                </div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div className="tar p-2"></div>
                            </div>
                        </div>
                        <div className="bill-table-footer">
                            <div className="half-a">
                                <div>GSTIN NO. : </div>
                                <div>
                                    {this.state.billSettings
                                        ? this.state.billSettings[6].value
                                        : `27AVAPP6656L1Z0`}
                                </div>
                            </div>
                            <div className="half-a">
                                <div>Sub Total</div>
                                <div className="tar">
                                    {this.state.billEdits.subTotal.toFixed(2)}
                                </div>
                            </div>
                        </div>
                        <div className="bill-summary">
                            <div className="bill-summary-1">
                                <div className="bill-summary-bank-details">
                                    <div className="p-2">
                                        <div className="half-b">
                                            <div>
                                                <b>Bank Name: </b>
                                            </div>
                                            <div>
                                                {this.state.billSettings
                                                    ? this.state.billSettings[1]
                                                          .value
                                                    : "BANK OF INDIA"}
                                            </div>
                                        </div>
                                        <div className="half-b">
                                            <div>
                                                <b>Bank A/c No.</b>
                                            </div>
                                            <div>
                                                {this.state.billSettings
                                                    ? this.state.billSettings[2]
                                                          .value
                                                    : "1609301100000075"}
                                            </div>
                                        </div>
                                        <div className="half-b">
                                            <div>
                                                <b>RTGS/IFSC Code</b>
                                            </div>
                                            <div>
                                                {this.state.billSettings
                                                    ? this.state.billSettings[3]
                                                          .value
                                                    : "BKID001609"}
                                            </div>
                                            <div></div>
                                        </div>
                                    </div>
                                    <div></div>
                                </div>
                                <div className="bill-summary-amount-in-words p-2">
                                    <div className="half-a">
                                        <div>
                                            <b>Total GST: </b>
                                        </div>
                                        <div>
                                            {inWords(
                                                parseFloat(
                                                    this.state.billEdits.cgst +
                                                        this.state.billEdits
                                                            .sgst
                                                ).toFixed(2)
                                            )}
                                        </div>
                                    </div>
                                    <div className="half-a">
                                        <div>
                                            <b>Bill Ammount : </b>
                                        </div>
                                        <div>
                                            {inWords(
                                                parseFloat(
                                                    this.state.billEdits
                                                        .total[0]
                                                ).toFixed(2)
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="bill-ammounts">
                                <div></div>
                                <div className="pr-2">
                                    <div className="half-a pl-2">
                                        <div>
                                            <b>Taxable Amount</b>
                                        </div>
                                        <div className="tar">
                                            <b>
                                                {this.state.billEdits.subTotal.toFixed(
                                                    2
                                                )}
                                            </b>
                                        </div>
                                    </div>
                                    <div className="half-p">
                                        <div>CGST</div>
                                        <div className="tar">
                                            {this.state.billEdits.cgst.toFixed(
                                                2
                                            )}
                                        </div>
                                    </div>
                                    <div className="half-p">
                                        <div>SGCT</div>
                                        <div className="tar">
                                            {this.state.billEdits.sgst.toFixed(
                                                2
                                            )}
                                        </div>
                                    </div>
                                    <div className="half-p">
                                        <div>Discount</div>
                                        <div className="tar">
                                            {false ? (
                                                parseFloat(
                                                    this.state.billEdits
                                                        .discount[0]
                                                ).toFixed(2)
                                            ) : (
                                                <TextEdit
                                                    showOnly={
                                                        parseFloat(
                                                            this.state.billEdits
                                                                .subTotal
                                                        ) === parseFloat(0.0)
                                                            ? true
                                                            : false
                                                    }
                                                    val={parseFloat(
                                                        this.state.billEdits
                                                            .discount[0]
                                                    ).toFixed(2)}
                                                    setVal={(val) =>
                                                        this.setState({
                                                            billEdits: {
                                                                ...this.state
                                                                    .billEdits,
                                                                discount: [
                                                                    val,
                                                                    this.state
                                                                        .billEdits
                                                                        .discount[1],
                                                                ],
                                                                total: [
                                                                    parseFloat(
                                                                        this
                                                                            .state
                                                                            .billEdits
                                                                            .total[0]
                                                                    ) +
                                                                        parseFloat(
                                                                            this
                                                                                .state
                                                                                .billEdits
                                                                                .discount[0]
                                                                        ) -
                                                                        parseFloat(
                                                                            val
                                                                        ),
                                                                    this.state
                                                                        .billEdits
                                                                        .total[1],
                                                                ],
                                                            },
                                                        })
                                                    }
                                                    show={
                                                        this.state.billEdits
                                                            .discount[1]
                                                    }
                                                    toggle={(val) =>
                                                        this.setState({
                                                            billEdits: {
                                                                ...this.state
                                                                    .billEdits,
                                                                discount: [
                                                                    this.state
                                                                        .billEdits
                                                                        .discount[0],
                                                                    val,
                                                                ],
                                                            },
                                                        })
                                                    }
                                                />
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div className="half-p px-2">
                                    <div>
                                        <b>Grand Total</b>
                                    </div>
                                    <div className="tar">
                                        <TextEdit
                                            showOnly={
                                                parseFloat(
                                                    this.state.billEdits
                                                        .subTotal
                                                ) === parseFloat(0.0)
                                                    ? true
                                                    : false
                                            }
                                            val={parseFloat(
                                                this.state.billEdits.total[0]
                                            ).toFixed(2)}
                                            setVal={(val) =>
                                                this.setState({
                                                    billEdits: {
                                                        ...this.state.billEdits,
                                                        total: [
                                                            val,
                                                            this.state.billEdits
                                                                .total[1],
                                                        ],
                                                        discount: [
                                                            parseFloat(
                                                                this.state
                                                                    .billEdits
                                                                    .total[0]
                                                            ) +
                                                                parseFloat(
                                                                    this.state
                                                                        .billEdits
                                                                        .discount[0]
                                                                ) -
                                                                parseFloat(val),
                                                            this.state.billEdits
                                                                .discount[1],
                                                        ],
                                                    },
                                                })
                                            }
                                            show={this.state.billEdits.total[1]}
                                            toggle={(val) =>
                                                this.setState({
                                                    billEdits: {
                                                        ...this.state.billEdits,
                                                        total: [
                                                            this.state.billEdits
                                                                .total[0],
                                                            val,
                                                        ],
                                                    },
                                                })
                                            }
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="bill-footer">
                            <div className="bill-notes px-2">
                                <div className="half-a">
                                    <div>
                                        <b>{"Terms & condition : "}</b>
                                    </div>
                                    <div>SAHAS</div>
                                </div>
                                {this.state.billSettings ? (
                                    <ol>
                                        {this.state.billSettings[4].value
                                            .split("$")
                                            .map((val, key) => (
                                                <li key={key}>{val}</li>
                                            ))}
                                    </ol>
                                ) : (
                                    <ol>
                                        <li>{`Goods Carried Will Not Be Accepted`}</li>
                                        <li>{`"Subjected To Sangli Juridictins Only.   E.&.O.E"`}</li>
                                        <li>{`All tyers warranties covered by themanufacturing companies & all the cliamsfinalized by company.`}</li>
                                    </ol>
                                )}
                                <div>Transport</div>
                            </div>
                            <div className="bill-receiver-sign">
                                <div></div>
                                <div className="pb-3">
                                    <b>Receivers Sign</b>
                                </div>
                            </div>
                            <div className="bill-authority-sign">
                                <div className="pt-4 tar pr-3">
                                    For,{" "}
                                    <b>
                                        {this.state.billSettings
                                            ? this.state.billSettings[0].value
                                            : ""}
                                    </b>
                                </div>
                                <div></div>
                                <div className="pb-4 tar pr-4">
                                    <small>(Authorised Signature)</small>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr />
                    <div className="mt-2 mr-1">
                        <Btn1
                            style={{ float: "right" }}
                            className="mx-2"
                            onClick={(e) => {
                                this.setState({
                                    showUpdateModal: false,
                                });
                                this.handleAddSubmit(e);
                            }}
                            disabled
                        >
                            print
                        </Btn1>
                        <Btn1
                            style={{ float: "right" }}
                            onClick={(e) => {
                                this.setState({
                                    showAddModal: false,
                                });
                                this.handleAddSubmit(e);
                            }}
                        >
                            save
                        </Btn1>
                    </div>
                </Modal.Body>
            </Modal>
        );
    }

    renderShowAddNewModal() {
        return (
            <Modal
                show={this.state.showAddModal}
                onHide={(e) =>
                    this.setState({ showAddModal: false, billSettings: null })
                }
                size="xl"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                {false
                    ? console.log(
                          "render show bill modal : ",
                          this.state.allParties
                      )
                    : ""}
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        New Bill
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <PrintBillCreate
                        {...this.props}
                        {...this.state}
                        setState={(stateVal) => this.setState({ ...stateVal })}
                        adjustAmmounts={() => this.adjustAmmounts()}
                        ref={(el) => (this.componentRef = el)}
                    />
                    <hr />
                    <div className="mt-2 mr-1">
                        <ReactToPrint
                            trigger={() => {
                                return (
                                    <Btn1
                                        style={{ float: "right" }}
                                        className="mx-2"
                                        onClick={(e) => {
                                            this.handleAddSubmit(e);
                                            console.log("Success!");
                                        }}
                                    >
                                        save and print
                                    </Btn1>
                                );
                            }}
                            content={() => this.componentRef}
                        />
                        <Btn1
                            style={{ float: "right" }}
                            onClick={(e) => {
                                this.setState({
                                    showAddModal: false,
                                });
                                this.handleAddSubmit(e);
                            }}
                        >
                            save
                        </Btn1>
                    </div>
                </Modal.Body>
            </Modal>
        );
    }

    renderShowBillModal1() {
        return (
            <Modal
                show={this.state.showBillModal}
                onHide={(e) => this.setState({ showBillModal: false })}
                size="xl"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        New Bill
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="bill mx-5 my-3">
                        <div className="bill-title tac gry">SHAKTI TRADERS</div>
                        <div className="bill-company-address tac">
                            PATEL CHOWK, NEAR PETROL PUMP, SANGLI PH:-(0233)
                            2621046, 2621047
                        </div>
                        <div className="bill-sub-title">
                            <div className="tal f2">Debit MaTiO</div>
                            <div className="tac f4">TAX INVOICE</div>
                            <div className="tar f2">Original</div>
                        </div>
                        <div className="info">
                            <div className="client">
                                <div>
                                    <b>
                                        <TextEdit
                                            style={{ paddingLeft: 0 }}
                                            val={
                                                this.state.billEdits
                                                    .client_name[0]
                                            }
                                            setVal={(val) =>
                                                this.setState({
                                                    billEdits: {
                                                        ...this.state.billEdits,
                                                        client_name: [
                                                            val,
                                                            this.state.billEdits
                                                                .client_name[1],
                                                        ],
                                                    },
                                                })
                                            }
                                            show={
                                                this.state.billEdits
                                                    .client_name[1]
                                            }
                                            toggle={(val) =>
                                                this.setState({
                                                    billEdits: {
                                                        ...this.state.billEdits,
                                                        client_name: [
                                                            this.state.billEdits
                                                                .client_name[0],
                                                            val,
                                                        ],
                                                    },
                                                })
                                            }
                                            showOnly={true}
                                        />
                                    </b>
                                </div>
                                <div>
                                    <div className="half-a">
                                        <div>Mob No. : </div>
                                        <TextEdit
                                            val={
                                                this.state.billEdits
                                                    .client_mobile[0]
                                            }
                                            setVal={(val) =>
                                                this.setState({
                                                    billEdits: {
                                                        ...this.state.billEdits,
                                                        client_mobile: [
                                                            val,
                                                            this.state.billEdits
                                                                .client_mobile[1],
                                                        ],
                                                    },
                                                })
                                            }
                                            show={
                                                this.state.billEdits
                                                    .client_mobile[1]
                                            }
                                            toggle={(val) =>
                                                this.setState({
                                                    billEdits: {
                                                        ...this.state.billEdits,
                                                        client_mobile: [
                                                            this.state.billEdits
                                                                .client_mobile[0],
                                                            val,
                                                        ],
                                                    },
                                                })
                                            }
                                            showOnly={true}
                                        />
                                    </div>
                                </div>
                                <div className="client-2">
                                    <div className="half-a">
                                        <div>Gstin : </div>
                                        <div>27gje8se868</div>
                                    </div>
                                    <div className="half-a">
                                        <div>
                                            <b>Place of supply : </b>
                                        </div>
                                        <div>Maharashtra</div>
                                    </div>
                                </div>
                            </div>
                            <div className="details">
                                <div className="gry">
                                    <div className="half-p">
                                        <div> Invoice No. : </div>
                                        <div className="p__5">GT/5219</div>
                                    </div>
                                    <div className="half-p">
                                        <div>{`Date  :  `}</div>
                                        <div>16/06/2020</div>
                                    </div>
                                </div>
                                <div>
                                    <div className="half-p">
                                        <div>
                                            <b>Vehicle No. : </b>
                                        </div>
                                        <TextEdit
                                            val={
                                                this.state.billEdits
                                                    .vehicleNo[0]
                                            }
                                            setVal={(val) =>
                                                this.setState({
                                                    billEdits: {
                                                        ...this.state.billEdits,
                                                        vehicleNo: [
                                                            val,
                                                            this.state.billEdits
                                                                .vehicleNo[1],
                                                        ],
                                                    },
                                                })
                                            }
                                            show={
                                                this.state.billEdits
                                                    .vehicleNo[1]
                                            }
                                            toggle={(val) =>
                                                this.setState({
                                                    billEdits: {
                                                        ...this.state.billEdits,
                                                        vehicleNo: [
                                                            this.state.billEdits
                                                                .vehicleNo[0],
                                                            val,
                                                        ],
                                                    },
                                                })
                                            }
                                            showOnly={true}
                                        />
                                    </div>
                                    <div className="half-p">
                                        <div>
                                            <b>Time : </b>
                                        </div>
                                        <TextEdit
                                            val={this.state.billEdits.time[0]}
                                            setVal={(val) =>
                                                this.setState({
                                                    billEdits: {
                                                        ...this.state.billEdits,
                                                        time: [
                                                            val,
                                                            this.state.billEdits
                                                                .time[1],
                                                        ],
                                                    },
                                                })
                                            }
                                            show={this.state.billEdits.time[1]}
                                            toggle={(val) =>
                                                this.setState({
                                                    billEdits: {
                                                        ...this.state.billEdits,
                                                        time: [
                                                            this.state.billEdits
                                                                .time[0],
                                                            val,
                                                        ],
                                                    },
                                                })
                                            }
                                            showOnly={true}
                                        />
                                    </div>
                                    <div className="half-p">
                                        <div>
                                            <b> Vehicle</b>
                                        </div>
                                        <TextEdit
                                            val={
                                                this.state.billEdits
                                                    .vehicleName[0]
                                            }
                                            setVal={(val) =>
                                                this.setState({
                                                    billEdits: {
                                                        ...this.state.billEdits,
                                                        vehicleName: [
                                                            val,
                                                            this.state.billEdits
                                                                .vehicleName[1],
                                                        ],
                                                    },
                                                })
                                            }
                                            show={
                                                this.state.billEdits
                                                    .vehicleName[1]
                                            }
                                            toggle={(val) =>
                                                this.setState({
                                                    billEdits: {
                                                        ...this.state.billEdits,
                                                        vehicleName: [
                                                            this.state.billEdits
                                                                .vehicleName[0],
                                                            val,
                                                        ],
                                                    },
                                                })
                                            }
                                            showOnly={true}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="bill-table">
                            <div className="bill-table-header">
                                <div>Sr.</div>
                                <div>Product Name</div>
                                <div>HSN/SAC</div>
                                <div>Qty</div>
                                <div>Rate</div>
                                <div>GST%</div>
                                <div>Amount</div>
                            </div>
                            {(this.state.billEdits.billTable || []).map(
                                (val, index) => (
                                    <div
                                        className="bill-table-content"
                                        key={"bill-table-" + index.toString()}
                                    >
                                        <div>{index}</div>
                                        <div>{val[0]}</div>
                                        <div>{val[1]}</div>
                                        <div>{val[2].toFixed(2)}</div>
                                        <div>{val[3].toFixed(2)}</div>
                                        <div>{val[4]}</div>
                                        <div className="tar p-2">
                                            {(val[2] * val[3]).toFixed(2)}
                                        </div>
                                    </div>
                                )
                            )}
                        </div>
                        <div className="bill-table-footer">
                            <div className="half-a">
                                <div>GSTIN NO. : </div>
                                <div>27AVAPP6656L1Z0</div>
                            </div>
                            <div className="half-a">
                                <div>Outstanding : </div>
                                <TextEdit
                                    val={this.state.billEdits.outstanding[0]}
                                    setVal={(val) =>
                                        this.setState({
                                            billEdits: {
                                                ...this.state.billEdits,
                                                outstanding: [
                                                    val,
                                                    this.state.billEdits
                                                        .outstanding[1],
                                                ],
                                            },
                                        })
                                    }
                                    show={this.state.billEdits.outstanding[1]}
                                    toggle={(val) =>
                                        this.setState({
                                            billEdits: {
                                                ...this.state.billEdits,
                                                outstanding: [
                                                    this.state.billEdits
                                                        .outstanding[0],
                                                    val,
                                                ],
                                            },
                                        })
                                    }
                                    showOnly={true}
                                />
                            </div>
                            <div className="half-a">
                                <div>Sub Total</div>
                                <div className="tar">
                                    {this.state.billEdits.subTotal.toFixed(2)}
                                </div>
                            </div>
                        </div>
                        <div className="bill-summary">
                            <div className="bill-summary-1">
                                <div className="bill-summary-bank-details">
                                    <div className="p-2">
                                        <div className="half-b">
                                            <div>
                                                <b>Bank Name: </b>
                                            </div>
                                            <div>
                                                {this.state.billEdits.bank[0]}
                                            </div>
                                        </div>
                                        <div className="half-b">
                                            <div>
                                                <b>Bank A/c No.</b>
                                            </div>
                                            <div>
                                                {this.state.billEdits.bank[1]}
                                            </div>
                                        </div>
                                        <div className="half-b">
                                            <div>
                                                <b>RTGS/IFSC Code</b>
                                            </div>
                                            <div>
                                                {this.state.billEdits.bank[2]}
                                            </div>
                                            <div></div>
                                        </div>
                                    </div>
                                    <div></div>
                                </div>
                                <div className="bill-summary-amount-in-words p-2">
                                    <div className="half-a">
                                        <div>
                                            <b>Total GST: </b>
                                        </div>
                                        <div>
                                            {inWords(
                                                parseFloat(
                                                    this.state.billEdits.cgst +
                                                        this.state.billEdits
                                                            .sgst
                                                ).toFixed(2)
                                            )}
                                        </div>
                                    </div>
                                    <div className="half-a">
                                        <div>
                                            <b>Bill Ammount : </b>
                                        </div>
                                        <div>
                                            {inWords(
                                                parseFloat(
                                                    this.state.billEdits
                                                        .total[0]
                                                ).toFixed(2)
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="bill-ammounts">
                                <div></div>
                                <div className="pr-2">
                                    <div className="half-a pl-2">
                                        <div>
                                            <b>Taxable Amount</b>
                                        </div>
                                        <div className="tar">
                                            <b>
                                                {this.state.billEdits.subTotal}
                                            </b>
                                        </div>
                                    </div>
                                    <div className="half-p">
                                        <div>CGST</div>
                                        <div className="tar">
                                            {this.state.billEdits.cgst}
                                        </div>
                                    </div>
                                    <div className="half-p">
                                        <div>SGCT</div>
                                        <div className="tar">
                                            {this.state.billEdits.sgst}
                                        </div>
                                    </div>
                                    <div className="half-p">
                                        <div>Round-off</div>
                                        <div className="tar">-0.02</div>
                                    </div>
                                </div>
                                <div className="half-p px-2">
                                    <div>
                                        <b>Grand Total</b>
                                    </div>
                                    <div className="tar">
                                        <b>
                                            {this.state.billEdits.total[0].toFixed(
                                                2
                                            )}
                                        </b>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="bill-footer">
                            <div className="bill-notes px-2">
                                <div className="half-a">
                                    <div>
                                        <b>{"Terms & condition : "}</b>
                                    </div>
                                    <div>SAHAS</div>
                                </div>
                                <ol>
                                    <li>Goods Carried Will Not Be Accepted</li>
                                    <li>{`"Subjected To Sangli Juridictins Only.   E.&.O.E"`}</li>
                                    <li>{`All tyers warranties covered by themanufacturing companies & all the cliamsfinalized by company.`}</li>
                                </ol>
                                <div>Transport</div>
                            </div>
                            <div className="bill-receiver-sign">
                                <div></div>
                                <div className="pb-3">
                                    <b>Receivers Sign</b>
                                </div>
                            </div>
                            <div className="bill-authority-sign">
                                <div className="pt-4 tar pr-3">
                                    For, <b>Shakti Traders</b>
                                </div>
                                <div></div>
                                <div className="pb-4 tar pr-4">
                                    <small>(Authorised Signature)</small>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        );
    }

    renderShowBillModal2() {
        return (
            <Modal
                show={this.state.showBillModal}
                onHide={(e) => this.setState({ showBillModal: false })}
                size="xl"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                {false
                    ? console.log("render show bill modal : ", this.state.party)
                    : ""}
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Show Bill
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {false ? (
                        <div className="bill mx-5 my-3">
                            <div className="bill-title gry">
                                <div>
                                    <img
                                        src={this.props.logo}
                                        alt={this.props.logo}
                                    />
                                </div>
                                <div className="bill-title-block  tac">
                                    <div>
                                        {this.state.billSettings
                                            ? this.state.billSettings[0].value
                                            : "Global Tyres default"}
                                    </div>
                                    <small>
                                        {this.state.billSettings
                                            ? this.state.billSettings[5].value
                                            : "PATEL CHOWK, NEAR PETROL PUMP, SANGLI PH:-(0233) 2621046,2621047"}
                                    </small>
                                </div>
                            </div>
                            <div className="bill-company-logos tac">
                                {this.props.logos.map((logoPath) => (
                                    <img src={logoPath} alt={logoPath} />
                                ))}
                            </div>
                            <div className="bill-sub-title">
                                <div className="tal f2">Debit MaTiO</div>
                                <div className="tac f4">TAX INVOICE</div>
                                <div className="tar f2">Original</div>
                            </div>
                            <div className="info">
                                <div className="client">
                                    <div>
                                        <b>
                                            {this.state.billEdits.party[0]
                                                ? this.state.billEdits.party[0]
                                                      .name
                                                : ""}
                                        </b>
                                    </div>
                                    <div>
                                        <div className="half-a">
                                            <div>Mob No. : </div>
                                            <div>
                                                {this.state.billEdits.party[0]
                                                    ? this.state.billEdits
                                                          .party[0].mobile
                                                    : ""}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="client-2">
                                        <div className="half-a">
                                            <div>Gstin : </div>
                                            <div>
                                                <div>
                                                    {this.state.billEdits
                                                        .party[0]
                                                        ? this.state.billEdits
                                                              .party[0].gstin
                                                        : ""}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="half-a">
                                            <div>
                                                <b>Place of supply : </b>
                                            </div>
                                            <div>
                                                <div>
                                                    {this.state.billEdits
                                                        .party[0]
                                                        ? this.state.billEdits
                                                              .party[0].address
                                                        : ""}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="details">
                                    <div className="gry">
                                        <div className="half-p">
                                            <div> Invoice No. : </div>
                                            <div className="p__5">GT/5219</div>
                                        </div>
                                        <div className="half-p">
                                            <div>{`Date  :  `}</div>
                                            <div>
                                                {
                                                    this.state.dateTime.split(
                                                        "T"
                                                    )[0]
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <div className="half-p">
                                            <div>
                                                <b>Vehicle No. : </b>
                                            </div>
                                            <div>{this.state.vehicleNo}</div>
                                        </div>
                                        <div className="half-p">
                                            <div>
                                                <b>Time : </b>
                                            </div>
                                            <div>
                                                {
                                                    this.state.dateTime.split(
                                                        "T"
                                                    )[0]
                                                }
                                            </div>
                                        </div>
                                        <div className="half-p">
                                            <div>
                                                <b> Vehicle</b>
                                            </div>
                                            <div>{this.state.vehicleName}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="bill-table">
                                <div className="bill-table-header">
                                    <div>Sr.</div>
                                    <div>Product Name</div>
                                    <div>HSN/SAC</div>
                                    <div>Qty</div>
                                    <div>Rate</div>
                                    <div>GST%</div>
                                    <div>Amount</div>
                                </div>
                                {(this.state.billEdits.billTable || []).map(
                                    (val, index) => (
                                        <div
                                            key={
                                                "bill-table-" + index.toString()
                                            }
                                        >
                                            {false
                                                ? console.log(
                                                      "bill table content ",
                                                      index,
                                                      " : ",
                                                      val
                                                  )
                                                : ""}
                                            <div>{index + 1}</div>
                                            <div>{val.productName}</div>
                                            <div>{val.hsn}</div>
                                            <div>{val.quantity}</div>
                                            <div>{val.rate}</div>
                                            <div>{val.cgst + val.sgst}</div>
                                            <div className="tar px-2">
                                                {(
                                                    val.rate * val.quantity
                                                ).toFixed(2)}
                                            </div>
                                        </div>
                                    )
                                )}
                            </div>
                            <div className="bill-table-footer">
                                <div className="half-a">
                                    <div>GSTIN NO. : </div>
                                    <div>
                                        {this.state.billSettings
                                            ? this.state.billSettings[6].value
                                            : ""}
                                    </div>
                                </div>
                                <div className="half-a">
                                    <div>Sub Total</div>
                                    <div className="tar">
                                        {this.state.billEdits.subTotal.toFixed(
                                            2
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className="bill-summary">
                                <div className="bill-summary-1">
                                    <div className="bill-summary-bank-details">
                                        <div className="p-2">
                                            <div className="half-b">
                                                <div>
                                                    <b>Bank Name: </b>
                                                </div>
                                                <div>
                                                    {this.state.billSettings
                                                        ? this.state
                                                              .billSettings[1]
                                                              .value
                                                        : "BANK OF INDIA"}
                                                </div>
                                            </div>
                                            <div className="half-b">
                                                <div>
                                                    <b>Bank A/c No.</b>
                                                </div>
                                                <div>
                                                    {this.state.billSettings
                                                        ? this.state
                                                              .billSettings[2]
                                                              .value
                                                        : ""}
                                                </div>
                                            </div>
                                            <div className="half-b">
                                                <div>
                                                    <b>RTGS/IFSC Code</b>
                                                </div>
                                                <div>
                                                    {this.state.billSettings
                                                        ? this.state
                                                              .billSettings[3]
                                                              .value
                                                        : ""}
                                                </div>
                                                <div></div>
                                            </div>
                                        </div>
                                        <div></div>
                                    </div>
                                    <div className="bill-summary-amount-in-words p-2">
                                        <div className="half-a">
                                            <div>
                                                <b>Total GST: </b>
                                            </div>
                                            <div>
                                                {inWords(
                                                    parseFloat(
                                                        this.state.billEdits
                                                            .cgst +
                                                            this.state.billEdits
                                                                .sgst
                                                    ).toFixed(2)
                                                )}
                                            </div>
                                        </div>
                                        <div className="half-a">
                                            <div>
                                                <b>Bill Ammount : </b>
                                            </div>
                                            <div>
                                                {inWords(
                                                    parseFloat(
                                                        this.state.billEdits
                                                            .total
                                                    ).toFixed(2)
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="bill-ammounts">
                                    <div></div>
                                    <div className="pr-2">
                                        <div className="half-a pl-2">
                                            <div>
                                                <b>Taxable Amount</b>
                                            </div>
                                            <div className="tar">
                                                <b>
                                                    {parseFloat(
                                                        this.state.billEdits
                                                            .subTotal
                                                    ).toFixed(2)}
                                                </b>
                                            </div>
                                        </div>
                                        <div className="half-p">
                                            <div>CGST</div>
                                            <div className="tar">
                                                {parseFloat(
                                                    this.state.billEdits.cgst
                                                ).toFixed(2)}
                                            </div>
                                        </div>
                                        <div className="half-p">
                                            <div>SGCT</div>
                                            <div className="tar">
                                                {parseFloat(
                                                    this.state.billEdits.sgst
                                                ).toFixed(2)}
                                            </div>
                                        </div>
                                        <div className="half-p">
                                            <div>Discount</div>
                                            <div className="tar">
                                                {parseFloat(
                                                    this.state.billEdits
                                                        .discount[0]
                                                ).toFixed(2)}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="half-p px-2">
                                        <div>
                                            <b>Grand Total</b>
                                        </div>
                                        <div className="tar">
                                            {parseFloat(
                                                this.state.billEdits.total
                                            ).toFixed(2)}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="bill-footer">
                                <div className="bill-notes px-2">
                                    <div className="half-a">
                                        <div>
                                            <b>{"Terms & condition : "}</b>
                                        </div>
                                        <div>SAHAS</div>
                                    </div>
                                    <ol>
                                        {this.state.billSettings
                                            ? this.state.billSettings[4].value
                                                  .split("$")
                                                  .map((val, key) => (
                                                      <li key={key}>{val}</li>
                                                  ))
                                            : ""}
                                    </ol>
                                    <div>Transport</div>
                                </div>
                                <div className="bill-receiver-sign">
                                    <div></div>
                                    <div className="pb-3">
                                        <b>Receivers Sign</b>
                                    </div>
                                </div>
                                <div className="bill-authority-sign">
                                    <div className="pt-4 tar pr-3">
                                        For,{" "}
                                        <b>
                                            {this.state.billSettings
                                                ? this.state.billSettings[0]
                                                      .value
                                                : ""}
                                        </b>
                                    </div>
                                    <div></div>
                                    <div className="pb-4 tar pr-4">
                                        <small>(Authorised Signature)</small>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <>
                            <PrintBillShow
                                {...this.props}
                                {...this.state}
                                ref={(el) => (this.componentRef = el)}
                            />
                            <hr />
                            <div className="mt-2 mr-1">
                                <ReactToPrint
                                    trigger={() => {
                                        return (
                                            <Btn1
                                                style={{ float: "right" }}
                                                className="mx-2"
                                                onClick={(e) => {
                                                    console.log("Success!");
                                                }}
                                            >
                                                print
                                            </Btn1>
                                        );
                                    }}
                                    content={() => this.componentRef}
                                />
                            </div>
                        </>
                    )}
                </Modal.Body>
            </Modal>
        );
    }

    renderShowBillModal() {
        return (
            <Modal
                show={this.state.showBillModal}
                onHide={(e) => this.setState({ showBillModal: false })}
                size="xl"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Bill View
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <PrintBillShow
                        {...this.props}
                        {...this.state}
                        ref={(el) => (this.componentRef = el)}
                    />
                    <hr />
                    <div className="mt-2 mr-1">
                        <ReactToPrint
                            trigger={() => {
                                return (
                                    <Btn1
                                        style={{ float: "right" }}
                                        className="mx-2"
                                        onClick={(e) => {
                                            console.log("Success!");
                                        }}
                                    >
                                        print
                                    </Btn1>
                                );
                            }}
                            content={() => this.componentRef}
                        />
                    </div>
                </Modal.Body>
            </Modal>
        );
    }

    render() {
        return (
            <div className="container-fluid border m-0 p-0 main">
                <div className="m-0 p-0">
                    <div className="container-fluid border m-0 p-1">
                        <div
                            className="btn-group"
                            role="group"
                            aria-label="Basic example"
                        >
                            {false ? (
                                <Button
                                    className="mt-1 mr-1 mb-3"
                                    color="secondary"
                                    variant="contained"
                                    onClick={(e) => {
                                        this.fetchBillSetting({
                                            id: "",
                                            partyId: "",
                                            partyName: "",
                                            vehicleNo: "",
                                            vehicleName: "",
                                            total: "",
                                            advance: "",
                                            status: "",
                                            showAddModal: true,
                                        });
                                    }}
                                >
                                    Generate New Bill
                                </Button>
                            ) : (
                                <Button
                                    color="primary"
                                    variant="contained"
                                    className="mt-1 mr-1 mb-3 ml-5"
                                    onClick={(e) => {
                                        this.fetchBillSetting({
                                            id: "",
                                            partyId: "",
                                            partyName: "",
                                            vehicleNo: "",
                                            vehicleName: "",
                                            total: "",
                                            advance: "",
                                            status: "",
                                            showAddModal: true,
                                        });
                                    }}
                                >
                                    <FontAwesomeIcon
                                        icon={faPlusCircle}
                                        size="2x"
                                    />
                                </Button>
                            )}
                            <Button
                                color="primary"
                                variant="contained"
                                className="mt-1 mr-1 mb-3 ml-5"
                                onClick={this.props.refresh}
                            >
                                <FontAwesomeIcon icon={faSyncAlt} size="2x" />
                            </Button>
                        </div>

                        {true ? this.renderUpdateBillModal() : ""}
                        {this.renderShowAddNewModal()}
                        {true ? this.renderShowBillModal() : ""}
                        <Row className="ml-0 mr-0">
                            <Col md="12" className="p-0 m-0 measure1">
                                <div>
                                    <table
                                        id="bill_table"
                                        className="display"
                                        style={{ width: "100%" }}
                                    >
                                        <thead>
                                            <tr align="center">
                                                <th>id</th>
                                                <th>party Name</th>
                                                <th>Vehicle No.</th>
                                                <th>Vehicle Name</th>
                                                <th>Total</th>
                                                <th>Advance</th>
                                                <th>Date Modified</th>
                                                <th>Options</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.renderPartiesData()}
                                        </tbody>
                                    </table>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>
            </div>
        );
    }
}

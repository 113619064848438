import "./App.css";
import React, { useState } from "react";
import { HashRouter as Router, Switch, Route } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";

import Home from "./pages/home/Home";
import BillManager from "./pages/billManager/BillManager";
import PartyManager from "./pages/partyManager/PartyManager";
import WorkerManager from "./pages/workerManager/workerManger";
import LedgerManager from "./pages/ledgerManager/LedgerManager";
import InventoryManager from "./pages/inventoryManager/InventoryManager";

import logo1 from "./logos/bridgeStoneLogo.jpg";
import logo2 from "./logos/ceatLogo.png";
import logo3 from "./logos/jkTyresLogo.jpg";
import logo4 from "./logos/mrfLogo.png";
import logo from "./logos/globalTyresLogo.jpg";

import NotFound from "./components/notFound/NotFound";
import Menu from "./components/menu/Menu";

import "datatables.net-dt/css/jquery.dataTables.min.css";
import "react-toastify/dist/ReactToastify.css";
import "bootstrap/dist/css/bootstrap.css";

function App() {
  const [r, setR] = useState(0);
  return (
    <Router>
      <div className="page">
        <Menu />
        <div className="content">
          <Switch>
            <Route exact path="/billManager">
              <BillManager
                refresh={() => setR(r + 1)}
                key={Date.now()}
                logo={logo}
                logos={[logo1, logo2, logo3, logo4]}
              />
            </Route>
            <Route exact path="/partyManager">
              <PartyManager
                refresh={() => setR(r + 1)}
                key={Date.now()}
                toast={toast}
              />
            </Route>
            <Route exact path="/workerManager">
              <WorkerManager
                refresh={() => setR(r + 1)}
                key={Date.now()}
                toast={toast}
              />
            </Route>
            <Route
              exact
              path="/ledgerManager/:partyId"
              render={(props) => (
                <LedgerManager
                  {...props}
                  refresh={() => setR(r + 1)}
                  key={Date.now()}
                  toast={toast}
                />
              )}
            />
            {false ? (
              <Route exact path="/ledgerManager">
                <LedgerManager
                  refresh={() => setR(r + 1)}
                  key={Date.now()}
                  toast={toast}
                />
              </Route>
            ) : (
              ""
            )}
            <Route exact path="/inventoryManager">
              <InventoryManager
                refresh={() => setR(r + 1)}
                key={Date.now()}
                logo={logo}
                logos={[logo1, logo2, logo3, logo4]}
              />
            </Route>
            <Route exact path="/">
              <Home refresh={() => setR(r + 1)} key={Date.now()} />
            </Route>
            <Route path="*">
              <NotFound refresh={() => setR(r + 1)} key={Date.now()} />
            </Route>
          </Switch>
        </div>
      </div>
      <ToastContainer position={toast.POSITION.TOP_RIGHT} autoClose={5000} />
    </Router>
  );
}

export default App;
